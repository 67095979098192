import React, { useContext, useState, useEffect } from 'react';
import classes from './Account.module.scss';
import btCoin from '../../../../assets/images/bt_coin.png';
import ReactPaginate from 'react-paginate';
import { AuthContext } from '../../../../context/auth-context';
import axios from 'axios';
import * as Common from '../../../../common';
import * as Admin from '../../../../constant/userAdmin';
import { checkAuth } from '../../../../commonFunc';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Account = () => {
    const authContext = useContext(AuthContext);
    const [balance, setBalance] = useState(0);
    const [hourlyCost, setHourlyCost] = useState();
    const [transactionData, setTransactionData] = useState();
    const [txCurrPage, setTxCurrPage] = useState(1);
    const [txPages, setTxPages] = useState();
    const [showTokenIssueConfirm, setShowTokenIssueConfirm] = useState(false);
    const [tokenIssueValue, setTokenIssueValue] = useState();
    const [tokenIssueEmail, setTokenIssueEmail] = useState();
    const [tokenIssueDesc, setTokenIssueDesc] = useState();

    const ADMIN = 'ADMIN';

    useEffect(() => {
        if (authContext.credentials && authContext.credentials.token && authContext.credentials.email) {
            getBalance();
            getTxHistory();
        }
    }, [authContext])

    useEffect(() => {
        if (authContext.credentials && authContext.credentials.token && authContext.credentials.email) {
            getTxHistory();
        }
    }, [txCurrPage])

    const getBalance = () => {
        let data = {
            credentials: authContext.credentials,
        }
        axios.post(Common.ENDPOINT + Common.TOKEN_BALANCE, data).then(response => {
            if (response.data.status === '1') {
                setBalance(response.data.balance);
                setHourlyCost(response.data.hourly_price);
            } else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message);
            }
        })
    }

    const getTxHistory = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                per_page: 10,
                page: txCurrPage
            }
        }
        axios.post(Common.ENDPOINT + Common.TOKEN_TRANSACTIONS, data).then(response => {
            if (response.data.status === '1') {
                setTransactionData(response.data.items);
                setTxPages(Math.ceil(parseFloat(response.data.total) / 10))
            } else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message);
            }
        })
    }

    const tokenIssueValidate = () => {
        const isEmail = (email) => {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }

        if (!isEmail(tokenIssueEmail)) {
            alert('Please provide a valid email');
            return;
        }
        if (tokenIssueValue < 0) {
            alert('Please enter a valid token amount');
            return;
        }
        tokenIssueHandler();
    }

    const tokenIssueHandler = () => {
        setShowTokenIssueConfirm(false);
        let data = {
            credentials: authContext.credentials,
            data: {
                token_email: tokenIssueEmail,
                amount: tokenIssueValue,
                desc: tokenIssueDesc
            }
        }
        axios.post(Common.ENDPOINT + Common.TOKEN_SEND, data).then(response => {
            if (response.data.status === '1') {
                alert('Token issued successfully!');
            } else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message);
            }
        })
    }

    const cost = hourlyCost ? <span>Hourly Cost: <b>{hourlyCost}</b> token(s)</span> : null;
    const accountBalance =
        <div className={classes.balance}>
            <img src={btCoin} width="30"></img>
            <span className={classes.amount}>Total: {balance}</span>
            {/* <NavLink to="/payment?addToken=true" className={classes.action} style={{ display: 'inlineBlock', verticalAlign: 'middle' }}>+ Add Tokens</NavLink> */}
            <span className={classes.action}
                onClick={() => alert('Oops, this function is still under development!')}>Add Token</span>
            {/* <NavLink to="/payment"><div className={classes.btPrimary} title="Go to payment page">Manage Orders</div></NavLink> */}
            <span className={classes.action} title="Go to payment page" onClick={() => alert('Oops, this function is still under development!')}>Manage Orders</span>
            {
                Admin.convertRole(authContext.usertype) === ADMIN ? <span className={classes.action} onClick={() => setShowTokenIssueConfirm(true)}>Issue Token</span> : null
            }
        </div>

    const transactions = transactionData ? transactionData.length === 0 ?
        <tr className={classes.txRow}><td colSpan="3">No transaction data exists.</td></tr> :
        transactionData.map((tx, i) => {
            let type = tx.type;
            let amount = tx.amount;
            if (amount > 0) amount = '+' + amount;
            if (tx.type === 'user_paid') {
                type = <i className="fas fa-shopping-cart" title="paid tokens"></i>
            } else if (tx.type === 'system_issue') {
                type = <i className="fas fa-hand-holding-heart" title="system issued tokens"></i>
            } else if (tx.type === 'user_refund') {
                type = <i className="fas fa-undo-alt" title="refund"></i>
            } else if (tx.type === 'chain') {
                type = <i className="fas fa-money-bill-wave-alt" title="cost from chain"></i>
            } else if (tx.type === 'system_deduct') {
                type = <i className="fas fa-level-down-alt" title="system deduct"></i>
            }

            let localeTs = new Date((new Date(tx.timestamp)).toString() + ' UTC').toLocaleString();
            let title = tx.title;
            if (tx.type === 'chain') {
                let fromDate = new Date((new Date(tx.from_time)).toString() + ' UTC')
                let toDate = new Date((new Date(tx.to_time)).toString() + ' UTC')
                let fromTs = fromDate.toLocaleString()
                let toTs = toDate.toLocaleString()
                if (fromDate.getFullYear() === toDate.getFullYear() && fromDate.getMonth() === toDate.getMonth() && fromDate.getDate() === toDate.getDate()) {
                    toTs = toTs.split(',')[1]
                }
                let chainName = tx.chain_name ? <span style={{ fontSize: '1.2rem', fontWeight: '400' }}>For <span style={{ fontSize: '1.2rem', fontWeight: '600' }}>{tx.chain_name}, Chain ID: {tx.chain_id}</span></span> : null
                title = <span>{tx.title} <span style={{ fontSize: '1.2rem', color: '#555' }}>[{fromTs} - {toTs} {chainName}]</span></span>
            }

            return <tr className={classes.txRow} key={i}>
                <td className={classes.txType}>{type}</td>
                <td>
                    <div className={classes.txTitle}>{title}<div className={classes.txDesc}>{tx.desc}</div></div>
                    <div className={classes.txTimestamp}>{localeTs}</div>
                </td>
                <td><div className={classes.txAmount} title="transaction amount">{amount}</div><div className={classes.txBalance} title="current balance">{tx.balance}</div></td>
            </tr>
        }) : <tr className={classes.txRow}><td colSpan="3">Fetching data...</td></tr>;

    const txPagination = txPages ? <ReactPaginate
        forcePage={txCurrPage - 1}
        previousLabel={<i className="fas fa-caret-left"></i>}
        nextLabel={<i className="fas fa-caret-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h"></i>}
        breakClassName={'break-me'}
        pageCount={txPages}
        onPageChange={(page) => {
            setTxCurrPage(page.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
    /> : null;

    const transactionHistory = <div className={classes.transactions}>
        <h4>Recent Transactions</h4>{cost}
        <div className={classes.refresh} title="refresh transaction history" onClick={getTxHistory}><i className="fas fa-sync-alt"></i></div>
        <table className={classes.txTable}><tbody>{transactions}</tbody></table>
        {txPagination}
    </div>;

    const tokenIssueModal = <Modal animation={true} show={showTokenIssueConfirm} onHide={() => { setShowTokenIssueConfirm(false) }}>
        <Modal.Header closeButton>
            <Modal.Title>Issue Token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className={classes.formRow}>
                <p>Token Amount</p>
                <input type="number" placeholder=" token amount"  value={tokenIssueValue} onChange={(e) => { setTokenIssueValue(e.target.value) }}></input>
            </div>
            <div className={classes.formRow}>
                <p>Receiver</p>
                <input type="email" placeholder=" user email" value={tokenIssueEmail} onChange={(e) => { setTokenIssueEmail(e.target.value) }}></input>
            </div>
            <div className={classes.formRow}>
                <p>Description (optional)</p>
                <input type="text" placeholder="" value={tokenIssueDesc} placeholder=" Describe the reason of this token issue" onChange={(e) => { setTokenIssueDesc(e.target.value) }}></input>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => { setShowTokenIssueConfirm(false) }}>Cancel</Button>
            <Button variant="primary" onClick={tokenIssueValidate}>Confirm</Button>
        </Modal.Footer>
    </Modal>
    return (
        <div className={classes.Account}>
            {accountBalance}
            {transactionHistory}
            {tokenIssueModal}
        </div>
    )
}

export default Account;