import React, { useState, useEffect, useContext } from 'react';
import classes from './Monitor.module.scss';
import { AuthContext } from '../../../context/auth-context';
import { MonitorContext } from '../../../context/monitor-context';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as Common from '../../../common';
import { checkAuth } from '../../../commonFunc';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'

const Contracts = () => {
    const authContext = useContext(AuthContext);
    const monitorContext = useContext(MonitorContext);
    const [searchInput, setSearchInput] = useState();
    const [currPage, setCurrPage] = useState(1);
    const [pages, setPages] = useState();
    const [perPage, setPerPage] = useState(10);
    const [contractData, setContractData] = useState();
    const pagination_options = [10, 25, 50];
    const [refreshing, setRefreshing] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);

    const getContracts = (searchInput) => {
        setContractData(null);
        setRefreshing(true);

        let data = {
            credentials: authContext.credentials,
            data: {
                page: currPage,
                per_page: perPage,
                chain_id: monitorContext.chain.chain_id,
                search_term: searchInput
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_GET_CONTRACTS_V2, data).then(response => {
            setRefreshing(false);

            if (response.data.status === '1') {
                setContractData(response.data.items);
                setPages(Math.ceil(parseFloat(response.data.total) / perPage))
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const isInputValid = (input) => {
        var format = /^[a-zA-Z0-9]+$/
        return format.test(input)
    }

    useEffect(() => {
        if (monitorContext.chain) {
            getContracts();
        }
    }, [monitorContext, currPage, perPage])

    let contractSearch = <div className={classes.search}><InputGroup>
        <FormControl
            placeholder="Search contract by contract address"
            aria-label="Search contract by contract address"
            aria-describedby="search-addon"
            onChange={(e) => {
                setSearchInput(e.target.value);
                if (!isInputValid(e.target.value)) {
                    setShowFeedback(true);
                } else setShowFeedback(false);
            }}
            maxLength="66"
        />
        {
            showFeedback ? <InputGroup.Append>
                <div className={[classes.mainBtn, classes.disabled].join(' ')} ><i className="fas fa-search" style={{ marginLeft: 0 }}></i>Search</div>
            </InputGroup.Append> : <InputGroup.Append>
                    <div className={classes.mainBtn} onClick={() => { getContracts(searchInput) }}><i className="fas fa-search" style={{ marginLeft: 0 }}></i>Search</div>
                </InputGroup.Append>
        }
    </InputGroup>
        {showFeedback && searchInput !== '' ? <small>Please provide a valid contract address</small> : null}
        <div><span className={classes.actionBtn} onClick={() => getContracts()}>Show All Contracts</span></div>
    </div>

    let tableRows = contractData ? contractData.length > 0 ? contractData.map((contract, i) => {
        return <tr key={i}>
            <td><Link to={`contracts/${contract.id}`}>#{contract.contract_id} <b>{contract.contract_name}</b>{
                contract.is_private ? <span><i className="fas fa-lock" style={{ color: '#00b6a6' }} title="private contract"></i></span> : null
            }</Link> ({trimHash(contract.contract_hash)}<i className="far fa-copy" title="copy contract address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(contract.contract_hash) }}></i>)</td>
            <td><Link to={`transactions/${contract.tx_id}`}><b>Tx</b> {trimHash(contract.tx_hash)}</Link><i className="far fa-copy" title="copy hash to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(contract.tx_hash) }}></i></td>
            <td><span title={getLocalTime(contract.time)}>{getLocalTime(contract.time)}</span></td>
        </tr>
    }) : <tr><td colSpan="3">No Contract Data available</td></tr> : <tr><td colSpan="3">Fetching Data...</td></tr>;

    let contractTable = <Table hover className={classes.table}>
        <tbody>
            {tableRows}
        </tbody>
    </Table>

    const paginationUpdate = (perPage) => {
        setPerPage(perPage);
        setCurrPage(1);
    }

    const paginationOptions = pagination_options.map((number, index) => {
        let classes = "option"
        if (number === perPage) classes = "option selected";
        return <span className={classes} key={index} onClick={() => { paginationUpdate(number) }}>{number}</span>
    })

    const tablePagination = pages > 0 ? <ReactPaginate
        forcePage={currPage - 1}
        previousLabel={<i className="fas fa-caret-left"></i>}
        nextLabel={<i className="fas fa-caret-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h"></i>}
        breakClassName={'break-me'}
        pageCount={pages}
        onPageChange={(page) => {
            setCurrPage(page.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
    /> : null

    let contractTableSession = <div>
        <div className={classes.spaceBetween}>
            <div className={classes.thirdTitle}>Lastest Contracts</div>
            <div>
                <span className="pagination_menu" style={{ fontSize: '1.2rem', display: 'inline-block' }}>show {paginationOptions} items</span>{' '}
                {
                    !refreshing ? <span className={classes.refreshBtn} title="refresh data"><i className="fas fa-sync-alt" onClick={() => getContracts()}></i></span> :
                        <Spinner animation="border" variant="secondary" />
                }
            </div>
        </div>
        {contractTable}
        {tablePagination}
    </div>
    return (
        <div className={classes.monitor}>
            <div className={classes.subTitle}>Contracts<OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Popover id="contracts-popover">
                    <Popover.Content>
                        <p>Smart contracts are lines of code that are stored on a blockchain and automatically execute when predetermined terms and conditions are met. At the most basic level, they are programs that run as they’ve been set up to run by the people who developed them. </p>              </Popover.Content>
                </Popover>}
            ><sup><i class="fas fa-info-circle"></i></sup></OverlayTrigger></div>
            {/* {chainDesc} */}
            {monitorContext.chain ?
                <div>
                    {contractSearch}
                    {contractTableSession}
                </div> :
                <div>Click <Link to="/chain">here</Link> to select a chain first</div>}
        </div>
    )
}

function trimHash(hash) {
    return hash.substring(0, 6) + '...' + hash.substring(hash.length - 6, hash.length)
}

function copyToClipboard(secretInfo) {
    var $body = document.getElementsByTagName('body')[0];
    var $tempInput = document.createElement('INPUT');
    $body.appendChild($tempInput);
    $tempInput.setAttribute('value', secretInfo)
    $tempInput.select();
    document.execCommand('copy');
    $body.removeChild($tempInput);
}

function getLocalTime(time) {
    let utc_time = (new Date(time)).toString() + ' UTC';
    let localtime = new Date(utc_time).toLocaleString();
    return localtime;
}

export default Contracts;