import React, { useState, useEffect, useContext } from 'react';
import classes from './Monitor.module.scss';
import { useParams } from 'react-router-dom';
import { MonitorContext } from '../../../context/monitor-context';
import { AuthContext } from '../../../context/auth-context';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Timestamp from 'react-timestamp';
import axios from 'axios';
import * as Common from '../../../common';
import { checkAuth } from '../../../commonFunc';
import Badge from 'react-bootstrap/Badge';

const Transaction = () => {
    let { txId } = useParams();

    const monitorContext = useContext(MonitorContext);
    const authContext = useContext(AuthContext);

    const [txInfo, setTxInfo] = useState();

    const getTxInfo = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                id: Number(txId),
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_GET_TRANSACTION, data).then(response => {
            if (response.data.status === '1') {
                setTxInfo(response.data);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    useEffect(() => {
        if (monitorContext.chain) getTxInfo();
    }, [monitorContext])

    const backToTxs = <Link to="/chain/monitor/transactions"><div className={classes.actionBtn} style={{ fontSize: '1.4rem' }}>&lt; Back to Transactions</div></Link>

    let txType = txInfo ? txInfo.tx_type : null;
    let txDetail = null;
    if (txType === 'invoke') {
        let inputs = ''
        txInfo.contract.inputs.forEach(input => {
            inputs += (input + ', ')
        })
        if (inputs !== '') inputs = inputs.substring(0, inputs.length - 2);
        inputs = '(' + inputs + ')'
        txType = <Badge pill variant="info" style={{ color: 'white' }}>Invoke</Badge>
        txDetail = <span>Invoked function <b>{txInfo.contract.function}{inputs}</b> from contract <Link to={`/chain/monitor/contracts/${txInfo.contract.id}`}><b><span style={{textDecoration:'underline'}}>{txInfo.contract.contract_name}</span></b></Link></span>
    } else if (txType === 'deploy') {
        let isPrivate = txInfo.contract.is_private ? <i className="fas fa-lock" title="private contract" style={{ color: 'white' }}></i> : null;
        txType =
            <Badge pill variant="secondary" style={{ color: 'white' }}>Deploy{isPrivate}</Badge>
        txDetail = <span>Deplyed <b>{txInfo.contract.is_private ? 'Private ' : 'Public '}</b>Contract <Link to={`/chain/monitor/contracts/${txInfo.contract.id}`}><b><span style={{textDecoration:'underline'}}>{txInfo.contract.contract_name}</span></b></Link>
        </span>
    } else if (txType === 'transfer') {
        txType = <Badge pill variant="warning" style={{ color: 'white' }}>Transfer</Badge>
    }
    const txDetails = txInfo ? <div className={classes.container}>
        <div className={classes.txDetails}>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Transaction Hash</div></Col>
                <Col xs={8} md={9}><div><b>{txInfo.tx_hash}</b><i className="far fa-copy" title="copy transaction hash to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(txInfo.tx_hash) }}></i></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Block</div></Col>
                <Col xs={8} md={9}><div><Link to={`/chain/monitor/blocks/${txInfo.block_id}`}><b>#{txInfo.block_number}</b> {txInfo.block_hash}</Link><i className="far fa-copy" title="copy block hash to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(txInfo.block_hash) }}></i></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Time</div></Col>
                <Col xs={8} md={9}><div><b><Timestamp relative date={toLocalTime(txInfo.tx_time)} /></b> ({toLocalTime(txInfo.tx_time)})</div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>From</div></Col>
                <Col xs={8} md={9}><div>{txInfo.from}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(txInfo.from) }}></i></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>To</div></Col>
                <Col xs={8} md={9}><div>{txInfo.to === '' ? 'Not Available' : txInfo.to}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(txInfo.to) }}></i></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Value</div></Col>
                <Col xs={8} md={9}><div><b>{txInfo.value}</b></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Transaction Fee</div></Col>
                <Col xs={8} md={9}><div><b>{txInfo.fee}</b></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}>Type</Col>
                <Col xs={8} md={9}>{txType} - {txDetail}</Col>
            </Row>
        </div>
    </div> : <div className={classes.container}>Fetching Data...</div>;

    return (
        <div className={classes.monitor}>
            <div className={classes.subTitle}>Transaction</div>
            {backToTxs}
            {txDetails}
        </div>
    )
}

function toLocalTime(time) {
    return new Date((new Date(time)).toString() + ' UTC').toLocaleString();
}

function copyToClipboard(secretInfo) {
    var $body = document.getElementsByTagName('body')[0];
    var $tempInput = document.createElement('INPUT');
    $body.appendChild($tempInput);
    $tempInput.setAttribute('value', secretInfo)
    $tempInput.select();
    document.execCommand('copy');
    $body.removeChild($tempInput);
}

export default Transaction;