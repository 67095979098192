import React, { useState, useEffect, useContext } from 'react';
import classes from './Monitor.module.scss';
import classes2 from './Overview.module.scss';
import Row from 'react-bootstrap/Row';
import { AuthContext } from '../../../context/auth-context';
import { MonitorContext } from '../../../context/monitor-context';
import axios from 'axios';
import * as Common from '../../../common';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { checkAuth } from '../../../commonFunc';
import { Link } from 'react-router-dom';

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Overview = () => {
    const authContext = useContext(AuthContext);
    const monitorContext = useContext(MonitorContext);
    const [showLoginReminder, setShowLoginReminder] = useState();

    const [metrics, setMetrics] = useState();

    const [blocksData, setBlocksData] = useState();
    const [txsData, setTxsData] = useState();
    const [contractsData, setContractsData] = useState();
    const [refreshing, setRefreshing] = useState(false);
    useEffect(() => {
        if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
            setShowLoginReminder(false);
        } else { setShowLoginReminder(true); }
    }, [authContext])

    useEffect(() => {
        if (monitorContext.chain) {
            getChainInfo();
        }
    }, [monitorContext.chain])

    const getPreviousSelectedChain = () => {
        if (window.sessionStorage.getItem('selected_chain')) {
            let chain = JSON.parse(window.sessionStorage.getItem('selected_chain'))
            if (monitorContext) monitorContext.setChain(chain);
        }
    }

    const getChainSummery = () => {
        setRefreshing(true);
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: monitorContext.chain.chain_id
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_CHAIN_SUMMERY, data).then(response => {
            setRefreshing(false);
            if (response.data.status === '1') {
                setMetrics(response.data);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getBlocksChart = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: monitorContext.chain.chain_id
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_CHAIN_BLOCKS, data).then(response => {
            if (response.data.status === '1') {
                setBlocksData(response.data.items);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getTxsChart = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: monitorContext.chain.chain_id
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_CHAIN_TXS, data).then(response => {
            if (response.data.status === '1') {
                setTxsData(response.data.items);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getContractsChart = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: monitorContext.chain.chain_id
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_CHAIN_CONTRACTS, data).then(response => {
            if (response.data.status === '1') {
                setContractsData(response.data.items);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getChainInfo = () => {
        getChainSummery();
        // getBlocksChart();
        // getTxsChart();
        // getContractsChart();
    }

    const loginReminder = showLoginReminder ? <div>Please <a href="/login">login</a> to check details.</div> : null;

    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className={classes2.chartTooltip}>
                    {`${payload[0].payload.x} : ${payload[0].payload.y} ${payload[0].unit}`}
                </div>
            );
        }
        return null;
    };

    let blockChart = blocksData ? blocksData.length > 0 ? 
        <ResponsiveContainer width='100%' height={80}>
            <BarChart data={blocksData}>
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="y" fill="#93b2c4" unit="blocks">
                    {
                        blocksData.map((entry, index) => {
                            return <Cell cursor="pointer" key={`cell-${index}`} />
                        })
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer> : <p>No Block Data available</p> : <p>Fetching Data...</p>

    let txChart = txsData ? txsData.length > 0 ?
        <ResponsiveContainer width='100%' height={80}>
            <BarChart data={txsData}>
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="y" fill="#93b2c4" unit="transactions">
                    {
                        txsData.map((entry, index) => (
                            <Cell cursor="pointer" key={`cell-${index}`} />
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer> : <p>No Transaction Data available</p> : <p>Fetching Data...</p>

    let contractChart = contractsData ? contractsData.length > 0 ? 
        <ResponsiveContainer width='100%' height={80}>
            <BarChart data={contractsData}>
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="y" fill="#93b2c4" unit="contracts">
                    {
                        contractsData.map((entry, index) => (
                            <Cell cursor="pointer" key={`cell-${index}`} />
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer> : <p>No Contract Data available</p> : <p>Fetching Data...</p>

    let overviewContanier = showLoginReminder ? null : monitorContext.chain ? metrics ? 
        <div className={classes.container}>
            <div style={{textAlign:'right'}}>{
                !refreshing ? <span className={classes.refreshBtn} title="refresh data"><i className="fas fa-sync-alt" onClick={getChainInfo}></i></span> : 
                <Spinner animation="border" variant="secondary" />
            }</div>
            <div style={{ marginBottom: '3rem' }}>
                {/* <Col xs={12} md={12} lg={5}> */}
                    <Row>
                        <Col xs={6} md={4} lg={3}><div className={classes.metricTitle}>Block Number</div></Col>
                        <Col><Link to={`/chain/monitor/blocks`}><div className={classes.mainBtn}>{metrics.last_block_number} Blocks</div></Link></Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4} lg={3}><div className={classes.metricTitle}>Last Block Time</div></Col>
                        <Col><div className={classes.metricValueDark}>{getLocalTime(metrics.last_block_time)}</div></Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4} lg={3}><div className={classes.metricTitle}>Avg Block Time</div></Col>
                        <Col><div className={classes.metricValueDark}>{metrics.avg_block_timespan}</div></Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4} lg={3}><div className={classes.metricTitle}>Active Nodes</div></Col>
                        <Col><div className={classes.metricValueDark}>{metrics.active_nodes} / {metrics.nodes}</div></Col>
                    </Row>
                {/* </Col> */}
                {/* <Col xs={6} md={6} lg={4} style={{ margin: 'auto', textAlign: 'center' }}>{blockChart}</Col> */}
                {/* <Col style={{ margin: 'auto', paddingLeft: '3rem' }}><Link to="/chain/monitor/blocks"><div className={classes.mainBtn}>View Blocks</div></Link></Col> */}
            </div>
            <div style={{ marginBottom: '3rem' }}>
                {/* <Col xs={12} md={12} lg={5}> */}
                    <Row>
                        <Col xs={6} md={4} lg={3}><div className={classes.metricTitle}>Transactions</div></Col>
                        <Col xs={6}><Link to="/chain/monitor/transactions"><div className={classes.mainBtn}>{metrics.tx_count} Transactions</div></Link></Col>
                    </Row>
                {/* </Col> */}
                {/* <Col xs={6} md={6} lg={4} style={{ margin: 'auto', textAlign: 'center' }}>{txChart}</Col> */}
                {/* <Col style={{ margin: 'auto', paddingLeft: '3rem' }}></Col> */}
            </div>
            <div style={{ marginBottom: '3rem' }}>
                {/* <Col xs={12} md={12} lg={5}> */}
                    <Row>
                        <Col xs={6} md={4} lg={3}><div className={classes.metricTitle}>Contracts</div></Col>
                        <Col xs={6}><Link to="/chain/monitor/contracts"><div className={classes.mainBtn}>{metrics.contract_count} Contracts</div></Link></Col>
                    </Row>
                {/* </Col> */}
                {/* <Col xs={6} md={6} lg={4} style={{ margin: 'auto', textAlign: 'center' }}>{contractChart}</Col> */}
                {/* <Col style={{ margin: 'auto', paddingLeft: '3rem' }}><Link to="/chain/monitor/contracts"><div className={classes.mainBtn}>View Contracts</div></Link></Col> */}
            </div>
            <div style={{ marginBottom: '3rem' }}>
                {/* <Col xs={12} md={12} lg={5}> */}
                    <Row>
                        <Col xs={6} md={4} lg={3}><div className={classes.metricTitle}>Difficulty</div></Col>
                        <Col xs={6}><div className={classes.metricValueDark}>{metrics.difficulty}</div></Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4} lg={3}><div className={classes.metricTitle}>Avg Difficulty</div></Col>
                        <Col xs={6}><div className={classes.metricValueDark}>{metrics.avg_difficulty}</div></Col>
                    </Row>
                    <Row>
                        <Col xs={6} md={4} lg={3}><div className={classes.metricTitle}>Avg Hash Rate</div></Col>
                        <Col xs={6}><div className={classes.metricValueDark}>{metrics.avg_hashrate}</div></Col>
                    </Row>
                {/* </Col> */}
            </div>
        </div>
        : <div>Fetching Data...</div> : <div>Click <Link to="/chain">here</Link> to select a chain first</div>

    return (
        <div className={classes.monitor}>
            <div className={classes.subTitle}>Overview</div>
            {/* <p className={classes.pageDesc}>Managing your network doesn't end when your chain is launched. Monitor chain health easily by keeping track of chain status, blocks, contracts, and transactions here in one streamlined dashboard.</p> */}
            {loginReminder}
            {overviewContanier}
        </div>
    )
}

function getLocalTime(time) {
    let utc_time = (new Date(time)).toString() + ' UTC';
    let localtime = new Date(utc_time).toLocaleString();
    return localtime;
}

export default Overview;