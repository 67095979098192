import React, { useContext, useState, useEffect } from 'react';
import classes from './Message.module.scss';
import Timestamp from 'react-timestamp';
import Spinner from 'react-bootstrap/Spinner';
import axios from "axios";
import * as Common from "../../../../../common";
import { checkAuth } from "../../../../../commonFunc";
import { AuthContext } from "../../../../../context/auth-context";
import logo from '../../../../../assets/images/BT_logo.png';
import MessageItem from './MessageItem';

const Message = (props) => {
	let message = props.data;
	const authContext = useContext(AuthContext);
	const [showContent, setShowContent] = useState(props.showContent);
	const [showReplyArea, setShowReplyArea] = useState(false);
	const [replyContent, setReplyContent] = useState('');

	const [showAction, setShowAction] = useState(false);
	const [isUnread, setIsUnread] = useState(message.message_read_status === 0 && !props.sentBox);

	let msgClass = isUnread ? [classes.unreadMsg, classes.message].join(' ') : [classes.readMsg, classes.message].join(' ');
	if (props.sentBox) {
		message = message[0];
		message.message_from = message.message_to;
	}
	const localtime = new Date(message.message_timestamp).toLocaleString();

	useEffect(() => {
		if (!message.message_content) setShowContent(false)
	}, [message])

	const toggleMsgContent = () => {
		if (showContent) {
			setShowContent(false);
			return;
		} else {
			if (!Array.isArray(message.message_content) && !props.sentBox) {
				props.getContent(message.message_id)
				setIsUnread(false);
				message.message_content = null;
			}
			setShowContent(true);
		}
	}

	const sendReplyHandler = () => {
		// call API to reply the message
		const data = {
			credentials: authContext.credentials,
			data: {
				message_reply_to: message.message_id,
				message_content: replyContent,
				message_title: 'Re:' + message.message_title,
				message_reply_status: 1
			}
		}
		axios.post(Common.ENDPOINT + Common.REPLY_MESSAGE, data).then(response => {
			if (response.data.status === '1') {
				alert('Reply Sent!')
				// reload sent messages data
				setShowReplyArea(false);
				props.reloadSentData();
				setShowContent(false);
			}
			else {
				if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message);
			}
		})
	}

	const systemIcon = message.message_type === 'system' ? <img src={logo} width="12" style={{ marginLeft: '3px', verticalAlign: 'baseline' }} alt="[system message]" title="system message" /> : null;

	const replyBtn = message.message_reply_status === 1 && !props.sentBox ?
		<i className="fas fa-reply" title="reply message" onClick={() => setShowReplyArea(true)}></i>
		: <span></span>;

	const deleteBtn = <i className="fas fa-trash-alt" title="delete message" onClick={() => props.delete(message.message_id, props.sentBox)}></i>
	const markAsReadBtn = isUnread ? <i className="fas fa-envelope-open-text" title="mark as read" onClick={() => { props.markRead(message.message_id); setIsUnread(false); }}></i> : null
	const msgTitleRow = <div className={classes.title} onClick={toggleMsgContent}
		onMouseOver={() => setShowAction(true)} onMouseLeave={() => setShowAction(false)}>
		<div className={classes.spaceBetween}>
			<span>{message.message_from}{systemIcon}</span>
			<span title={localtime}>
				<Timestamp relative date={message.message_timestamp} />
			</span>
		</div>
		<div className={classes.spaceBetween} style={{ lineHeight: '2.5rem' }}>
			<div className={classes.msgTitle} style={{fontWeight: '600'}}>{message.message_title}</div>
			{showAction ? <div className={classes.actionIcons} onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}>{deleteBtn} {markAsReadBtn}</div> : null}
		</div>
	</div>

	const replySession = showReplyArea ? <div style={{ marginTop: '1rem' }}>
		<div className={classes.replyTitle}>
			<span>To: {message.message_from}</span>
			<span>Re:{message.message_title}</span>
		</div>
		<div className={classes.replyContent}>
			<textarea placeholder="compose your reply here"
				onChange={(e) => setReplyContent(e.target.value)} value={replyContent}></textarea></div>
		<div className={classes.replyAction}>
			<span onClick={sendReplyHandler}>Send</span>
			<span onClick={() => {
				setShowReplyArea(false)
			}}>Cancel</span>
		</div>
	</div> : null;

	const msgManage = <div>
		<div className={classes.msgManage}>
			{replyBtn}
			{deleteBtn}
		</div>
		{replySession}
	</div>

	let msgContent;
	if (props.sentBox) {
		msgContent = showContent ? props.data.map((message, i) => {
			return <MessageItem key={i} data={message} index={i} manage={msgManage} />
		}) : null;
	} else {
		msgContent = showContent ?
			message.message_content ?
				Array.isArray(message.message_content) ?
					message.message_content.map((message, i) => {
						return <MessageItem key={i} data={message} index={i} manage={msgManage} />
					})
					: <p>{message.message_content}</p>
				: <div className={classes.content}><Spinner animation="border" variant="info" /></div>
			: null;
	}

	return (
		<div className={classes.message}>
			<div className={msgClass}>
				{msgTitleRow}
			</div>
			<div className={classes.messageChain}>{msgContent}</div>
		</div>
	)
}

export default Message;
