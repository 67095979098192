import React, { useContext, useState, useEffect } from 'react';
import { MessageContext } from '../../../context/message-context';
import { useToasts } from 'react-toast-notifications'

// right now, the notification is only for new messages, type as 'info'
// later, we can add more kind of notifications and for more places to use

const Notification = () => {
    const msgContext = useContext(MessageContext);    
    const { addToast } = useToasts()

    useEffect(() => {
        if (msgContext.showMessageNotif) {
            addToast('You got a new message!', {
                appearance: 'info',
                autoDismiss: false,
            })
            msgContext.setShowMessageNotif(false);
        }
    }, [msgContext.showMessageNotif])

    return null;
}

export default Notification;