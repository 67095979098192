/*************** 

** PRODUCTION **

****************/

export const SERVICE_ID = '3a456450d99103d059b69bfa2d579ea3cea3623b'; 
export const IDEUrl = 'https://ide.greenhouse.blocktest.com/#version=soljson-v0.5.15+commit.6a57276f.js';
export const DOMAIN = '.blocktest.com';
export const USERPATH = 'https://api.greenhouse.blocktest.com/blocktest/api/user';
export const ENDPOINT = 'https://api.greenhouse.blocktest.com/blocktest/api/';
export const IDE_ENDPOINT = 'https://ide-api.greenhouse.blocktest.com/blocktest/api/';
export const ENV = 'prod';
export const VERSION = '0.0.15' // prod version
/*************** 

** API **

****************/

// USER
export const ACCOUNT_VALIDATE = '/validate';
export const QUESTION_LIST = '/questions/list';
export const USER_QUESTION_LIST = '/questions/get';
export const RESET_QUESTION_VERIFY = '/questions/verify';
export const UPDATE_QUESTION = '/questions/update';
export const ADMIN_UPLOAD_ICONS = 'icon/upload';
export const PROFILE_ICON_LIST = 'icon/list';
export const PROFILE_ICON_DELETE = 'icon/delete';
export const PROFILE_ICON_UPDATE = 'icon/update';
export const SELECT_ICON = 'user/change_icon';
export const USER_UPDATE_USERNAME = 'user/change_username';

// CHAIN ADMIN
export const CHAIN_CREATE = 'bdaas/chain/create';
export const CHAIN_STATUS = 'bdaas/chain/status';
export const CHAIN_CHAIN_LIST = 'bdaas/chain/chain_list';
export const CHAIN_ACTION = 'bdaas/chain/action';
export const CHAIN_ACTION_LIST = 'bdaas/chain/action_list';
export const BASIC_CHAIN_LIST = 'bdaas/chain/basic_chain_list';

// QUORUM
export const GET_CONTRACTS = 'bdaas/quorum/get_contracts';

// SECURITY ANALYSIS
export const PROJECT_LIST = 'bdaas/projects/list';
export const PROJECT_STRUCTURE = 'bdaas/projects/list_project_structure_tree';
export const UPLOADED_FILENAMES = 'bdaas/submissions/get/code/records';
export const GET_STATIC_RESULT = 'security/getstaticsecurityjob';
export const SECURITY_SUBMIT = 'security/submitstaticsecurityjob';
export const CANCEL_SUBMIT = 'security/cancelstaticsecurityjob';

// MONITOR
export const MONITOR_GET_NODES = 'monitor/nodes';
export const MONITOR_GET_BLOCKS = 'monitor/blocks';
export const MONITOR_GET_TRANSACTIONS = 'monitor/transactions';
export const MONITOR_GET_CONTRACTS = 'monitor/contracts';

// MONITOR V2
export const MONITOR_CHAIN_SUMMERY = 'monitor/v2/summary';
export const MONITOR_CHAIN_BLOCKS = 'monitor/v2/block_chart';
export const MONITOR_CHAIN_TXS = 'monitor/v2/tx_chart';
export const MONITOR_CHAIN_CONTRACTS = 'monitor/v2/contract_chart';
export const MONITOR_GET_BLOCKS_V2 = 'monitor/v2/blocks';
export const MONITOR_GET_BLOCK = 'monitor/v2/block';
export const MONITOR_GET_TRANSACTIONS_V2 = 'monitor/v2/txs';
export const MONITOR_GET_TRANSACTION = 'monitor/v2/tx';
export const MONITOR_GET_CONTRACTS_V2 = 'monitor/v2/contracts';
export const MONITOR_GET_CONTRACT = 'monitor/v2/contract';
export const MONITOR_GET_TOKENS = 'monitor/v2/tokens';
export const MONITOR_GET_WALLETS = 'monitor/v2/wallets';
export const MONITOR_GET_NODES_V2 = 'monitor/v2/nodes';

// PERFORMANCE
export const PERFORMANCE_REQUEST_TEST = 'bdaas/performance/request_test';
export const PERFORMANCE_GET_TEST = 'bdaas/performance/get_test';
export const PERFORMANCE_GET_TESTS = 'bdaas/performance/get_tests';
export const PERFORMANCE_CANCEL = 'bdaas/performance/cancel_test';

// FEEDBACK
export const FEEDBACK_SUBMIT = 'submitfeedback';

// CONSORTIUM
export const CONSORTIUM_CREATE = 'bdaas/consortium/create';
export const CONSORTIUM_LIST = 'bdaas/consortium/list';
export const NODE_CREATE = 'bdaas/node/create';
export const NODE_ACTION = 'bdaas/node/action';
export const NODE_LIST = 'bdaas/node/list';
export const MEMBER_INVITE = 'bdaas/member/invite';
export const MEMBER_ACTION = 'bdaas/member/action';
export const MEMBER_CHANGE_ROLE = 'bdaas/member/change_role';
export const MEMBER_LIST = 'bdaas/member/list';
export const EVENT_LIST = 'bdaas/event/list';
export const EVENT_ACTION = 'bdaas/event/action';
export const EVENT_TODO_COUNT = 'bdaas/event/todo';

// SETTINGS
export const SETTINGS_GET = 'sysadmin/settings/get';

// MESSAGES
export const SEND_MESSAGE = 'socialcenter/send_message';
export const REPLY_MESSAGE = 'socialcenter/reply_message';
export const GET_ALL_MESSAGES = 'socialcenter/get_all_message';
export const MESSAGE_COUNT = 'socialcenter/get_total_message_count';
export const GET_MESSAGE_CONTENT = 'socialcenter/get_message_content';
export const GET_SENT_MESSAGES = 'socialcenter/get_sent_or_reply_messages';
export const MARK_MESSAGE_READ = 'socialcenter/mark_message_read';
export const DELETE_RECEIVE_MESSAGE = 'socialcenter/receive_delete_message';
export const DELETE_SENT_MESSAGE = 'socialcenter/send_delete_message';
export const GET_USERS_MESSAGES = 'socialcenter/get_users_message';
export const UNREAD_MESSAGE_COUNT = 'socialcenter/get_unread_count';

// TOKEN
export const TOKEN_BALANCE = 'tx_admin/token/balance';
export const TOKEN_SEND = 'tx_admin/token/send';
export const TOKEN_DEDUCT = 'tx_admin/token/deduct';
export const TOKEN_TRANSACTIONS = 'tx_admin/token/tx_history';
export const TOKEN_CHAIN_PRICE = 'tx_admin/token/chain_pricing';
export const TOKEN_CREATE_INTENT = 'tx_admin/payment/create_intent';
export const TOKEN_CONFIRM = 'tx_admin/payment/confirm';
export const TOKEN_PAYMENT_LIST = 'tx_admin/payment/list';
export const TOKEN_REFUND_LIST = 'tx_admin/refund/list';
export const TOKEN_REFUND_REQUEST = 'tx_admin/refund/request';
export const TOKEN_REFUND_REVIEW = 'tx_admin/refund/review';
export const TOKEN_REFUND_CONFIRM_REVERSAL = 'tx_admin/refund/confirm_reversal';

// RESTful API
export const ACCESS_TOKEN_GET = 'bdaas/access_token/get';
export const ACCESS_TOKEN_REFRESH = 'bdaas/access_token/refresh'
export const RESTFUL_ENDPOINT = ENDPOINT + 'invoke';

// DAPP MARKETPLACE
export const DAPP_LIST = 'bdaas/dapp/list';
export const PUBLISHED_DAPP_LIST = 'bdaas/dapp/my_share_list';
export const SAVED_DAPP_LIST = 'bdaas/dapp/my_fav_list';
export const DAPP_DETAIL = 'bdaas/dapp/detail';
export const DAPP_CREATE_ACCOUNT = 'bdaas/dapp/create_account';
export const DAPP_ACCOUNTS = 'bdaas/dapp/get_accounts';
export const DAPP_INVOKE = 'bdaas/dapp/invoke';
export const DAPP_FAV_STATUS = 'bdaas/dapp/fav_status';
export const DAPP_FAV = 'bdaas/dapp/fav';
export const DAPP_SHARE = 'bdaas/dapp/share';
export const DAPP_UNSHARE = 'bdaas/dapp/unshare';
export const DAPP_STATUS = 'bdaas/dapp/status';
export const DAPP_SHARE_PREFIX = 'https://greenhouse.blocktest.com/gallery/shop/'; // for local
export const DAPP_UPDATE_ICON = 'bdaas/dapp/change_icon';

// NEWS AND TUTORIAL
export const ARTICLES_GET = 'socialcenter/get_news_and_tutorial_list';
export const ARTICLE_DETAIL = 'socialcenter/get_article';

// APP CONSOLE
export const CONTRACT_DESCRIPTION = 'bdaas/application_console/update_app_description';
export const GET_CONSOLE_CONTRACTS = 'get_application_console_contracts';
export const FUNCTION_DESCRIPTION = 'bdaas/application_console/update_description';
export const FUNCTION_RENAME = 'bdaas/application_console/rename_function';
export const FUNCTION_DISPLAY = 'bdaas/application_console/update_display';
export const FUNCTION_SORT = 'bdaas/application_console/order_function';
export const UPDATE_ICON = 'bdaas/application_console/change_icon';
export const UPDATE_DAPP_NAME = 'bdaas/application_console/update_app_name';
export const CONSOLE_SET_CALL_BACK = 'bdaas/application_console/set_callback_function';
export const CONSOLE_SET_FUNCTION_PARAMETER = 'bdaas/application_console/rename_function_parameter';