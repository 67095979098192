import React from 'react';
import classes from './Modal.module.css';
import Backdrop from '../Backdrop/Backdrop';

const modal = (props) => {
    return (
        <div onClick={props.onClick} >
            <Backdrop show={props.show} />
            <div className={classes.Modal} style={{
                transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: props.show ? '1' : '0',
                top: props.top,
                width: props.width,
                left: props.left,
                zIndex: props.zIndex
            }}>
                <div style={{ textAlign: 'center' }}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default modal;