import React, { useState, useEffect, useContext } from 'react';
import classes from './Monitor.module.scss';
import { AuthContext } from '../../../context/auth-context';
import { MonitorContext } from '../../../context/monitor-context';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as Common from '../../../common';
import { checkAuth } from '../../../commonFunc';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';

const Transactions = () => {
    const authContext = useContext(AuthContext);
    const monitorContext = useContext(MonitorContext);
    const [searchInput, setSearchInput] = useState();
    const [currPage, setCurrPage] = useState(1);
    const [pages, setPages] = useState();
    const [perPage, setPerPage] = useState(10);
    const [txData, setTxData] = useState();
    const pagination_options = [10, 25, 50];
    const [refreshing, setRefreshing] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);

    const getTxs = (searchInput) => {
        setTxData(null);
        setRefreshing(true);

        let data = {
            credentials: authContext.credentials,
            data: {
                page: currPage,
                per_page: perPage,
                chain_id: monitorContext.chain.chain_id,
                search_term: searchInput
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_GET_TRANSACTIONS_V2, data).then(response => {
            setRefreshing(false);

            if (response.data.status === '1') {
                setTxData(response.data.items);
                setPages(Math.ceil(parseFloat(response.data.total) / perPage))
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }


    const isInputValid = (input) => {
        var format = /^[a-zA-Z0-9]+$/
        return format.test(input)
    }

    useEffect(() => {
        if (monitorContext.chain) {
            getTxs();
        }
    }, [monitorContext, perPage, currPage])

    let txSearch = <div className={classes.search}><InputGroup>
        <FormControl
            placeholder="Search transaction by transaction hash"
            aria-label="Search transaction by transaction hash"
            aria-describedby="search-addon"
            onChange={(e) => {
                setSearchInput(e.target.value);
                if (!isInputValid(e.target.value)) {
                    setShowFeedback(true);
                } else setShowFeedback(false);
            }}
            maxLength="66"
        />
        {
            showFeedback ? <InputGroup.Append>
                <div className={[classes.mainBtn, classes.disabled].join(' ')} ><i className="fas fa-search" style={{ marginLeft: 0 }}></i>Search</div>
            </InputGroup.Append> : <InputGroup.Append>
                    <div className={classes.mainBtn} onClick={() => { getTxs(searchInput) }}><i className="fas fa-search" style={{ marginLeft: 0 }}></i>Search</div>
                </InputGroup.Append>
        }
    </InputGroup>
        {showFeedback && searchInput !== '' ? <small>Please provide a valid transaction hash</small> : null}
        <div><span className={classes.actionBtn} onClick={() => getTxs()}>Show All Transactions</span></div>
    </div>

    let tableRows = txData ? txData.length > 0 ? txData.map((tx, i) => {
        let txType = tx.tx_type;
        if (txType === 'invoke') {
            let inputs = ''
            tx.contract.inputs.forEach(input => {
                inputs += (input + ', ')
            })
            if (inputs !== '') inputs = inputs.substring(0, inputs.length - 2);
            inputs = '(' + inputs + ')'
            txType = <OverlayTrigger
                placement={"right"}
                overlay={
                    <Tooltip style={{ fontSize: '12px' }}>
                        Invoked function - <b>{tx.contract.function}{inputs}</b> from contract - <b>{tx.contract.contract_name}</b>
                    </Tooltip>
                }
            ><Badge pill variant="info" style={{ color: 'white' }}>Invoke<i className="fas fa-info-circle" style={{ color: 'white', marginRight: '0' }}></i></Badge></OverlayTrigger>
        } else if (txType === 'deploy') {
            let isPrivate = tx.contract.is_private ? <i className="fas fa-lock" title="private contract" style={{ color: 'white' }}></i> : null;
            txType = <OverlayTrigger
                placement={"right"}
                overlay={
                    <Tooltip style={{ fontSize: '12px' }}>
                        Deplyed <b>{tx.contract.is_private ? 'Private ' : 'Public '}</b>Contract - <b>{tx.contract.contract_name}</b>
                    </Tooltip>
                }
            >
                <Badge pill variant="secondary" style={{ color: 'white' }}>Deploy{isPrivate}<i className="fas fa-info-circle" style={{ color: 'white', marginRight: '0' }}></i></Badge></OverlayTrigger>
        } else if (txType === 'transfer') {
            txType = <Badge pill variant="warning" style={{ color: 'white' }}>Transfer</Badge>
        }
        return <tr key={i}>
            <td><Link to={`/chain/monitor/transactions/${tx.id}`}><b>Tx</b> {trimHash(tx.tx_hash)}</Link><i className="far fa-copy" title="copy hash to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(tx.tx_hash) }}></i></td>
            <td><b>From</b> {trimHash(tx.from)}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(tx.from) }}></i></td>
            <td><b>To</b> {trimHash(tx.to)}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(tx.to) }}></i></td>
            <td>{txType}</td>
            <td><span title={getLocalTime(tx.tx_time)}>{getLocalTime(tx.tx_time)}</span></td>
        </tr>
    }) : <tr><td colSpan="5">No Transaction Data available</td></tr> : <tr><td colSpan="5">Fetching Data...</td></tr>;

    let txTable = <Table hover className={classes.table}>
        <tbody>
            {tableRows}
        </tbody>
    </Table>

    const paginationUpdate = (perPage) => {
        setPerPage(perPage);
        setCurrPage(1);
    }

    const paginationOptions = pagination_options.map((number, index) => {
        let classes = "option"
        if (number === perPage) classes = "option selected";
        return <span className={classes} key={index} onClick={() => { paginationUpdate(number) }}>{number}</span>
    })

    const tablePagination = pages > 0 ? <ReactPaginate
        forcePage={currPage - 1}
        previousLabel={<i className="fas fa-caret-left"></i>}
        nextLabel={<i className="fas fa-caret-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h"></i>}
        breakClassName={'break-me'}
        pageCount={pages}
        onPageChange={(page) => {
            setCurrPage(page.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
    /> : null

    let txTableSession = <div>
        <div className={classes.spaceBetween}>
            <div className={classes.thirdTitle}>Lastest Transactions</div>
            <div>
                <span className="pagination_menu" style={{ fontSize: '1.2rem', display: 'inline-block' }}>show {paginationOptions} items</span>{' '}
                {
                    !refreshing ? <span className={classes.refreshBtn} title="refresh data"><i className="fas fa-sync-alt" onClick={() => getTxs()}></i></span> :
                        <Spinner animation="border" variant="secondary" />
                }
            </div>
        </div>
        {txTable}
        {tablePagination}
    </div>

    return (
        <div className={classes.monitor}>
            <div className={classes.subTitle}>Transactions<OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Popover id="contracts-popover">
                    <Popover.Content>
                        <p>A transaction is a new record of exchange of some value or data between two public addresses of the blockchain. </p>                                      </Popover.Content>
                </Popover>}
            ><sup><i class="fas fa-info-circle"></i></sup></OverlayTrigger></div>
            {/* {chainDesc} */}
            {txSearch}
            {monitorContext.chain ? txTableSession : <div>Click <Link to="/chain">here</Link> to select a chain first</div>}
        </div>
    )
}

function trimHash(hash) {
    if (hash === '') return 'Not Available'
    return hash.substring(0, 6) + '...' + hash.substring(hash.length - 6, hash.length)
}

function copyToClipboard(secretInfo) {
    var $body = document.getElementsByTagName('body')[0];
    var $tempInput = document.createElement('INPUT');
    $body.appendChild($tempInput);
    $tempInput.setAttribute('value', secretInfo)
    $tempInput.select();
    document.execCommand('copy');
    $body.removeChild($tempInput);
}

function getLocalTime(time) {
    let utc_time = (new Date(time)).toString() + ' UTC';
    let localtime = new Date(utc_time).toLocaleString();
    return localtime;
}

export default Transactions;