import React, { useState, useEffect, useContext } from 'react';
import classes from './FuncRow.module.scss';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import * as Common from '../../../common';
import { AuthContext } from '../../../context/auth-context';
import { checkAuth } from '../../../commonFunc';
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const FuncRow = (props) => {
    const authContext = useContext(AuthContext);
    let web3Contract = props.web3Contract;
    let abi = props.data;
    let name = abi.name;
    let inputsMeta = props.data.inputs;
    const [showDetail, setShowDetail] = useState(false);
    const [inputs, setInputs] = useState(new Array(inputsMeta.length || 0));
    const [isExecuting, setIsExecuting] = useState(false);
    const [response, setResponse] = useState();
    const [inputValidation, setInputValidation] = useState();
    const [showResponse, setShowResponse] = useState(false);
    const [showAction, setShowAction] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [display, setDisplay] = useState(abi.display);
    const [tempDisplay, setTempDisplay] = useState(abi.display);
    const [rename, setRename] = useState(abi.rename);
    const [tempName, setTempName] = useState(abi.rename !== '' ? abi.rename : abi.name);
    const [desc, setDesc] = useState(abi.description);
    const [tempDesc, setTempDesc] = useState(abi.description);

    let chain = props.chain;
    let contract = props.contract;
    let account = props.account;
    let sorting = props.sortable;
    let callbackFunctions = props.calls;

    const [cbName, setCbName] = useState(abi.callback_function);
    const [cbMsg, setCbMsg] = useState(abi.response_message);
    const [enableResponseMsg, setEnableResponseMsg] = useState(abi.response_message !== null && abi.response_message !== undefined);
    const [enableResponseFunction, setEnableResponseFunction] = useState(abi.callback_function !== null && abi.callback_function !== undefined);
    const [cbResponse, setCbResponse] = useState();

    const inputChangeHandler = (index, value) => {
        let newInputs = [...inputs];
        newInputs[index] = value;
        setInputs(newInputs);
    }

    let dataInputs = {};
    inputsMeta.forEach(input => {
        dataInputs[input.name] = {
            value: 'SOME VALUE',
            type: input.type
        }
    })

    const [inputRename, setInputRename] = useState(inputsMeta.map(input => {
        return input.rename || input.name
    }))

    const executeHandler = (event) => {
        if (event) {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                setInputValidation(true);
                event.preventDefault();
                event.stopPropagation();
            } else {
                setInputValidation(false);
                setShowDetail(false);
                event.preventDefault();
                if (chain) {
                    executeBTchains();
                } else {
                    executeOfficialEth();
                }
            }
        } else {
            setShowDetail(false);
            if (chain) {
                executeBTchains();
            } else {
                executeOfficialEth();
            }
        }
    }

    const invokeCallBack = (callback, cb) => {
        if (typeof callback === 'string') {
            setCbResponse(callback);
            cb();
        } else if (callback && typeof callback === 'object') {
            if (chain) {
                executeBTchainsCallback(callback[0].name, [], cb)
            } else {
                executeOfficialEthCallback(callback[0], callback[0].name, [], cb)
            }
        } else cb();
    }

    const executeBTchainsCallback = (cb_name, cb_inputs, cb) => {
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: chain.chain_id,
                contract_address: contract.contract_address,
                contract_name: contract.contract_name,
                function: cb_name,
                inputs: cb_inputs,
                account: account.address,
                node: contract.node_index,
                gas: 204800000,
                private_for: contract.private_for
            }
        }
        axios.post(Common.ENDPOINT + 'bdaas/' + chain.type + '/invoke_contract', data).then(response => {
            cb();
            if (response.data.status === '1') {
                setCbResponse(response.data)
            }
        })
    }

    const executeBTchains = () => {
        setIsExecuting(true);
        setResponse(null);
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: chain.chain_id,
                contract_address: contract.contract_address,
                contract_name: contract.contract_name,
                function: name,
                inputs: inputs,
                account: account.address,
                node: contract.node_index,
                gas: 204800000,
                private_for: contract.private_for
            }
        }
        axios.post(Common.ENDPOINT + 'bdaas/' + chain.type + '/invoke_contract', data).then(response => {
            // set callback message or invoke callback function
            if (response.data.status === '1') {
                let cb = null
                if (abi.response_message) cb = abi.response_message
                if (abi.callback_function) {
                    cb = callbackFunctions.filter(func => {
                        return func.name === abi.callback_function
                    })
                }
                invokeCallBack(cb, () => {
                    setIsExecuting(false);
                    setResponse(response.data);
                    setShowResponse(true);
                })
            } else {
                setIsExecuting(false);
                setResponse(response.data);
                setShowResponse(true);
            }
        })
    }

    const executeOfficialEthCallback = (cbAbi, cb_name, cb_inputs, cb) => {
        try {
            if (cbAbi.constant) {
                web3Contract.methods[cb_name](...cb_inputs)
                    .call()
                    .then(res => {
                        setCbResponse(res);
                        cb();
                    });
            } else {
                web3Contract.methods[cb_name](...cb_inputs)
                    .send({
                        from: account,
                        gas: 4000000,
                        gasPrice: "20000000000" /* 20gwei */
                    })
                    .once("transactionHash", function (hash) {
                        console.log(`hash: ${hash}`);
                    })
                    .once("receipt", function (receipt) {
                        // console.log(`receipt: ${JSON.stringify(receipt)}`);
                    })
                    .on("error", function (error) {
                        console.log(error);
                        alert(`Error Occurred: ${JSON.stringify(error)}`)
                    })
                    .then(res => {
                        setCbResponse(res);
                        cb();
                    });
            }
        }
        catch (error) {
            console.log(error)
            setIsExecuting(false);
        }
    }

    const executeOfficialEth = () => {
        setIsExecuting(true);
        setResponse(null);
        try {
            if (abi.constant) {
                web3Contract.methods[name](...inputs)
                    .call()
                    .then(res => {
                        setIsExecuting(false);
                        setResponse(res);
                        setShowResponse(true);
                    });
            } else {
                web3Contract.methods[name](...inputs)
                    .send({
                        from: account,
                        gas: 4000000,
                        gasPrice: "20000000000" /* 20gwei */
                    })
                    .once("transactionHash", function (hash) {
                        console.log(`hash: ${hash}`);
                    })
                    .once("receipt", function (receipt) {
                        // console.log(`receipt: ${JSON.stringify(receipt)}`);
                    })
                    .on("error", function (error) {
                        console.log(`Error Occurred: ${JSON.stringify(error)}`)
                        // let errorMsg = JSON.parse(JSON.stringify(error)).reason || 'Error: function requirement not met'
                        setResponse(JSON.parse(JSON.stringify(error)));
                        setIsExecuting(false);
                        setShowResponse(true);
                    })
                    .then(res => {
                        let cb = null
                        if (abi.response_message) cb = abi.response_message
                        if (abi.callback_function) {
                            cb = callbackFunctions.filter(func => {
                                return func.name === abi.callback_function
                            })
                        }
                        invokeCallBack(cb, () => {
                            setIsExecuting(false);
                            setResponse(res);
                            setShowResponse(true);
                        })
                    });
            }
        }
        catch (error) {
            console.log(error)
            setIsExecuting(false);
        }
    }

    const updateFunctionName = () => {
        if ((rename !== '' && rename !== tempName) || (rename === '' && abi.name !== tempName)) {
            let data = {
                credentials: authContext.credentials,
                data: {
                    contract_id: contract.contract_id,
                    function_name: name,
                    function_new_name: tempName,
                }
            }
            axios.post(Common.ENDPOINT + Common.FUNCTION_RENAME, data).then(response => {
                if (response.data.status === '1') {
                    setRename(tempName);
                    props.update('rename', tempName);
                }
                else {
                    if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
                }
                updateFunctionDesc();
            })
        } else updateFunctionDesc();
    }

    const updateFunctionDesc = () => {
        if (abi.description !== tempDesc) {
            let data = {
                credentials: authContext.credentials,
                data: {
                    contract_id: contract.contract_id,
                    function_name: name,
                    description: tempDesc,
                }
            }
            axios.post(Common.ENDPOINT + Common.FUNCTION_DESCRIPTION, data).then(response => {
                if (response.data.status === '1') {
                    setDesc(tempDesc);
                    props.update('description', tempDesc);
                }
                else {
                    if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
                }
                updateFunctionDisplay()
            })
        } else updateFunctionDisplay()
    }

    const updateFunctionDisplay = () => {
        if (abi.display !== tempDisplay) {
            let data = {
                credentials: authContext.credentials,
                data: {
                    contract_id: contract.contract_id,
                    function_name: name,
                    display: tempDisplay ? "1" : "0",
                }
            }
            axios.post(Common.ENDPOINT + Common.FUNCTION_DISPLAY, data).then(response => {
                if (response.data.status === '1') {
                    setDisplay(tempDisplay);
                    props.update('display', tempDisplay);
                }
                else {
                    if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
                }
                setCallBackFunction()
            })
        } else setCallBackFunction()
    }

    const setCallBackFunction = () => {
        if (cbName !== abi.callback_function || cbMsg !== abi.response_message) {
            if (cbMsg === '') setCbMsg(null);
            let data = {
                credentials: authContext.credentials,
                data: {
                    contract_id: contract.contract_id,
                    function_name: name,
                    callback_function: cbName,
                    response_message: cbMsg
                }
            }
            axios.post(Common.ENDPOINT + Common.CONSOLE_SET_CALL_BACK, data).then(response => {
                if (response.data.status === '1') {
                    props.update('callback_function', cbName);
                    props.update('response_message', cbMsg);
                }
                else {
                    if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
                }
                functionDisplayInputsHandler()
            })
        } else functionDisplayInputsHandler();
    }

    const setFunctionDisplayInputname = (index, length) => {
        if (index >= length) return
        if (inputRename[index] === inputsMeta[index].rename || inputRename[index] === '') {
            setFunctionDisplayInputname(index + 1, length)
        }
        // console.log('1')
        axios.post(Common.ENDPOINT + Common.CONSOLE_SET_FUNCTION_PARAMETER, {
            credentials: authContext.credentials,
            data: {
                contract_id: contract.contract_id,
                function_name: name,
                parameter_name: inputsMeta[index].name,
                parameter_new_name: inputRename[index]
            }
        }).then(response => {
            if (response.data.status === '1') {
                inputsMeta[index].rename = inputRename[index];
                setFunctionDisplayInputname(index + 1, length)
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) {
                    alert(response.data.message)
                    setFunctionDisplayInputname(index + 1, length)
                }
            }
        })
    }

    const functionDisplayInputsHandler = () => {
        setFunctionDisplayInputname(0, inputRename.length)
    }

    const editFunctionHandler = () => {
        setShowEditModal(false);
        updateFunctionName();
    }

    useEffect(() => {
        if (showResponse && response) {
            document.getElementById(name).scrollIntoView({ behavior: 'smooth' });
        } else if (isExecuting) {
            document.getElementById(`${name}-exe`).scrollIntoView({ behavior: 'smooth' });
        }
    }, [showResponse, isExecuting, response])

    useEffect(() => {
        if (cbName) setCbMsg(null);
    }, [cbName])

    useEffect(() => {
        if (cbMsg) setCbName(null);
    }, [cbMsg])

    useEffect(() => {
        if (!enableResponseFunction) setCbName(null);
    }, [enableResponseFunction])

    useEffect(() => {
        if (!enableResponseMsg) setCbMsg(null);
    }, [enableResponseMsg])

    const paraTableRows = inputsMeta.length > 0 ? inputsMeta.map((input, i) => {
        let inputType = "text";
        if (input.type === 'uint256' || input.type === 'int') inputType = "number"
        if (input.type === 'bool' && inputs[i] === undefined) inputChangeHandler(i, false)
        return <div key={i}>
            {
                input.type !== 'bool' ? <div><h5><b>{input.name}</b>{input.rename ? <span> ({input.rename})</span> : null}</h5>
                    <Form.Group controlId={`input${i}`}>
                        <Form.Control required type={inputType} placeholder={input.type} value={inputs[i]} onChange={(e) => { inputChangeHandler(i, String(e.target.value)) }} />
                        <Form.Control.Feedback type="invalid">Input value cannot be empty</Form.Control.Feedback>
                    </Form.Group></div> :
                    <div className={classes.spaceBetween}>
                        <h5><b>{input.name}</b>{input.rename ? <span> ({input.rename})</span> : null}</h5>
                        <BootstrapSwitchButton width={60} height={30}
                            onstyle="success"
                            offstyle="secondary"
                            checked={inputs[i] === true}
                            onlabel='True'
                            offlabel='False'
                            onChange={() => {
                                if (inputs[i] === true) {
                                    inputChangeHandler(i, false)
                                } else {
                                    inputChangeHandler(i, true)
                                }
                            }}
                        />
                    </div>
            }
        </div>
    }) : null
    const parameterTable = inputsMeta.length > 0 ? <div>
        <Table style={{ fontSize: '1.4rem' }} borderless>
            <tbody>
                {paraTableRows}
            </tbody>
        </Table></div> : null;

    const getRenderedResponse = (response) => {
        let renderedResponse = response !== undefined && response !== null ? response !== true && response !== false && response.result !== undefined ? response.result : response : null;
        if (renderedResponse && typeof renderedResponse === 'object') {
            if (renderedResponse.length > 0) {
                renderedResponse = <ul>{
                    renderedResponse.map(res => {
                        return <li>{res}</li>
                    })}</ul>
            } else {
                renderedResponse = <ul>{
                    Object.keys(renderedResponse).map(key => {
                        return <li>{key}: {renderedResponse[key]}</li>
                    })}</ul>
            }
        } else {
            // for boolean return (true/false)
            renderedResponse = String(renderedResponse);
        }
        return renderedResponse;
    }

    let renderedResponse = response && (response.status === true || response.status === '1') ? getRenderedResponse(response) : response; // do not need to render eth-official failed response
    let renderedCbResponse = getRenderedResponse(cbResponse);

    const responseTable = response !== undefined && response !== null ? <Table variant="light" className={classes.responseTable} style={{ fontSize: '1.4rem', borderRadius: '3px' }} id={name}>
        {
            response.status === '1' ? /* success BT chain response */
                response.op === 'call' ? /* call function */
                    <tbody>
                        <tr><td><Badge variant="success" style={{ fontSize: '1.4rem' }}>Success</Badge></td></tr>
                        <tr><td><span style={{ marginRight: '1rem' }}>Result</span><b>{renderedResponse}</b></td></tr>
                    </tbody> : /* transact function */
                    <tbody>
                        <tr><td><Badge variant="success" style={{ fontSize: '1.4rem', cursor: 'pointer' }}>
                            <OverlayTrigger placement="right" delay={{ show: 0, hide: 200 }} trigger="click"
                                overlay={
                                    <Tooltip id="result-tooltip">
                                        <table className={classes.tooltipTable}>
                                            <tr><td>Block</td><td><i className="fas fa-cube" style={{ color: '#0070b3' }}></i>{response.block_number}</td></tr>
                                            <tr><td>Transaction Hash</td><td>
                                                <OverlayTrigger placement="right"
                                                    overlay={
                                                        <Tooltip id="tx-hash-tooltip" >
                                                            <div style={{ fontSize: '1.2rem', width: '100px' }}>click to copy!</div>
                                                        </Tooltip>
                                                    }
                                                >
                                                    <i className="fas fa-copy" style={{ color: '#0070b3', cursor: 'pointer' }} onClick={() => copyToClipboard(response.tx_hash)}></i>
                                                </OverlayTrigger>
                                                {response.tx_hash}</td></tr>
                                            {
                                                response.gasUsed ? <tr><td>Gas Used</td><td>{response.gasUsed}</td></tr> : null
                                            }
                                            {
                                                response.private_for ?
                                                    response.private_for.length === 0 ?
                                                        <tr><td>Permission</td><td>Public</td></tr> :
                                                        <tr><td>Permission</td><td>Private <i className="fas fa-lock"></i>(private for: {response.private_for.map(index => {
                                                            return <b>Node{index + 1} </b>
                                                        })})</td></tr> : null
                                            }
                                        </table>
                                    </Tooltip>
                                }><sapn>Success<i className="fas fa-info-circle" style={{ marginRight: '0' }}></i></sapn>
                            </OverlayTrigger>
                        </Badge></td></tr>
                        {
                            (response.events && response.events.length > 0) || (renderedCbResponse && abi.callback_function || abi.response_message) ?
                                <tr>
                                    <td><span style={{ marginRight: '1rem' }}>Result</span>
                                        {
                                            renderedCbResponse && abi.callback_function || abi.response_message ? <b>{renderedCbResponse}</b> : null
                                        }
                                        {
                                            response.events && response.events.length > 0 ?
                                                response.events.map(e => {
                                                    let args = Object.keys(e.args).map(key => {
                                                        return <tr><td style={{ padding: '0 1rem' }}><b>{key}</b></td><td style={{ padding: '0 1rem' }}>{e.args[key]}</td></tr>
                                                    })
                                                    return <OverlayTrigger placement="top" delay={{ show: 0, hide: 200 }} trigger="click"
                                                        overlay={
                                                            <Tooltip id="events-tooltip" className={classes.myTooltip}><table style={{ fontSize: '1.4rem', textAlign: 'left' }}>
                                                                {args}
                                                            </table></Tooltip>
                                                        }><Badge variant="info" style={{ fontSize: '90%', marginLeft: '3px', cursor: 'pointer' }}>
                                                            {e.event}<i className="fas fa-info-circle" style={{ marginRight: '0' }}></i>
                                                        </Badge></OverlayTrigger>
                                                })
                                                : null
                                        }
                                    </td>
                                </tr> : null
                        }
                    </tbody> : response.status === '0' ? /* failed BT chain response */
                    <tbody>
                        <tr><td><Badge variant="danger" style={{ fontSize: '1.4rem' }}>Fail</Badge></td></tr>
                        <tr><td>{response.message}</td></tr>
                    </tbody> : response.status === true ? /* success eth-official chain response for transact invoke*/
                        <tbody>
                            <tr><td><Badge variant="success" style={{ fontSize: '1.4rem' }}>
                                <OverlayTrigger placement="right" delay={{ show: 0, hide: 200 }} trigger="click"
                                    overlay={
                                        <Tooltip id="result-tooltip">
                                            <table className={classes.tooltipTable}>
                                                <tr><td>Block</td><td><i className="fas fa-cube" style={{ color: '#0070b3' }}></i>{response.blockNumber}</td></tr>
                                                <tr><td>Transaction Hash</td><td>
                                                    <OverlayTrigger placement="right"
                                                        overlay={
                                                            <Tooltip id="tx-hash-tooltip" >
                                                                <div style={{ fontSize: '1.2rem', width: '100px' }}>click to copy!</div>
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <i className="fas fa-copy" style={{ color: '#0070b3', cursor: 'pointer' }} onClick={() => copyToClipboard(response.transactionHash)}></i>
                                                    </OverlayTrigger>
                                                    {response.transactionHash}</td></tr>
                                                <tr><td>Gas Used</td><td>{response.gasUsed}</td></tr>
                                            </table>
                                        </Tooltip>
                                    }
                                >
                                    <sapn>Success<i className="fas fa-info-circle" style={{ marginRight: '0' }}></i></sapn>
                                </OverlayTrigger>
                            </Badge></td></tr>
                            {
                                (renderedCbResponse && abi.callback_function || abi.response_message) || (response.events && Object.keys(response.events).length > 0) ?
                                    <tr>
                                        <td><span style={{ marginRight: '1rem' }}>Result</span>
                                            {
                                                renderedCbResponse && abi.callback_function || abi.response_message ? <b>{renderedCbResponse}</b> : null
                                            }
                                            {
                                                response.events && Object.keys(response.events).length > 0 ?
                                                    Object.keys(response.events).map(e => {
                                                        let args = Object.keys(response.events[e].returnValues).map(key => {
                                                            if (isNaN(Number(key)))
                                                                return <tr><td style={{ padding: '0 1rem' }}><b>{key}</b></td><td style={{ padding: '0 1rem' }}>{response.events[e].returnValues[key]}</td></tr>
                                                        })
                                                        return <OverlayTrigger placement="top" delay={{ show: 0, hide: 200 }} trigger="click"
                                                            overlay={
                                                                <Tooltip id="events-tooltip" className={classes.myTooltip}><table style={{ fontSize: '1.4rem', textAlign: 'left' }}>
                                                                    {args}
                                                                </table></Tooltip>
                                                            }><Badge variant="info" style={{ fontSize: '90%', marginLeft: '3px', cursor: 'pointer' }}>
                                                                {e}<i className="fas fa-info-circle" style={{ marginRight: '0' }}></i>
                                                            </Badge></OverlayTrigger>
                                                    })
                                                    : null
                                            }
                                        </td>
                                    </tr> : null
                            }
                        </tbody> : response.receipt && response.receipt.status === false ? /* fail eth-official chain response for invoke invoke*/
                            <tbody>
                                <tr><td><Badge variant="danger" style={{ fontSize: '1.4rem' }}>Fail</Badge></td></tr>
                                <tr><td>Error: {response.reason || 'Function requirement not met'}</td></tr>
                            </tbody> : response.code ? /* fail eth-official chain response for invoke invoke*/
                                <tbody>
                                    <tr><td><Badge variant="danger" style={{ fontSize: '1.4rem' }}>Fail</Badge></td></tr>
                                    <tr><td>Error: {response.message}</td></tr>
                                </tbody> : /* eth-official chain response for call invoke*/
                                <tbody>
                                    <tr><td><Badge variant="success" style={{ fontSize: '1.4rem' }}>Success</Badge></td></tr>
                                    <tr><td><span style={{ marginRight: '1rem' }}>Result</span><b>{renderedResponse}</b></td></tr>
                                </tbody>
        }
    </Table> : null

    const detailModal = <Modal animation={true} show={showDetail} onHide={() => { setShowDetail(false) }} dialogClassName={classes.modal}>
        <Form noValidate validated={inputValidation} onSubmit={e => executeHandler(e)}>
            <Modal.Header closeButton>
                <Modal.Title><b>{name} {rename ? <span>({rename})</span> : null}</b></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.content}>
                    {parameterTable}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { setShowDetail(false) }}>Cancel</Button>
                <Button variant="primary" type="submit">Execute</Button>
            </Modal.Footer>
        </Form>
    </Modal>

    const callBackFunctionDropDown = callbackFunctions ? callbackFunctions.map((abi, i) => {
        if (abi.inputs && abi.inputs.length === 0)
            return <Dropdown.Item key={i} eventKey={i} onSelect={() => setCbName(abi.name)}>{abi.name}</Dropdown.Item>
    }) : null;

    const editFunctionInputs = inputsMeta.length > 0 ? <div>
        <div><h5>Function Input Name</h5></div>
        <div><Table style={{ fontSize: '1.2rem' }}>
            <thead><tr><td>Input Name</td><td>Display Input Name</td></tr></thead>
            <tbody>
                {
                    inputsMeta.map((input, i) => {
                        return <tr>
                            <td><b>{input.name}</b></td>
                            <td><input type="text" value={inputRename[i]} style={{ width: '100%' }} onChange={(e) => {
                                let updatedInputRename = [...inputRename];
                                updatedInputRename[i] = e.target.value;
                                setInputRename(updatedInputRename);
                            }} /></td>
                        </tr>
                    })
                }
            </tbody>
        </Table>

        </div>
    </div> : null

    const editModal = <Modal animation={true} show={showEditModal} onHide={() => { setShowEditModal(false) }}>
        <Modal.Header closeButton>
            <Modal.Title>Edit Function - <b>{name}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div><h5>Function Display Name</h5></div>
            <div><input type="text" value={tempName} onChange={(e) => setTempName(e.target.value)} style={{ width: '100%' }} /></div>
            <div><h5>Function Description</h5></div>
            <div><textarea value={tempDesc} onChange={(e) => setTempDesc(e.target.value)} style={{ width: '100%' }} /></div>
            {editFunctionInputs}
            <div className={classes.spaceBetween}>
                <h5>Display this function in Gallery</h5>
                <span><BootstrapSwitchButton width={60} height={30}
                    onstyle="success"
                    offstyle="secondary"
                    checked={tempDisplay === 1}
                    onlabel='Yes'
                    offlabel='No'
                    onChange={() => { setTempDisplay(!tempDisplay) }}
                /></span>
            </div>
            {
                !enableResponseFunction && !abi.constant ? <div className={classes.spaceBetween}>
                    <h5>Enable Response Message</h5>
                    <span><BootstrapSwitchButton width={60} height={30}
                        onstyle="success"
                        offstyle="secondary"
                        checked={enableResponseMsg}
                        onlabel='Yes'
                        offlabel='No'
                        onChange={() => { setEnableResponseMsg(!enableResponseMsg) }}
                    /></span>
                </div> : null
            }
            {
                !enableResponseMsg && !abi.constant ? <div className={classes.spaceBetween}>
                    <h5>Enable Response Function</h5>
                    <span><BootstrapSwitchButton width={60} height={30}
                        onstyle="success"
                        offstyle="secondary"
                        checked={enableResponseFunction}
                        onlabel='Yes'
                        offlabel='No'
                        onChange={() => { setEnableResponseFunction(!enableResponseFunction) }}
                    /></span>
                </div> : null
            }
            {
                enableResponseMsg && !abi.constant ? <div><h5>Response Message</h5><input type="text" value={cbMsg} onChange={(e) => setCbMsg(e.target.value)} style={{ width: '100%' }} /></div> : null
            }
            {
                enableResponseFunction && !abi.constant ? <div><h5>Response Function</h5>
                    <DropdownButton title={cbName || 'please select a callback function (Optional)'} variant="custom-blue">
                        {callBackFunctionDropDown}
                    </DropdownButton></div> : null
            }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => { setShowEditModal(false) }}>Cancel</Button>
            <Button variant="primary" onClick={editFunctionHandler}>Save</Button>
        </Modal.Footer>
    </Modal>

    const editFunctionBtn = <div onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowEditModal(true);
    }} className={classes.action}><i className="fas fa-pencil-alt"></i></div>

    const descToolTip = desc ? <OverlayTrigger placement="top" delay={{ show: 0, hide: 200 }} overlay={
        <Tooltip id="desc-tooltip"><span>{desc}</span></Tooltip>
    }><i className="fas fa-info-circle"></i></OverlayTrigger> : null

    const isHide = !display ? <OverlayTrigger placement="top" delay={{ show: 0, hide: 200 }} overlay={
        <Tooltip id="hide-tooltip" ><span>This function is not visible in Dapp Gallery</span></Tooltip>
    }><i className="fas fa-eye-slash"></i></OverlayTrigger> : null;

    return <div>
        {detailModal}
        {editModal}
        {
            sorting ? <div className={classes.row}>
                <div className={[classes.header, classes.sorting].join(' ')}>
                    <div className={classes.name}>{name} {rename !== '' ? <span>({rename})</span> : null} <span style={{ fontSize: '1.2rem' }}>{descToolTip}{isHide}</span></div>
                </div>
            </div>
                : <div className={classes.row} onMouseOver={() => setShowAction(true)} onMouseLeave={() => setShowAction(false)}>
                    {
                        inputs.length === 0 ? <div className={classes.header} onClick={() => {
                            if (!isExecuting) {
                                if (inputs.length === 0) executeHandler();
                                else setShowDetail(true);
                            }
                        }} >
                            <div className={classes.name}>{name} {rename !== '' ? <span>({rename})</span> : null} <span style={{ fontSize: '1.2rem' }}>{descToolTip}{isHide}</span></div>
                            {
                                showAction ? <div style={{ textAlign: 'right' }}>
                                    {showResponse && response ? <div onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setShowResponse(false);
                                    }} className={classes.action}><i className="fas fa-compress-arrows-alt"></i></div> :
                                        !showResponse && response ? <div onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setShowResponse(true);
                                        }} className={classes.action}><i className="fas fa-expand-arrows-alt"></i></div> : null}
                                    {editFunctionBtn}
                                </div> : null
                            }
                        </div> :
                            <div className={classes.header} onClick={(e) => {
                                if (!isExecuting) {
                                    if (inputs.length === 0) executeHandler();
                                    else setShowDetail(true);
                                }
                            }} >
                                <div className={classes.name}>{name} {rename !== '' ? <span>({rename})</span> : null} <span style={{ fontSize: '1.2rem' }}>{descToolTip}{isHide}</span></div>
                                {
                                    showAction ? <div style={{ textAlign: 'right' }}>
                                        {showResponse && response ? <div onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setShowResponse(false);
                                        }} className={classes.action}><i className="fas fa-compress-arrows-alt"></i></div> :
                                            !showResponse && response ? <div onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setShowResponse(true);
                                            }} className={classes.action}><i className="fas fa-expand-arrows-alt"></i></div> : null
                                        }
                                        {editFunctionBtn}
                                    </div> : null
                                }
                            </div>
                    }
                    {isExecuting ? <div style={{ textAlign: 'center', color: '#0070b3', padding: '1rem' }} id={`${name}-exe`}><Spinner as='span' animation='border' role='status' aria-hidden='true' /> <b>Executing...</b></div> : null}
                    {showResponse && response !== undefined && response !== null ? <div>{responseTable}</div> : null}
                </div>
        }
    </div>
}

function copyToClipboard(secretInfo) {
    var $body = document.getElementsByTagName('body')[0];
    var $tempInput = document.createElement('INPUT');
    $body.appendChild($tempInput);
    $tempInput.setAttribute('value', secretInfo)
    $tempInput.select();
    document.execCommand('copy');
    $body.removeChild($tempInput);
}

export default FuncRow;
