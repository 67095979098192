import React, { useState, useContext, useEffect } from 'react';
import classes from './TopBanner.module.scss';
import axios from 'axios';
import * as Common from '../../common';

const TopBanner = () => {
  const [reloadTrigger, setReloadTrigger] = useState();
  const [announcement, setAnnouncement] = useState();
  const [show, setShow] = useState();
  const [bgcolor, setBgcolor] = useState();
  const [closable, setClosable] = useState();

  let reloadInterval;

  const hardReload = () => {
    window.location.reload(true); // works for FireFox (at least)

    // method that didn't work for Chrome or Firefox 
    // window.location.href = window.location.href;
    // window.history.go(0);
    // window.location.href = window.location.href+'?eraseCache=true';
  }

  const _getAnnouncement = (data) => {
    if (data) {
      let newBanner = {};
      data.map(obj => {
        let key = obj.key;
        let value = obj.value;
        newBanner[key] = value;
      })
      setBgcolor("#" + newBanner.color_hex);
      setClosable(newBanner.closable);
      setAnnouncement(newBanner.content);
      if (newBanner.content !== window.localStorage.getItem('_announcement')) {
        setShow(true);
        window.localStorage.setItem('_show_announcement', 'true');
      }
      window.localStorage.setItem('_announcement', newBanner.content);
    } else {
      // no announcement
      window.localStorage.removeItem('_announcement');
      window.localStorage.removeItem('_show_announcement');
      setAnnouncement(false);
    }
  }
  const _getVersion = (data) => {
    if (data) {
      let newVersion = {};
      data.map(obj => {
        let key = obj.key;
        let value = obj.value;
        newVersion[key] = value;
      })
      if (newVersion.version !== Common.VERSION && !window.localStorage.getItem('_version_refreshed')) {
        // window.localStorage.setItem('_bdaas_version', newVersion.version);
        window.localStorage.setItem('_version_refreshed', 'yes')
        hardReload();
      }
      // window.localStorage.setItem('_bdaas_version', newVersion.version);
      // Remove this in the next version
      window.localStorage.removeItem('_bdaas_version');
    }
  }
  const getAnnouncement = () => {
    let paras = '?property=bdaas_banner&env=' + Common.ENV;
    axios.get(Common.ENDPOINT + Common.SETTINGS_GET + paras).then(response => {
      if (response.data.status === 1) {
        _getAnnouncement(response.data.result.bdaas_banner)
      }
    })
  }

  const getAnnouncementAndVersion = () => {
    let paras = '?property=bdaas_banner+bdaas&env=' + Common.ENV;
    axios.get(Common.ENDPOINT + Common.SETTINGS_GET + paras).then(response => {
      if (response.data.status === 1) {
        _getAnnouncement(response.data.result.bdaas_banner);
        _getVersion(response.data.result.bdaas);
      }
    })
  }

  const hideHandler = () => {
    setShow(false);
    window.localStorage.setItem('_show_announcement', 'false')
  }

  const showHandler = () => {
    setShow(true);
    window.localStorage.setItem('_show_announcement', 'true');
  }

  useEffect(() => {
    getAnnouncementAndVersion();
    if (window.localStorage.getItem('_show_announcement') === 'false') {
      setShow(false);
    } else setShow(true);
    setReloadTrigger(true);
  }, [])

  useEffect(() => {
    if (reloadTrigger) {
      reloadInterval = setInterval(getAnnouncement, 300000);
      let timeout;
      window.onmousemove = () => {
        clearTimeout(timeout);
        setReloadTrigger(true);
        timeout = setTimeout(() => {
          clearInterval(reloadInterval);
          setReloadTrigger(false);
        }, 600000)
      }
    } else {
      clearInterval(reloadInterval);
    }
    return () => {
      clearInterval(reloadInterval);
    }
  }, [reloadTrigger])

  const closeBannerIcon = closable === '1' ? <i className="fas fa-times" onClick={hideHandler} title="close announcement"></i> : null;
  const annoncementBanner = show ? <div className={classes.TopBanner} style={{ backgroundColor: bgcolor }}><div className={classes.announcement}>
    {announcement}
    {closeBannerIcon}
  </div></div> : <div className={classes.brief}><span onClick={showHandler} title="open announcement" style={{ backgroundColor: '#eee', padding: '2px 5px 0 0', borderRadius: '2px' }}><span className={classes.icon}><i className="fas fa-bullhorn" ></i></span>Announcement</span></div>;

  if (announcement) {
    return annoncementBanner
  } else return null;
}

export default TopBanner;