import React, { useState, useEffect, useContext } from 'react';
import Menu from './Menu/Menu';
import { Switch, Route, Redirect } from 'react-router-dom';
import Shop from './Shop';
import MyApps from './MyApps';
import AppPage from './AppPage';
import SavedApps from './SavedApps';

const Marketplace = () => {
    return (
        <div>
            <Menu />
            <Switch>
                <Route path='/gallery/shop' exact component={Shop}></Route>
                <Route path='/gallery/myapps' exact component={MyApps}></Route>
                <Route path='/gallery/saved' exact component={SavedApps}></Route>
                <Route path='/gallery/shop/:dappId' exact component={AppPage}></Route>
                <Redirect from="/gallery" to="/gallery/shop" />
            </Switch>
        </div>
    )
}

export default Marketplace;