import React, { useContext, useState, useEffect } from 'react';
import classes from './icon.module.scss';
import { AuthContext } from '../../../context/auth-context';
import axios from 'axios';
import * as Common from '../../../common';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Icon = (props) => {
    const authContext = useContext(AuthContext);

    const [showAction, setShowAction] = useState(false);
    const [newName, setNewName] = useState(props.name);
    const [editOn, setEditOn] = useState(false);

    const deleteIconHandler = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                id: props.id
            }
        }

        axios.post(Common.ENDPOINT + Common.PROFILE_ICON_DELETE, data).then(response => {
            if (response.data.status === '1') {
                props.delete(props.id);
            } else {
                alert(response.data.message);
            }
        })
    }

    const editIconHandler = () => {
        setEditOn(false);
        if (newName !== props.name) {
            let data = {
                credentials: authContext.credentials,
                data: {
                    id: props.id,
                    source: props.source,
                    filename: newName
                }
            }

            axios.post(Common.ENDPOINT + Common.PROFILE_ICON_UPDATE, data).then(response => {
                if (response.data.status === '1') {
                    props.edit(props.id, newName);
                } else {
                    alert(response.data.message);
                }
            })
        }
    }


    return (
        <div className={classes.icon}
            onMouseOver={() => { setShowAction(true) }} onMouseLeave={() => { setShowAction(false) }}
        >
            {
                props.editable ? <div>
                    <img src={props.src} className={classes.editable} />
                    <span>
                        <span className={showAction ? classes.show : classes.hide}><i className="far fa-trash-alt" onClick={deleteIconHandler}></i><i className="fas fa-pencil-alt" onClick={() => setEditOn(true)}></i></span>
                        <div className={editOn ? classes.nameEdit : classes.name} contentEditable={editOn} onBlur={editIconHandler} onInput={(e) => {
                            setNewName(e.target.innerText)
                        }} onKeyPress={(e) => {
                            if (e.which == 13 || e.keyCode == 13) { editIconHandler(); }
                          }}>{props.name}</div>
                    </span>
                </div> : <OverlayTrigger placement="bottom" delay={{ show: 200, hide: 0 }} overlay={
                    <Tooltip id="icon-tooltip"><span>{`I'm ${getName(props.name)}, Pick Me!`}</span></Tooltip>
                }><img src={props.src} className={classes.select} onClick={() => props.select(props.src)} /></OverlayTrigger>
            }
        </div>
    )
}

const getName = (filename) => {
    let name = filename.split('.')[0]
    return name.charAt(0).toUpperCase() + name.slice(1)
}

export default Icon;