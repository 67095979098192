import React, { useState, useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import '../../assets/css/custom-bootstrap.css';
import LogoImg from '../../assets/images/Logo.png'
import classes from './Authentication.module.scss';
import axios from 'axios';
import * as Common from '../../common';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { AuthContext } from '../../context/auth-context';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { checkAuth } from '../../commonFunc';
import googleLogo from '../../assets/images/logo_google.svg';
import facebookLogo from '../../assets/images/logo_facebook.png';
import githubLogo from '../../assets/images/logo_github.png';

const Authentication = () => {
    const authContext = useContext(AuthContext);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [showLogin, setShowLogin] = useState('login');
    const [validated, setValidated] = useState(false);
    const [login, setLogin] = useState([{ email: '', password: '' }]);
    const [signup, setSignup] = useState({ email: '', password: '' });
    const [showSecurityQuestion, setShowSecurityQuestion] = useState(false);
    const [signupQuestions1, setSignupQuestions1] = useState({ id: null, answer: '' });
    const [signupQuestions2, setSignupQuestions2] = useState({ id: null, answer: '' });
    const [signupQuestions3, setSignupQuestions3] = useState({ id: null, answer: '' });
    const [forgotPassword, setForgotPassword] = useState({ email: '', method: 'question' });
    const [isLoad, setIsLoading] = useState(false);
    const [notif, setNotif] = useState({ show: false, content: '' });
    const [securityQuestions, setSecurityQuestions] = useState();
    const [securityQuestions1, setSecurityQuestions1] = useState();
    const [securityQuestions2, setSecurityQuestions2] = useState();
    const [securityQuestions3, setSecurityQuestions3] = useState();
    const [mySecurityQuestions, setMySecurityQuestions] = useState();
    const [mySecurityAnswer1, setMySecurityAnswer1] = useState();
    const [mySecurityAnswer2, setMySecurityAnswer2] = useState();
    const [mySecurityAnswer3, setMySecurityAnswer3] = useState();
    const [blockSignUp, setBlockSignUp] = useState({ content: '', show: false });

    const getQuestionList = () => {
        axios.get(Common.USERPATH + Common.QUESTION_LIST).then(response => {
            if (response.data.status === '1') {
                setSecurityQuestions(response.data.questions)
                setSecurityQuestions1(response.data.questions)
                setSecurityQuestions2(response.data.questions)
                setSecurityQuestions3(response.data.questions)
            } else {
                alert(response.data.message)
            }
        })
    }

    const loginDataHandler = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setIsLoading(true);
            event.preventDefault();
            const data = {
                email: login.email,
                password: login.password,
                service_id: Common.SERVICE_ID
            }
            axios.post(Common.USERPATH + '/login', data)
                .then(response => {
                    const data = response.data;
                    if (data.status === '1') {
                        // allow page to reload if found a new version
                        window.localStorage.removeItem('_version_refreshed');
                        authContext.login(data.email, data.token, data.user_name, data.user_id, data.user_type, data.other_roles, data.icon_url);
                        setIsLoading(false)
                        window.history.back();
                    } else {
                        if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(data.message);
                        setIsLoading(false)
                    }
                })
                .catch(response => {
                    console.log(response);
                    setIsLoading(false)
                });
        }
        setValidated(true);
    }

    const signupDataHandler = (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            if (signup.password !== signup.password2) {
                setNotif({ show: true, content: 'The two passwords are not matching, please re-enter it.' })
                setSignup({ ...signup, password2: null });
                event.preventDefault();
            } else {
                setIsLoading(true);
                event.preventDefault();
                setValidated(false);
                executeRecaptcha("signup").then(token => {
                    let data;
                    if (showSecurityQuestion) {
                        data = {
                            email: signup.email,
                            password: signup.password,
                            answers: [
                                signupQuestions1,
                                signupQuestions2,
                                signupQuestions3
                            ],
                            service_id: Common.SERVICE_ID,
                            captcha_token: token,
                        }
                    }
                    else {
                        data = {
                            email: signup.email,
                            password: signup.password,
                            service_id: Common.SERVICE_ID,
                            captcha_token: token,
                        }
                    }
                    axios.post(Common.USERPATH + '/create', data)
                        .then(response => {
                            setIsLoading(false)
                            const data = response.data
                            if (data.status === '1') {
                                toggleFrameHandler('login');
                                setNotif({ show: true, content: 'Account created successfully!' })
                            } else {
                                alert(data.message);
                            }
                        })
                        .catch(response => {
                            setIsLoading(false)
                            console.log(response);
                        });
                })
            }
        }
    }

    const resetPwdDataHandler = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            setIsLoading(true);
            event.preventDefault();
            if (forgotPassword.method === 'email') {
                const data = {
                    email: forgotPassword.email,
                    service_id: Common.SERVICE_ID
                }
                axios.post(Common.USERPATH + '/reset', data)
                    .then(response => {
                        setIsLoading(false);
                        const data = response.data
                        if (data.status === '1') {
                            toggleFrameHandler('login');
                            setNotif({ show: true, content: 'Email sent! Check you inbox (or spam) to get your new password. Please update the password for your convenience.' })
                            setForgotPassword({ email: '', method: 'question' });
                        } else {
                            alert(data.message);
                        }
                    })
                    .catch(response => {
                        console.log(response);
                        setIsLoading(false);
                    });
            } else {
                setIsLoading(true);
                // setValidated(true);
                event.preventDefault();
                // call API to get user's security questions
                axios.get(Common.USERPATH + Common.USER_QUESTION_LIST + '?email=' + forgotPassword.email).then(response => {
                    setIsLoading(false);
                    if (response.data.status === '1') {
                        if (response.data.questions === null || response.data.questions.length !== 3) {
                            setNotif({ show: true, content: "You haven't set the security questions, please try the other way to reset your password." })
                        } else {
                            setMySecurityQuestions(response.data.questions);
                            toggleFrameHandler('resetPasswordViaQuestion');
                        }
                    } else {
                        if (response.data.message === 'Could not list questions.') {
                            setNotif({ show: true, content: "You haven't set the security questions, please try the other way to reset your password." })
                        }
                        else alert(response.data.message);
                    }
                })
            }
        }
    }

    const resetPwdViaQuestionHandler = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            event.preventDefault();
            if (forgotPassword.password !== forgotPassword.password2) {
                setNotif({ show: true, content: 'The two passwords are not matching, please re-enter it.' })
                setForgotPassword({ ...forgotPassword, password2: null });
                event.preventDefault();
            } else {
                setIsLoading(true);
                let data = {
                    credentials: {
                        email: forgotPassword.email,
                        service_id: Common.SERVICE_ID,

                    },
                    data: {
                        answers: [
                            {
                                id: mySecurityQuestions[0].id,
                                answer: mySecurityAnswer1
                            },
                            {
                                id: mySecurityQuestions[1].id,
                                answer: mySecurityAnswer2
                            },
                            {
                                id: mySecurityQuestions[2].id,
                                answer: mySecurityAnswer3
                            }
                        ],
                        password: forgotPassword.password
                    }
                }
                axios.post(Common.USERPATH + Common.RESET_QUESTION_VERIFY, data).then(response => {
                    setIsLoading(false);
                    if (response.data.status === '1') {
                        setNotif({ show: true, content: 'Password reset successfully!' })
                        toggleFrameHandler('login');
                        setForgotPassword({ email: '', password: '' });
                        setMySecurityAnswer1();
                        setMySecurityAnswer2();
                        setMySecurityAnswer3();
                    } else {
                        if (response.data.message === 'Incorrect answers.') {
                            setNotif({ show: true, content: 'Security Answers are not correct, please try again.' })
                        } else alert(response.data.message);
                    }
                })
            }
        }
    }

    const toggleFrameHandler = (frame) => {
        setShowLogin(frame);
        setValidated(false);
        if (frame === 'signup') {
            // call API to get if the block sign up is on
            let paras = '?property=bdaas_signup+bdaas_maintenance&env=' + Common.ENV;
            axios.get(Common.ENDPOINT + Common.SETTINGS_GET + paras).then(response => {
                if (response.data.status === 1) {
                    let ob = {};
                    if (response.data.result.bdaas_signup && response.data.result.bdaas_signup.length > 0) {
                        response.data.result.bdaas_signup.map(obj => {
                            let key = obj.key;
                            let value = obj.value;
                            ob[key] = value;
                        })
                        setBlockSignUp({ content: ob.message, show: true });
                    }
                    if (!response.data.result.bdaas_maintenance) {
                        // get Security Question list
                        getQuestionList();
                    }
                }
            })

        }
    }

    const filterSelectedQuestion = () => {
        if (securityQuestions) {
            let newList = []
            securityQuestions.map(question => {
                if (question.id !== signupQuestions2.id && question.id != signupQuestions3.id) newList.push(question);
            })
            setSecurityQuestions1(newList);
            newList = []
            securityQuestions.map(question => {
                if (question.id !== signupQuestions1.id && question.id != signupQuestions3.id) newList.push(question);
            })
            setSecurityQuestions2(newList);
            newList = []
            securityQuestions.map(question => {
                if (question.id !== signupQuestions1.id && question.id != signupQuestions2.id) newList.push(question);
            })
            setSecurityQuestions3(newList);
        }
    }

    useEffect(() => {
        filterSelectedQuestion();
    }, [signupQuestions1, signupQuestions2, signupQuestions3])

    let notification = <Alert style={{ textAlign: 'center' }} show={notif.show} variant="info" onClose={() => {
        setNotif({ show: false, content: '' });
    }} dismissible>{notif.content}</Alert>;

    let thirdPartyLogin = <div className={classes.spaceBetween}>
        <div className={classes.thirdPartyLogo} title="google login" onClick={() => window.location.href=`${Common.USERPATH}/oauth/login/google?service_id=${Common.SERVICE_ID}`}><img src={googleLogo} /></div>
        <div className={classes.thirdPartyLogo} title="facebook login" style={{ backgroundColor: '#1878f2' }} onClick={() => window.location.href=`${Common.USERPATH}/oauth/login/facebook?service_id=${Common.SERVICE_ID}`}><img src={facebookLogo} /></div>
        <div className={classes.thirdPartyLogo} title="github login" style={{ backgroundColor: '#181515' }} onClick={() => window.location.href=`${Common.USERPATH}/oauth/login/github?service_id=${Common.SERVICE_ID}`}><img src={githubLogo} /></div>
    </div>

    let frame = null;

    if (showLogin === 'login') {
        let button = 'Submit';
        if (isLoad) button = <span>Loading...
        <Spinner as='span' animation='border' role='status' aria-hidden='true' /></span>

        frame = <div className={classes.form}>
            <h2> Log In</h2>
            <Form.Text className={classes.textMuted}>New to BlockTEST? <span className={classes.link} style={{ fontWeight: '600' }} onClick={() => toggleFrameHandler('signup')}> Sign Up Now!</span></Form.Text>
            <Form.Text className={classes.textMuted} style={{margin: '0.8rem 0'}}>Log in with your social network</Form.Text>
            {thirdPartyLogin}
            <p className={classes.separator}><span>or</span></p>
            <Form noValidate
                validated={validated}
                onSubmit={e => loginDataHandler(e)}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Control type="email" placeholder="Email" maxLength="50" value={login.email || ''} onChange={(event) => setLogin({ ...login, email: event.target.value })} required />
                    <Form.Control.Feedback type="invalid" >
                        Please provide a valid email.
                        </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicPassword" >
                    <Form.Control type="password" placeholder="Password" maxLength="20" value={login.password || ''} onChange={(event) => setLogin({ ...login, password: event.target.value })} required />
                    <Form.Control.Feedback type="invalid" >
                        Password cannot be empty.
                        </Form.Control.Feedback>
                </Form.Group>
                <Form.Text className={classes.textMuted}>
                    <span className={classes.link} onClick={() => toggleFrameHandler('forgotPassword')}>Forgot Password?</span>
                </Form.Text>
                <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                    <Button className={classes.submitBtn} type="submit" >{button}</Button>
                    <span className={classes.terms}>By signing in, you agree with BlockTEST's <br></br><a href="https://blocktest.com/terms-of-service/" target="_blank">Terms of Service</a> & <a href="https://blocktest.com/privacy-policy/" target="_blank">Privacy Policy</a></span>
                    <hr></hr>
                    <a href="https://blocktest.com" target="_blank" rel="noopener noreferrer"><img src={LogoImg} className={classes.logoAuth} alt="logo" /></a>
                </div>
            </Form>
        </div>
    }
    if (showLogin === 'signup') {
        let button = 'Submit';
        if (isLoad) button = <span>Loading...
        <Spinner as='span' animation='border' role='status' aria-hidden='true' /></span>
        let sqSession = null;
        if (securityQuestions1 && securityQuestions2 && securityQuestions3) {
            let securityQuestionOptions1 = securityQuestions1.map(question => {
                return <option key={question.id} value={question.id}>{question.question}</option>
            })
            let securityQuestionOptions2 = securityQuestions2.map(question => {
                return <option key={question.id} value={question.id}>{question.question}</option>
            })
            let securityQuestionOptions3 = securityQuestions3.map(question => {
                return <option key={question.id} value={question.id}>{question.question}</option>
            })
            sqSession = showSecurityQuestion ? <div>
                <div className={classes.spaceBetween}>
                    <h3>Security Questions</h3>
                    <span className={classes.link} onClick={() => { setShowSecurityQuestion(false); }}><b>skip</b></span>
                </div>
                <div style={{ fontSize: '1.2rem', marginBottom: '1rem' }}>In case you forget your password </div>
                <Form.Group>
                    <Form.Control as="select" onChange={(event) => {
                        setSignupQuestions1({ ...signupQuestions1, id: Number(event.target.value) });
                        setValidated(false);
                    }} required>
                        <option disabled selected value="">Please select 1st security question</option>
                        {securityQuestionOptions1}
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="formControlSelect1-answer">
                    <Form.Control
                        required
                        type="password"
                        maxLength="50"
                        placeholder="Answer"
                        onChange={(event) => {
                            setSignupQuestions1({ ...signupQuestions1, answer: event.target.value })
                        }}
                    />
                    <Form.Control.Feedback type="invalid">Answer of 1st security question cannot be empty.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formControlSelect2">
                    {/* <Form.Label>Example select</Form.Label> */}
                    <Form.Control as="select" onChange={(event) => {
                        setSignupQuestions2({ ...signupQuestions2, id: Number(event.target.value) });
                        setValidated(false);
                    }} required>
                        <option disabled selected value="">Please select 2nd security question</option>
                        {securityQuestionOptions2}
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="formControlSelect2-answer">
                    <Form.Control
                        required
                        type="password"
                        maxLength="50"
                        placeholder="Answer"
                        onChange={(event) => {
                            setSignupQuestions2({ ...signupQuestions2, answer: event.target.value })
                        }}
                    />
                    <Form.Control.Feedback type="invalid">Answer of 2nd security question cannot be empty.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formControlSelect3">
                    {/* <Form.Label>Example select</Form.Label> */}
                    <Form.Control as="select" onChange={(event) => {
                        setSignupQuestions3({ ...signupQuestions3, id: Number(event.target.value) });
                        setValidated(false);
                    }} required>
                        <option disabled selected value="">Please select 3rd security question</option>
                        {securityQuestionOptions3}
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="formControlSelect3-answer">
                    <Form.Control
                        required
                        type="password"
                        maxLength="50"
                        placeholder="Answer"
                        onChange={(event) => {
                            setSignupQuestions3({ ...signupQuestions3, answer: event.target.value })
                        }}
                    />
                    <Form.Control.Feedback type="invalid">Answer of 3rd security question cannot be empty.</Form.Control.Feedback>
                </Form.Group></div> : <div style={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'center' }}>To protect your account, we recommend you to set <span className={classes.link} onClick={() => {
                    setShowSecurityQuestion(true);
                }}>security questions</span></div>
        }
        let blockSignUpNotif = blockSignUp.show ? <Alert show={true} variant="info" onClose={() => {
            setBlockSignUp({ show: false, content: '' });
        }}>{blockSignUp.content}</Alert> : null;

        let form = blockSignUp.show ? null : <div>
            <Form.Group controlId="SignUpEmail">
                <Form.Control type="email" placeholder="Enter email" maxLength="50" value={signup.email || ''} onChange={(event) => setSignup({ ...signup, email: event.target.value })} required />
                <Form.Control.Feedback type="invalid">
                    Please provide a valid email.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="SignUpPassword" >
                <Form.Control type="password" placeholder="Password" maxLength="20" value={signup.password || ''}
                    onChange={(event) => {
                        setSignup({ ...signup, password: event.target.value });
                        setValidated(false);
                    }}
                    required />
                <Form.Control.Feedback type="invalid" >
                    Password cannot be empty.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="SignUpPassword2" >
                <Form.Control type="password" placeholder="Re-enter Password" maxLength="20" value={signup.password2 || ''}
                    onChange={(event) => {
                        setSignup({ ...signup, password2: event.target.value });
                        setValidated(false);
                    }}
                    required />
                <Form.Control.Feedback type="invalid" >
                    Please re-enter password.
                </Form.Control.Feedback>
            </Form.Group>
            <hr style={{ margin: '2rem 0' }}></hr>
            {sqSession}
            <div style={{ textAlign: 'center' }}>
                <Button className={classes.submitBtn} type="submit" >{button}</Button>
            </div>
        </div>

        frame = <div className={classes.form}>
            <h2> Sign Up</h2>
            {blockSignUpNotif}
            <br></br>
            <Form noValidate
                validated={validated}
                onSubmit={e => signupDataHandler(e)}>
                {form}
                <div style={{ textAlign: 'center' }}>
                    <span className={classes.terms}>By signing up, you agree with BlockTEST's <br></br><a href="https://blocktest.com/terms-of-service/" target="_blank">Terms of Service</a> & <a href="https://blocktest.com/privacy-policy/" target="_blank">Privacy Policy</a></span>
                    <hr></hr>
                    <Form.Text className={classes.textMuted}>Already have an account? <span className={classes.link} onClick={() => toggleFrameHandler('login')}>Login</span></Form.Text>
                    <a href="https://blocktest.com" target="_blank" rel="noopener noreferrer"><img src={LogoImg} className={classes.logoAuth} alt="logo" /></a>
                </div>
            </Form>
        </div >
    }

    if (showLogin === 'forgotPassword') {
        let button = 'Submit';
        if (isLoad) button = <span>Loading...
        <Spinner as='span' animation='border' role='status' aria-hidden='true' /></span>

        frame = <div className={classes.form}>
            <h2> Reset Password</h2>
            <br></br>
            {/* <div className={classes.textMuted}>
                We will reset your password and send a temporary password to your email, you can change it after logging in
                </div> */}
            <Form noValidate
                validated={validated}
                onSubmit={e => resetPwdDataHandler(e)}>
                <Form.Group controlId="forgotEmail">
                    <Form.Control type="email" placeholder="Enter email" maxLength="50" value={forgotPassword.email || ''} onChange={(event) => setForgotPassword({ ...forgotPassword, email: event.target.value })} required />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid email.
                        </Form.Control.Feedback>
                </Form.Group>
                <h4>Reset password via</h4>
                <Form.Check
                    type="radio"
                    id="reset-question"
                    label="answering security questions (recommended)"
                    name="resetType"
                    checked={forgotPassword.method === 'question'}
                    onChange={() => setForgotPassword({ ...forgotPassword, method: 'question' })}
                />
                <Form.Check
                    type="radio"
                    id="reset-email"
                    label="receiving a random password by email"
                    name="resetType"
                    checked={forgotPassword.method === 'email'}
                    onChange={() => setForgotPassword({ ...forgotPassword, method: 'email' })}
                />
                <br></br>
                <div style={{ textAlign: 'center' }}>
                    <Button className={classes.submitBtn} type="submit">{button}</Button>
                    <Form.Text className={classes.textMuted}> Already a member? <span className={classes.link} onClick={() => toggleFrameHandler('login')}> Login </span></Form.Text>
                    <a href="https://blocktest.com" target="_blank" rel="noopener noreferrer"><img src={LogoImg} className={classes.logoAuth} alt="logo" /></a>
                </div>
            </Form>
        </div>
    }

    if (showLogin === 'resetPasswordViaQuestion') {
        let button = 'Submit';
        if (isLoad) button = <span>Loading...
        <Spinner as='span' animation='border' role='status' aria-hidden='true' /></span>

        frame = <div className={classes.form}>
            <h2>Reset Password</h2>
            <br></br>
            <Form noValidate
                validated={validated}
                onSubmit={e => resetPwdViaQuestionHandler(e)}>
                <div className={classes.question}>{mySecurityQuestions[0].question}</div>
                <Form.Group controlId="question1-answer">
                    <Form.Control
                        required
                        type="text"
                        placeholder="Answer"
                        onChange={(event) => { setMySecurityAnswer1(event.target.value); }}
                    />
                    <Form.Control.Feedback type="invalid">Answer of 1st security question cannot be empty.</Form.Control.Feedback>
                </Form.Group>
                <div className={classes.question}>{mySecurityQuestions[1].question}</div>
                <Form.Group controlId="question2-answer">
                    <Form.Control
                        required
                        type="text"
                        placeholder="Answer"
                        onChange={(event) => { setMySecurityAnswer2(event.target.value); }}
                    />
                    <Form.Control.Feedback type="invalid">Answer of 2nd security question cannot be empty.</Form.Control.Feedback>
                </Form.Group>
                <div className={classes.question}>{mySecurityQuestions[2].question}</div>
                <Form.Group controlId="question3-answer">
                    <Form.Control
                        required
                        type="text"
                        placeholder="Answer"
                        onChange={(event) => { setMySecurityAnswer3(event.target.value); }}
                    />
                    <Form.Control.Feedback type="invalid">Answer of 3rd security question cannot be empty.</Form.Control.Feedback>
                </Form.Group>
                <hr></hr>
                <h4>New Password</h4>
                <Form.Group controlId="resetPassword1" >
                    <Form.Control type="password" placeholder="New password" maxLength="20" value={forgotPassword.password || ''} onChange={(event) => setForgotPassword({ ...forgotPassword, password: event.target.value })} required />
                    <Form.Control.Feedback type="invalid" >
                        Password cannot be empty.
                        </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="resetPassword2" >
                    <Form.Control type="password" placeholder="Re-enter the new password" maxLength="20" value={forgotPassword.password2 || ''} onChange={(event) => setForgotPassword({ ...forgotPassword, password2: event.target.value })} required />
                    <Form.Control.Feedback type="invalid" >
                        Please re-enter password.
                        </Form.Control.Feedback>
                </Form.Group>
                <div style={{ textAlign: 'center' }}>
                    <Button className={classes.submitBtn} type="submit">{button}</Button>
                    <Form.Text className={classes.textMuted}> Already a member? <span className={classes.link} onClick={() => toggleFrameHandler('login')}> Login </span></Form.Text>
                    <a href="https://blocktest.com" target="_blank" rel="noopener noreferrer"><img src={LogoImg} className={classes.logoAuth} alt="logo" /></a>
                </div>
            </Form>
        </div>
    }

    return (
        <div className={classes.Authentication}>
            {notification}
            {frame}
        </div>
    );

}

export default Authentication;