import React, { useState } from 'react';

export const MessageContext = React.createContext({});

const MessageContextProvider = props => {
    const [showMessageNotif, setShowMessageNotif] = useState(false);
    const [unreadMsgCount, setUnreadMsgCount] = useState();

    const setUnreadMsgCountHandler = (count) => {
        if (count < 0) return;
        setUnreadMsgCount(count);
    }

    return (
        <MessageContext.Provider
            value={{
                showMessageNotif: showMessageNotif,
                setShowMessageNotif: setShowMessageNotif,
                unreadMsgCount: unreadMsgCount,
                setUnreadMsgCount: setUnreadMsgCountHandler,
            }}>
            {props.children}
        </MessageContext.Provider>
    )
}

export default MessageContextProvider;

