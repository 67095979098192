import React, { useContext, useState, useEffect } from 'react';
import classes from './Message.module.scss';

const MessageItem = (props) => {
    let message = props.data;
    let i = props.index;
    const [showContent, setShowContent] = useState(false);
	const localtime = new Date(message.message_timestamp).toLocaleString();
    if (i === 0) {
        return <div className={classes.content}>{message.message_content}{props.manage}</div>
    } else {
        let content = showContent ? <div className={classes.content}>
            {message.message_content}
        </div> : null;

        return <div className={classes.messageChainItem} onClick={() => setShowContent(!showContent)}>
            <div className={classes.spaceBetween}>
                <span>{message.message_from}</span>
                <span>{localtime}</span>
            </div>
            <div style={{fontSize:'14px'}}>{message.message_title}</div>
            {content}
        </div>
    }
}

export default MessageItem