import React, { useState, useEffect, useContext } from 'react';
import { pure } from 'recompose';
import classes from './Editor.module.scss';
import { IDEUrl } from '../../../common';
import net from '../../../assets/images/net.gif';
import { AuthContext } from '../../../context/auth-context';
import IframeComm from 'react-iframe-comm';
import { Link }from 'react-router-dom';

const Editor = pure(() => {
  const authContext = useContext(AuthContext);

  const [showLoginReminder, setShowLoginReminder] = useState();
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
      setShowLoginReminder(false);
    } else { setShowLoginReminder(true); }
  }, [authContext])

  const Loading = showLoading ?
    <div className={classes.loading}>
      <img src={net} alt="loading gif" />
      <p>Loading...</p>
    </div>
    : null;

  const attributes = {
    src: IDEUrl,
    width: "100%",
    height: "100%",
    title: "ide"
  }

  const onReceiveMessage = (message) => {
    if (message.data === 'close') {
      authContext.logout();
    } else if (message.data.type === 'maintenance') {
      authContext.maintenance(message.data.message);
    } else if (message.data.type === 'application console redirect') {
      window.sessionStorage.setItem('_console_selected_info', JSON.stringify(message.data.message));
      // window.location.href='/console'
      // var $body = document.getElementsByClassName('App')[0];
      // var a = document.createElement('a');  
      // a.href = "/console";  
      // $body.appendChild(a);
      // a.click();
      // $body.removeChild(a);

      document.getElementById("console-hidden-link").click();
    }
  }

  const consoleRedirect = <Link to="/console" id="console-hidden-link"></Link>

  if (showLoginReminder) {
    return (
      <div className={classes.preEditor}>
        <h2 className={classes.pageTitle}>Script Editor</h2>
        <p className={classes.pageDesc}>Create, debug, and deploy smart contracts, all in one place.</p>
        <p>Please <Link to="/login">login</Link> to get access to <i className="far fa-edit"></i>Editor</p>
      </div>
    )
  } else {
    return (
      <div className={classes.editor}>
        {Loading}
        {consoleRedirect}
        <IframeComm
            attributes={attributes}
            handleReady={() => setShowLoading(false)}
            handleReceiveMessage={onReceiveMessage}
        />      
      </div>
    )
  }
});

export default Editor;