import React from 'react';
import './sass/App.scss';
import './sass/pagination.scss';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Menu from './components/Menu/Menu';
import Chain from './components/MainView/Chain/Chain';
import Editor from './components/MainView/Editor/Editor';
import Authentication from './components/Authentication/Authentication';
import { CookiesProvider } from 'react-cookie';
import Transparent from './components/MainView/Transparent/Transparent';
import TopBanner from './components/TopBanner/TopBanner';
import { ToastProvider } from 'react-toast-notifications';
import Home from './components/MainView/Home/Home';
import loginSuccess from './components/Authentication/ThirdPartyLogin';
import Console from './components/MainView/Console/Console';
import Marketplace from './components/MainView/Marketplace/Marketplace';
import Blog from './components/MainView/Blog/Blog';
import User from './components/MainView/User/User';
import TopMenu from './components/Menu/TopMenu/TopMenu';

function App() {
  return (
    <CookiesProvider>
      <BrowserRouter>
        <ToastProvider>
          <div className="App">
            <Menu />
            <TopBanner />
            <TopMenu />
            <Editor />
            <Switch>
              <Route path="/chain" component={Chain} />
              <Route path="/editor" exact component={Transparent} />
              <Route path="/home" exact component={Home} />
              <Route path="/login" exact component={Authentication} />
              {/* <Route path="/payment" exact component={Payment} /> */}
              <Route path="/login_success" exact component={loginSuccess} />
              <Route path="/console" exact component={Console} />
              <Route path="/gallery" component={Marketplace} />
              <Route path="/user" component={User} />
              <Route path="/blog" component={Blog} />
              <Redirect from="/" to="/home" />
            </Switch>
          </div>
        </ToastProvider>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
