import React, { useContext, useState, useEffect } from 'react';
import Profile from './Profile/Profile';
import Messages from './Messages/Messages';
import Account from './Account/Account';
import { Switch, Route, Redirect } from 'react-router-dom';
import Menu from './Menu';

const User = () => {
    return (
        <div>
            <Menu />
            <Switch>
                <Route path="/user/profile" component={Profile} />
                <Route path="/user/messages" component={Messages} />
                <Route path="/user/account" component={Account} />
                <Redirect from="/user" to="/user/profile" />
            </Switch>
        </div>
    )
}

export default User;