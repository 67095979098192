import React, { useState, useEffect, useContext } from 'react';
import classes from './Authentication.module.scss';
import Spinner from 'react-bootstrap/Spinner';
import { AuthContext } from '../../context/auth-context';

const Login = () => {
    const authContext = useContext(AuthContext);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        let status = urlParams.get('status');
        if (status === '1') {
            authContext.login(urlParams.get('email'), urlParams.get('token'), urlParams.get('user_name'), urlParams.get('user_id'), 'user', []);
            window.location.href = "/";
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div className={classes.Authentication}>
            <div className={classes.thirdPartyLogin}>Loading...<Spinner animation="border" /></div>
        </div>
    )
}

export default Login;