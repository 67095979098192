import React from 'react';
import classes from './Menu.module.scss';
import { NavLink } from 'react-router-dom';

const Menu = () => {
    return (
        <div className={classes.menu}>
            <div className={classes.title}>DApp Gallery</div>
            <div className={classes.icons}>
                <NavLink to="/gallery/shop" activeClassName={classes.Active}>
                    <div className={classes.icon}>Shop</div>
                </NavLink>
            </div>
            <div className={classes.icons}>
                <NavLink to="/gallery/myapps" activeClassName={classes.Active}>
                    <div className={classes.icon}>My DApps</div>
                </NavLink>
            </div>
            <div className={classes.icons}>
                <NavLink to="/gallery/saved" activeClassName={classes.Active}>
                    <div className={classes.icon}>Saved DApps</div>
                </NavLink>
            </div>
        </div>
    )
}

export default Menu;