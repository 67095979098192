import React, { useContext, useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import BlogPage from './BlogPage';

const Blog = () => {
    return (
        <div>
            <Switch>
                <Route path='/blog/:id' exact component={BlogPage}></Route>
                <Redirect from="/blog" to="/home" />
            </Switch>
        </div>
    )
}

export default Blog;