import React, { useState, useEffect, useContext } from 'react';
import classes from './Monitor.module.scss';
import { AuthContext } from '../../../context/auth-context';
import { MonitorContext } from '../../../context/monitor-context';
import FormControl from 'react-bootstrap/FormControl';
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as Common from '../../../common';
import { checkAuth } from '../../../commonFunc';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const Token = () => {
    const authContext = useContext(AuthContext);
    const monitorContext = useContext(MonitorContext);
    const [tokensCount, setTokensCount] = useState();
    const [erc20tokensCount, setErc20TokensCount] = useState();
    const [erc721tokensCount, setErc721TokensCount] = useState();
    const [currPage, setCurrPage] = useState(1);
    const [pages, setPages] = useState();
    const [perPage, setPerPage] = useState(10);
    const [tokenData, setTokenData] = useState();
    const [erc20Data, setErc20Data] = useState();
    const [erc721Data, setErc721Data] = useState();
    const [selectedTokensData, setSelectedTokensData] = useState();
    const [selectedTokenType, setSelectedTokenType] = useState('Tokens')
    const tokenBtnType = ['Tokens', 'ERC20', 'ERC721']
    const pagination_options = [10, 25, 50];
    const [refreshing, setRefreshing] = useState(false);
    let firstLoading = true
    let tokenBtns = tokenBtnType.map(type => {
        let _classes = type === selectedTokenType ? [classes.mainBtn, classes.selected].join(' ') : classes.mainBtn
        let tokenCount = 0;
        if (type === 'Tokens') tokenCount = tokensCount;
        else if (type === 'ERC20') tokenCount = erc20tokensCount;
        else if (type === 'ERC721') tokenCount = erc721tokensCount;
        return <div className={_classes} key={type} style={{ marginRight: '1rem', padding: '0.3rem 2rem' }} onClick={() => setSelectedTokenType(type)}><b>{tokenCount}</b> {type}</div>
    })

    useEffect(() => {
        if (monitorContext.chain) {
            if (firstLoading) {
                getTokenData();
                getTokenData('ERC20');
                getTokenData('ERC721');
                firstLoading = false;
            }
            else if (selectedTokenType === 'Tokens') getTokenData();
            else if (selectedTokenType === 'ERC20') getTokenData('ERC20');
            else if (selectedTokenType === 'ERC721') getTokenData('ERC721');
        }
    }, [monitorContext, currPage, perPage, selectedTokenType])


    useEffect(() => {
        if (selectedTokenType === 'Tokens') {
            setSelectedTokensData(tokenData);
            setPages(Math.ceil(parseFloat(tokensCount) / perPage))
        }
        else if (selectedTokenType === 'ERC20') {
            setSelectedTokensData(erc20Data);
            setPages(Math.ceil(parseFloat(erc20tokensCount) / perPage))
        }
        else if (selectedTokenType === 'ERC721') {
            setSelectedTokensData(erc721Data);
            setPages(Math.ceil(parseFloat(erc721tokensCount) / perPage))
        }
    }, [tokenData, erc20Data, erc721Data])

    useEffect(() => {
        setCurrPage(1);
    }, [selectedTokenType])

    const getTokenData = (type) => {
        setRefreshing(true);

        let data = {
            credentials: authContext.credentials,
            data: {
                page: currPage,
                per_page: perPage,
                chain_id: monitorContext.chain.chain_id,
                type: type
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_GET_TOKENS, data).then(response => {
            setRefreshing(false);

            if (response.data.status === '1') {
                if (!type) {
                    setTokenData(response.data.items);
                    setTokensCount(response.data.total)
                }
                else if (type === 'ERC20') {
                    setErc20Data(response.data.items);
                    setErc20TokensCount(response.data.total)
                }
                else if (type === 'ERC721') {
                    setErc721Data(response.data.items);
                    setErc721TokensCount(response.data.total)
                }
                setPages(Math.ceil(parseFloat(response.data.total) / perPage))
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    let tableRows = selectedTokensData ? selectedTokensData.length > 0 ? selectedTokensData.map((token, i) => {
        return <tr key={i}>
            <td><b>{token.name}</b></td>
            <td>{trimHash(token.address)}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(token.address) }}></i></td>
            <td><b>{token.symbol}</b></td>
            <td><b>{token.type}</b></td>
        </tr>
    }) : <tr><td colSpan="4">No Token Data available</td></tr> : <tr><td colSpan="4">Fetching Data...</td></tr>;

    const table = <Table hover className={classes.table}>
        <thead>
            <tr style={{ color: 'grey' }}>
                <th>Name</th>
                <th>Address</th>
                <th>Symbol</th>
                <th>Type</th>
            </tr>
        </thead>
        <tbody>
            {tableRows}
        </tbody>
    </Table>;

    const paginationUpdate = (perPage) => {
        setPerPage(perPage);
        setCurrPage(1);
        if (selectedTokenType === 'Tokens') {
            setPages(Math.ceil(parseFloat(tokensCount) / perPage))
        }
        else if (selectedTokenType === 'ERC20') {
            setPages(Math.ceil(parseFloat(erc20tokensCount) / perPage))
        }
        else if (selectedTokenType === 'ERC721') {
            setPages(Math.ceil(parseFloat(erc721tokensCount) / perPage))
        }
    }

    const paginationOptions = pagination_options.map((number, index) => {
        let classes = "option"
        if (number === perPage) classes = "option selected";
        return <span className={classes} key={index} onClick={() => { paginationUpdate(number) }}>{number}</span>
    })

    const tablePagination = pages > 0 ? <ReactPaginate
        forcePage={currPage - 1}
        previousLabel={<i className="fas fa-caret-left"></i>}
        nextLabel={<i className="fas fa-caret-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h"></i>}
        breakClassName={'break-me'}
        pageCount={pages}
        onPageChange={(page) => {
            setCurrPage(page.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
    /> : null

    let tableSession = <div>
        <div className={classes.spaceBetween}>
            <div className={classes.thirdTitle}></div>
            <div>
                <span className="pagination_menu" style={{ fontSize: '1.2rem', display: 'inline-block' }}>show {paginationOptions} items</span>{' '}
                {
                    !refreshing ? <span className={classes.refreshBtn} title="refresh data"><i className="fas fa-sync-alt" onClick={() => {
                        if (selectedTokenType === 'Tokens') getTokenData();
                        else if (selectedTokenType === 'ERC20') getTokenData('ERC20');
                        else if (selectedTokenType === 'ERC721') getTokenData('ERC721');
                    }}></i></span> :
                        <Spinner animation="border" variant="secondary" />
                }
            </div>
        </div>
        {table}
        {tablePagination}
    </div>

    return (
        <div className={classes.monitor}>
            <div className={classes.subTitle}>Token<OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Popover id="contracts-popover">
                    <Popover.Content>
                        <p>Cryptographic tokens represent programmable assets or access rights, managed by a smart contract and an underlying distributed ledger. </p>
                    </Popover.Content></Popover>}
            ><sup><i class="fas fa-info-circle"></i></sup></OverlayTrigger></div>
            {/* {chainDesc} */}
            {
                monitorContext.chain ? <div>
                    <div style={{ marginTop: '2rem', marginBottom: '1rem' }}>{tokenBtns}</div>
                    {tableSession}
                </div> : <div>Click <Link to="/chain">here</Link> to select a chain first</div>
            }
        </div>
    )
}

function trimHash(hash) {
    return hash.substring(0, 6) + '...' + hash.substring(hash.length - 6, hash.length)
}

function copyToClipboard(secretInfo) {
    var $body = document.getElementsByTagName('body')[0];
    var $tempInput = document.createElement('INPUT');
    $body.appendChild($tempInput);
    $tempInput.setAttribute('value', secretInfo)
    $tempInput.select();
    document.execCommand('copy');
    $body.removeChild($tempInput);
}

export default Token;