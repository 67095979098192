import React, { useState, useEffect, useContext } from 'react';
import classes from './TopMenu.module.scss';
import telegramLogo from '../../../assets/images/logo_telegram.png';
import logo from '../../../assets/images/Logo.png';
import { AuthContext } from '../../../context/auth-context';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Setting from '../Setting/Setting';
import Feedback from '../Feedback/Feedback';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const TopMenu = () => {
    const authContext = useContext(AuthContext);
    const [login, setLogin] = useState();
    const [cookie, setCookie] = useCookies(['username', 'email'])

    useEffect(() => {
        if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
            setLogin(true);
        } else { setLogin(false) }
    }, [authContext])

    const logoutHandler = () => {
        authContext.signout();
        window.location.href = '/login';
    }
    // let unreadMsgCount = authContext && authContext.unreadMsgCount !== 0 ? <sup title='You have unread messages' style={{ fontFamily: '"Open Sans", sans-serif' }}><Badge variant="danger">{authContext.unreadMsgCount}</Badge></sup> : null;

    const telegram = <span className={classes.telegram}><a href="https://t.me/BlockTestOfficial" target="_blank">Join us on<img src={telegramLogo} height={20} /><b>Telegram</b></a></span>

    const userStatus = login ?
        <span className={classes.greeting}>
            <Link title="go to profile page" className={classes.link} to="/user"><Setting /><span className={classes.email}>{cookie.email}</span></Link>
            {' '}
            {/* <Link title="go to message center" className={classes.link} to="/user/messages"><i className="fas fa-bell">{unreadMsgCount}</i></Link> */}
            {' '}
            {telegram}
            <span className={classes.link}><Feedback /></span>
            <span className={classes.link} onClick={logoutHandler}><OverlayTrigger placement="bottom"
                overlay={
                    <Tooltip id="logout-tooltip"><div style={{ fontSize: '1.2rem', width: '60px' }}>Log Out</div></Tooltip>}>
                <i className="fas fa-sign-out-alt"></i>
            </OverlayTrigger></span>
        </span > :
<span className={classes.greeting}><a href="/login"><Setting /><b>Log In</b></a>{telegram}</span>

return (
    <div className={classes.topMenu}>
        <div className={classes.banner}>
            <a href="https://blocktest.com"><img src={logo} alt="blocktest-logo" /></a>{userStatus}
        </div>
    </div>
)
}

export default TopMenu;
