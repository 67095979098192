import React, { useState, useEffect, useContext } from 'react';
import classes from './AppPage.module.scss';
import ethereumLogo from '../../../assets/images/logo_ethereum.png';
import quorumLogo from '../../../assets/images/logo_quorum.png';
// import imageHolder from '../../../assets/images/photo-default.png';
import imageHolder from '../../../assets/images/blockchain.png';
import FuncRow from './FuncRow';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as Common from '../../../common';
import { AuthContext } from '../../../context/auth-context';
import { checkAuth } from '../../../commonFunc';
import { useParams } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Web3 from 'web3';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AppPage = () => {
    let { dappId } = useParams();
    const authContext = useContext(AuthContext);
    const [appInfo, setAppInfo] = useState();
    const [wallets, setWallets] = useState();
    const [isCreating, setIsCreating] = useState(false);
    const [showCreateAccount, setShowCreateAccount] = useState(false);
    const [showLoginReminder, setShowLoginReminder] = useState();
    const [accountNickName, setAccountNickName] = useState();
    const [isLoading, setIsLaoding] = useState(false);
    const [balance, setBalance] = useState();
    const [selectedWallet, setSelectedWallet] = useState();
    const [isFav, setIsFav] = useState();
    const [network, setNetwork] = useState(null);
    const [web3, setWeb3] = useState();
    const [invalidId, setInvalidId] = useState(false);
    const ethChains = new Map([[1, 'mainnet'], [3, 'ropsten'], [4, 'rinkeby'], [5, 'goerli'], [42, 'kovan']]);
    const [showFeedback, setShowFeedback] = useState(false);

    const [isCreator, setIsCreator] = useState(false);

    useEffect(() => {
        getAppInfo();
    }, [])

    useEffect(() => {
        if (network) {
            getBrowserWeb3();
        }
    }, [network])

    useEffect(() => {
        if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
            setShowLoginReminder(false);
            if (appInfo) {
                if (authContext.userid === appInfo.creator_id) setIsCreator(true);
                if (!network) getAccounts();
                getFavStatus();
            }
        } else { setShowLoginReminder(true); }
    }, [authContext, appInfo])

    useEffect(() => {
        if (appInfo && selectedWallet && appInfo.chain_type === 'ethereum' && !network) getBalance();
    }, [selectedWallet])

    const getBrowserWeb3 = () => {
        if (window.web3) {
            window.web3 = new Web3(Web3.givenProvider); // new Web3(window.web3.currentProvider);
            window.web3.eth.net.getId().then(id => {
                if (ethChains.get(id) !== network) {
                    alert(`Please switch to ${network} fisrt`)
                    return null
                } else {
                    window.ethereum.enable()
                    // console.log(window.web3)
                    setWeb3(window.web3)
                    return window.web3
                }
            });
        } else {
            alert('Please install metamask fisrt')
            return null
        }
    };

    useEffect(() => {
        if (web3) {
            let account = web3.eth.defaultAccount;
            web3.eth.handleRevert = true;
            if (!account) {
                web3.eth.getAccounts().then(accounts => {
                    if (accounts && accounts.length > 0) {
                        setSelectedWallet(accounts[0])
                    }
                })
            } else setSelectedWallet(account);
        } else setSelectedWallet(null);
    }, [web3])


    const getAppInfo = () => {
        let data
        if (isNaN(dappId)) {
            data = {
                data: {
                    url_id: dappId
                }
            }
        } else {
            data = {
                data: {
                    dapp_id: dappId
                }
            }
        }

        axios.post(Common.ENDPOINT + Common.DAPP_DETAIL, data).then(response => {
            if (response.data.status === '1') {
                let dapp = response.data.dapp;
                dapp.contract = response.data.contract;
                if (response.data.dapp.network_type !== '') setNetwork(response.data.dapp.network_type)
                setAppInfo(dapp);
            }
            else {
                // alert(response.data.message)
                setInvalidId(true);
            }
        })
    }

    const getAccounts = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                dapp_id: appInfo.id
            }
        }
        axios.post(Common.ENDPOINT + Common.DAPP_ACCOUNTS, data).then(response => {
            if (response.data.status === '1') {
                setWallets(response.data.accounts)
                if (response.data.accounts.length > 0) {
                    setSelectedWallet(response.data.accounts[0]);
                }
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getFavStatus = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                dapp_id: appInfo.id
            }
        }
        axios.post(Common.ENDPOINT + Common.DAPP_FAV_STATUS, data).then(response => {
            if (response.data.status === '1') {
                setIsFav(response.data.fav_status)
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const updateFavStatus = (op) => {
        setIsFav('updating');
        let data = {
            credentials: authContext.credentials,
            data: {
                dapp_id: appInfo.id,
                op: op
            }
        }
        axios.post(Common.ENDPOINT + Common.DAPP_FAV, data).then(response => {
            if (response.data.status === '1') {
                getFavStatus();
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getBalance = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                account_address: selectedWallet.address,
            }
        }
        axios.post(Common.ENDPOINT + 'bdaas/ethereum/get_balance', data).then(response => {
            if (response.data.status === '1') {
                setBalance(response.data.account_balance)
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const loadBalanceHandler = () => {
        setIsLaoding(true);
        let data = {
            credentials: authContext.credentials,
            data: {
                account_address: selectedWallet.address,
            }
        }
        axios.post(Common.ENDPOINT + 'bdaas/ethereum/transfer_ether', data).then(response => {
            setIsLaoding(false);
            if (response.data.status === '1') {
                getBalance();
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const createAccountHandler = () => {
        if (!accountNickName || accountNickName === '') {
            alert('Please name your account!');
            return;
        }
        setShowCreateAccount(false);
        setIsCreating(true);
        let data = {
            credentials: authContext.credentials,
            data: {
                dapp_id: appInfo.id,
                nick_name: accountNickName
            }
        }
        axios.post(Common.ENDPOINT + Common.DAPP_CREATE_ACCOUNT, data).then(response => {
            setIsCreating(false);
            if (response.data.status === '1') {
                getAccounts();
                alert('Account created successfully!');
                setShowCreateAccount(false);
                setAccountNickName(null);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const isInputValid = (input) => {
        var format = /^[a-zA-Z0-9-._]+$/
        return format.test(input)
    }

    let privateFor = appInfo && appInfo.contract.private_for && appInfo.contract.private_for.length > 0 ? <div style={{ fontSize: '1.4rem', color: '#555' }}><i className="fas fa-lock" style={{ marginLeft: 0 }}></i>Private contract, private for {
        appInfo.contract.private_for.map(index => {
            return <span style={{ fontWeight: '600' }}>Node{index + 1} </span>
        })
    }</div> : null;

    let contractInfo = null;
    let chainInfo = null;
    let appInformation = null;
    let functionSession = <p>Fetching Data...</p>;

    const createWalletReminder = wallets && wallets.length === 0 ? <div className={classes.reminder}><span>Seems like you do not have a wallet yet. Please create one first.</span></div> : null

    const walletItems = wallets ? wallets.map((wallet, i) => {
        return <Dropdown.Item key={i} eventKey={wallet} onSelect={() => setSelectedWallet(wallet)}>{wallet.nick_name} ({wallet.address})</Dropdown.Item>
    }) : null

    const copyWalletBtn = selectedWallet ?
        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={(props) => {
            return (
                <Tooltip id="copy-tooltip" {...props}>
                    Click to Copy Wallet address!
                </Tooltip>
            )
        }}>
            <span className={classes.copyBtn} onClick={(e) => {
                copyToClipboard(selectedWallet.address || selectedWallet);
                let ele = e.target;
                ele.innerText = ' Copied!'
                setTimeout(() => { ele.innerText = '' }, 1500)
            }}><i className="fas fa-copy"></i></span>
        </OverlayTrigger> : null

    let accountSession = !showLoginReminder ? !network ? <div style={{ marginTop: '1rem' }}>
        <h5 className={[classes.forceCenter, classes.inline].join(' ')} style={{ marginRight: '5px', width: '120px' }}>Select Wallet</h5>
        <DropdownButton className={classes.inline} title={selectedWallet ? `${selectedWallet.nick_name} (${selectedWallet.address})` : 'Please select a wallet'} variant="custom-blue" style={{ marginRight: '1rem' }}>
            {walletItems}
        </DropdownButton>
        {copyWalletBtn}
        {createWalletReminder}
        {balance ? !isLoading ? <span style={{ marginRight: '1rem' }}><b>{balance} ETH</b><span style={{ marginLeft: '1rem' }} className={classes.actionBtn} onClick={loadBalanceHandler}>+ Balance</span></span> : <span style={{ marginRight: '1rem' }}>Loading balance...</span> : null}
        {!isCreating ? <span className={classes.actionBtn} onClick={() => setShowCreateAccount(true)}>Create Wallet</span> : <span>Creating...</span>}
        {showCreateAccount ? <div className={classes.search} style={{ marginTop: '1rem' }}><InputGroup>
            <FormControl
                placeholder="Please enter the name of the new wallet"
                aria-label="Please enter the name of the new wallet"
                aria-describedby="newaccount-addon"
                value={accountNickName}
                onChange={(e) => {
                    setAccountNickName(e.target.value);
                    if (!isInputValid(e.target.value)) {
                        setShowFeedback(true);
                    } else setShowFeedback(false);
                }}
                maxLength="30"
            />
            {
                showFeedback || !accountNickName || accountNickName === '' ? <InputGroup.Append>
                    <div><span className={classes.actionBtn} style={{ marginLeft: '1rem' }} onClick={() => setShowCreateAccount(false)}>Cancel</span></div>
                </InputGroup.Append> : <InputGroup.Append>
                        <div><span className={classes.actionBtnDark} onClick={createAccountHandler}>Create</span><span className={classes.actionBtn} style={{ marginLeft: '1rem' }} onClick={() => setShowCreateAccount(false)}>Cancel</span></div>
                    </InputGroup.Append>
            }
        </InputGroup>
            {showFeedback && accountNickName !== '' ? <small>Wallet name can only contain alphanumeric characters, "-", "_", and "."</small> : null}
        </div> : null}
    </div> : selectedWallet ? <div style={{ fontSize: '1.4rem', fontWeight: '600' }}>Wallet: {selectedWallet} {copyWalletBtn}</div> : null : <div>Please <a href="/login">login</a> to select wallet.</div>

    if (appInfo) {
        contractInfo = <div style={{ marginBottom: '2rem' }}>
            <span style={{ marginRight: '1rem' }}><span style={{ marginRight: '1rem', fontSize: '2rem', fontWeight: '700', display: 'inline-block' }}>{appInfo.name}</span><span>({appInfo.contract.contract_address}{isCreator ? <span style={{ fontWeight: '600' }}>, #{appInfo.contract.contract_id} - {appInfo.contract.contract_name}</span> : null})</span></span>
            {appInfo.contract.contract_description ? <div><span style={{ color: '#666' }}>{appInfo.contract.contract_description}</span></div> : null}
        </div>

        let type = appInfo.chain_type;
        if (appInfo.chain_type === 'quorum') type = <img src={quorumLogo} height='20' alt="quorum" title="quorum chain" />
        else if (appInfo.chain_type === 'ethereum') type = <img src={ethereumLogo} height='20' alt="ethereum" title="ethereum chain" />

        chainInfo = <div style={{ marginBottom: '0.5rem' }}>
            <div style={{ fontWeight: 600, fontSize: '1.4rem', color: '#444' }}>
                {appInfo.chain_name} (ID: {appInfo.chain_id}) <span style={{ margin: '0 1rem' }}>{type}</span>
            </div>
            {privateFor}
        </div>

        appInformation = <div>
            <table>
                <tbody>
                    <tr>
                        <td style={{ padding: '0 2rem' }}>
                            <span className={classes.appImage}><img src={appInfo.icon_url || imageHolder} /></span></td>
                        <td style={{ padding: '0 2rem' }}>
                            {contractInfo}
                            {chainInfo}
                            {<div style={{ color: '#444' }}><span style={{ fontWeight: '600' }}>{appInfo.creator_name || appInfo.creator_email}</span> · <span>{toLocalTime(appInfo.timestamp)}</span></div>}
                            <div style={{ textAlign: 'right' }}>
                                {
                                    isFav === 'saved' ?
                                        <OverlayTrigger placement="bottom" delay={{ show: 0, hide: 200 }} overlay={(props) => {
                                            return (
                                                <Tooltip id="remove-fav-tooltip" {...props}>
                                                    <div style={{ fontSize: '1.4rem' }}>Remove from my favorite dapp list</div>
                                                </Tooltip>
                                            )
                                        }}><span className={classes.saveBtn} onClick={() => updateFavStatus('remove')}><i className="fas fa-heart"></i></span></OverlayTrigger>
                                        : isFav === 'unsaved' ? <OverlayTrigger placement="bottom" delay={{ show: 0, hide: 200 }} overlay={(props) => {
                                            return (
                                                <Tooltip id="remove-fav-tooltip" {...props}>
                                                    <div style={{ fontSize: '1.4rem' }}>Add to my favorite dapp list</div>
                                                </Tooltip>
                                            )
                                        }}><span className={classes.saveBtn} onClick={() => updateFavStatus('save')}><i className="far fa-heart"></i></span></OverlayTrigger>
                                            : isFav === 'updating' ? <span style={{ fontSize: '1.2rem' }}>Updating...</span> : null
                                }
                                <OverlayTrigger placement="bottom" overlay={(props) => {
                                    return (
                                        <Tooltip id="copy-link-tooltip" {...props} >
                                            <div style={{ fontSize: '1.4rem' }}>Click to copy dapp link!</div>
                                        </Tooltip>
                                    )
                                }}><span className={classes.shareBtn}><i className="fas fa-share-alt" onClick={(e) => {
                                    copyToClipboard(window.location.href);
                                    let ele = e.target
                                    ele.innerText = ' Copied!'
                                    setTimeout(() => ele.innerText = '', 1500)
                                }}></i></span></OverlayTrigger>
                                {
                                    isCreator ? <Link to="/console" onClick={() => {
                                        window.sessionStorage.setItem('_console_selected_info', JSON.stringify({
                                            chain_id: appInfo.chain_id,
                                            contract_id: appInfo.contract.contract_id,
                                            chain_type: appInfo.network_type === '' ? 'BT' : 'eth-official'
                                        }));
                                    }}><span className={classes.actionBtn}>Go to Console</span></Link> : null
                                }
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>

        let web3Contract = network && web3 ? new web3.eth.Contract(appInfo.contract.contract_abi, appInfo.contract.contract_address) : null;

        const transactFuncRow = appInfo.contract.application_console_contract_abi.transact.map((abi, i) => {
            if (abi.type === 'function' && abi.display === 1) 
            return <FuncRow data={abi} 
            contract={{ private_for: appInfo.contract.private_for, node_index: appInfo.contract.node_index }} 
            account={selectedWallet} web3Contract={web3Contract} key={i} dappId={appInfo.id} network={network} 
            calls={appInfo.contract.application_console_contract_abi.call}/>
        })

        const callFuncRow = appInfo.contract.application_console_contract_abi.call.map((abi, i) => {
            if (abi.type === 'function' && abi.display === 1) return <FuncRow data={abi} contract={{ private_for: appInfo.contract.private_for, node_index: appInfo.contract.node_index }} account={selectedWallet} web3Contract={web3Contract} key={i} dappId={appInfo.id} network={network} />
        })

        functionSession = <div style={{ maxWidth: '1200px' }}>
            <Row>
                <Col xs={12} md={6}>
                    <div className={[classes.funcType, classes.transact].join(' ')}><div className={classes.transact}>TRANSACT</div></div>
                    {transactFuncRow}
                </Col>
                <Col xs={12} md={6}>
                    <div className={[classes.funcType, classes.call].join(' ')}><div className={classes.call}>CALL</div></div>
                    {callFuncRow}
                </Col>
            </Row>
        </div>
    }

    const backToMarketplace = <Link to="/gallery"><div style={{ color: '#0070b3', marginBottom: '2rem' }}>{'<'} Back to Gallery</div></Link>

    return (
        <div className={classes.appPage}>
            {backToMarketplace}
            {
                !invalidId ? <div>
                    {appInformation}
                    <div style={{ marginTop: '2rem' }}>{accountSession}</div>
                    <div className={classes.container}>{functionSession}</div>
                </div> : <p>Sorry, this dapp doesn't exist, please choose another one.</p>
            }

        </div>
    )
}

function toLocalTime(time) {
    let utc_time = (new Date(time)).toString() + ' UTC';
    let localtime = new Date(utc_time).toLocaleString();
    return localtime;
}

const copyToClipboard = (secretInfo) => {
    var $body = document.getElementsByTagName('body')[0];
    var $tempInput = document.createElement('INPUT');
    $body.appendChild($tempInput);
    $tempInput.setAttribute('value', secretInfo)
    $tempInput.select();
    document.execCommand('copy');
    $body.removeChild($tempInput);
}

export default AppPage;