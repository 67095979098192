import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classes from './BlogPage.module.scss';
import axios from 'axios';
import * as Common from '../../../common';
import ReactHtmlParser from 'react-html-parser';
import grow from '../../../assets/images/loading.gif';

const BlogPage = () => {
    let { id } = useParams();
    let blogId = id.split('-').pop();
    const [isLoading, setIsLoading] = useState(false);
    const [blog, setBlog] = useState();

    useEffect(() => {
        getArticleContent();
        // setBlog({
        //     title: 'Blockchain Basics',
        //     content: <div>
        //         <img />
        //         <h2>1. Preparing the mold</h2>
        //         <p>The very obvious first step in making a pizza is, of course, determining the right tool. We will start the first line by declaring the version of Solidity for making the pizza: </p>
        //         <code>{`pragma solidity >=0.4.0 <0.7.0;`}</code>
        //         <p>The first line tells you that the source code is written for Solidity version starting from 0.4.0 and up to, but not including, 0.7.0. This is to ensure that the contract is not compilable with a new (breaking) compiler version, where it could behave differently.</p>
        //         <img />
        //         <h2>2. Select a topping</h2>
        //         <p>Next, we will need to decide on the toppings we will put on our pizza. Toppings, either be the darling-of-all-hearts salami or the love-it-or-hate-it pineapple, can be generalized into a contract. We can declare as such: </p>
        //         <code>
        //         contract StarterTopping {'{'}<br></br>
        //         {'}'}
        //         </code>
        //         <p>A contract is a collection of code (its <i>functions</i>) and data (its <i>state</i>) that resides at a specific address on the Ethereum blockchain. In this case, our contract starterTopping represents a generalization of all toppings, which we can further specify in the following steps. </p>
        //         <p>Now, it’s time for us to add more details to our toppings. Like with most of our friends, we can identify independent toppings by their names; therefore, our next step will be to declare a string variable that stores the name of our topping:</p>
        //         <code>
        //             contract StarterTopping {'{'}<br></br>
        //             {`    `}string toppingName;<br></br>
        //             {'}'}
        //             {
        //                 `contract StarterTopping {
        //                     string toppingName;
        //                 }
        //                 `
        //             }
                    
        //         </code>
        //         <code>
        //         contract StarterTopping {
        //             string toppingName;
        //         }
        //         </code>
        //     </div>
        // })
    }, [])

    const getArticleContent = () => {
        setIsLoading(true);
        let data = '?article_id=' + blogId;
        axios.get(Common.ENDPOINT + Common.ARTICLE_DETAIL + data).then(response => {
            if (response.data.status === '1') {
                let _blog = response.data.message_meta[0];
                axios.get(response.data.message_meta[0].content).then(response => {
                    setIsLoading(false);
                    _blog.content = response.data
                    setBlog(_blog)
                })
            }
            else { }
        })
    }

    return (
        <div className={classes.blog}>
            {
                !isLoading && blog ? <div>
                    <div className={classes.title}>{blog.title}</div>
                    <div className={classes.updateTime}>{toLocalTime(blog.last_update_time)}</div>
                    {ReactHtmlParser(blog.content)}
                    {/* {blog.content} */}
                </div> : <div style={{ width: '80px' }}><img src={grow} alt="animation" /></div>
            }
        </div>
    )
}

function toLocalTime(time) {
    let gmt_time = (new Date(time)).toString()
    let utc_time = gmt_time.substring(0, gmt_time.length - 4) + ' UTC';
    let localtime = new Date(utc_time).toLocaleString();
    return localtime;
}

export default BlogPage;