import React, { useState, useEffect, useContext } from 'react';
import classes from './Home.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../../../assets/images/Logo.png';
import grow from '../../../assets/images/loading.gif';
import { AuthContext } from '../../../context/auth-context';
import { useCookies } from 'react-cookie';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import chainPic from '../../../assets/images/chain.png';
import monitorPic from '../../../assets/images/monitor.png';
import editorPic from '../../../assets/images/editor.png';
import consolePic from '../../../assets/images/console.png';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import '../../../assets/css/carousel.css';
import axios from 'axios';
import * as Common from '../../../common';
import telegramLogo from '../../../assets/images/logo_telegram.png';
import Modal from 'react-bootstrap/Modal';
import DownArrow from '../../../assets/images/down-arrows2.png';

const Home = () => {
    // const authContext = useContext(AuthContext);
    // const [login, setLogin] = useState();
    // const [cookie, setCookie] = useCookies(['username', 'email'])
    const [news, setNews] = useState();
    const [tutorials, setTutorials] = useState();

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 0 },
            items: 1,
            partialVisibilityGutter: 0
        },
    };

    const responsive2 = {
        desktop: {
            breakpoint: { max: 3000, min: 900 },
            items: 2,
            partialVisibilityGutter: 0
        },
        small: {
            breakpoint: { max: 900, min: 0 },
            items: 1,
            partialVisibilityGutter: 0
        }
    };

    const [showAnnounce, setShowAnnounce] = useState(false);

    // useEffect(() => {
    //     if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
    //         setLogin(true);
    //     } else { setLogin(false) }
    // }, [authContext])

    useEffect(() => {
        getArticles();
    }, [])

    const getArticles = () => {
        let data = "?operation=all&page=1&per_page=4"
        axios.get(Common.ENDPOINT + Common.ARTICLES_GET + data).then(response => {
            if (response.data.status === '1') {
                let _news = []
                let _tutorials = [];
                response.data.article_meta.forEach(article => {
                    if (article.article_type === 'news') _news.push(article)
                    else if (article.article_type === 'tutorial') _tutorials.push(article)
                })
                setNews(_news);
                setTutorials(_tutorials);
            }
            else {
                // if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    // let unreadMsgCount = authContext && authContext.unreadMsgCount !== 0 ? <sup title='You have unread messages' style={{ fontFamily: '"Open Sans", sans-serif' }}><Badge variant="danger">{authContext.unreadMsgCount}</Badge></sup> : null;

    // const telegram = <span className={classes.telegram}><a href="https://t.me/BlockTestOfficial" target="_blank">Join us on<img src={telegramLogo} height={20} /><b>Telegram</b></a></span>

    // const userStatus = login ?
    //     <span className={classes.greeting} title={cookie.email}>
    //         <Link title="go to profile page" className={classes.link} to="/user/profile"><b>{cookie.email}</b></Link>
    //         {' '}
    //         <Link title="go to message center" className={classes.link} to="/user/messages"><i className="fas fa-bell">{unreadMsgCount}</i></Link>
    //         {' '}
    //         {telegram}
    //     </span> :
    //     <span className={classes.greeting}><a href="/login"><b>Login</b></a>{telegram}</span>

    const announce = <Modal show={showAnnounce} animation={true} onHide={() => { setShowAnnounce(false) }} dialogClassName={classes.modal}>
        <Modal.Header closeButton>
            <Modal.Title><b></b></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '3rem' }}>
        </Modal.Body>
        <Modal.Footer>
            <div className={classes.btPrimary} onClick={() => { setShowAnnounce(false) }}>Got it!</div>
        </Modal.Footer>
    </Modal>

    return (
        <React.Fragment>
            {announce}
            <div className={classes.home}>
                {/* <div className={classes.banner}>
                    <a href="https://blocktest.com"><img src={logo} alt="blocktest-logo" /></a>{userStatus}
                </div> */}
                {/* <div className={classes.announce}><span onClick={() => setShowAnnounce(true)}>+ More</span></div> */}
                <div className={classes.header}>
                    <Row>
                        <Col xs={12} md={10} lg={6}>
                            <h1>Greenhouse<sup><Badge style={{ fontSize: '1.4rem', marginLeft: '3px' }}>BETA</Badge></sup><br></br>All-in-one Blockchain DevOps Platform<img src={grow} alt="animation" /></h1>
                            <h3>Building networks and developing robust applications for real-life use cases.
                                {/* <a href="https://greenhouse-resource.s3.us-east-2.amazonaws.com/BDaaS/Greenhouse_demo_20200821.mp4" target="_blank"><i class="fas fa-video"></i><b>Demo</b></a> */}
                            </h3>
                        </Col>
                        <Col xs={12} md={12} lg={6} style={{ marginTop: '2rem' }}>
                            {/* <video controls width="100%" poster={demoCover}>
                                <source src="https://greenhouse-resource.s3.us-east-2.amazonaws.com/BDaaS/Greenhouse_demo_20200821.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video> */}
                            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/yIMDkaGXGb0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/yIMDkaGXGb0" frameBorder="0" allowFullScreen={true} webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
                        </Col>
                    </Row>
                </div>
                <div className={classes.scrollDown} style={{ color: '#0070b3', marginBottom: '-4rem' }}
                    onClick={() => {
                        document.getElementById('beginning-of-news').scrollIntoView({
                            behavior: 'smooth'
                        });
                    }}>
                    <div><b>Tutorial & Insights</b></div>
                    <img src={DownArrow} width="50" />
                </div>
                <div className={[classes.triangleBorder, classes.blue4].join(' ')}></div>
                <div className={[classes.triangleBorder, classes.blue3].join(' ')}></div>
                <div className={[classes.content, classes.blue].join(' ')}>
                    <Carousel responsive={responsive}
                        additionalTransfrom={0}
                        arrows
                        autoPlay
                        autoPlaySpeed={10000}
                        centerMode={false}
                        focusOnSelect={false}
                        infinite
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        showDots
                        slidesToSlide={1}
                        swipeable >
                        <Row style={{ padding: '0 4vw' }}>
                            <Col xs={12} md={12} lg={4} className={classes.block}>
                                <div className={classes.title}>One-Click Chain Environments</div>
                                <div className={classes.description}>Develop Dapps with the shared Ethereum and Quorum chain environments provided for free, or create your own private chain environments.</div>
                                <Link to='/chains'><div className={[classes.btAction, classes.MainBtn].join(' ')}>Get Started {'>'}</div></Link>
                            </Col>
                            <Col xs={12} md={12} lg={8}>
                                <div className={classes.image}><img src={chainPic} /></div>
                            </Col>
                        </Row>
                        <Row style={{ padding: '0 4vw' }}>
                            <Col xs={12} md={12} lg={4} className={classes.block}>
                                <div className={classes.title}>Code in Your Browser</div>
                                <div className={classes.description}>Greenhouse provides a Cloud-based IDE that allows you to code anywhere & anytime.</div>
                                <Link to='/editor'><div className={[classes.btAction, classes.MainBtn].join(' ')}>Get Started {'>'}</div></Link>
                            </Col>
                            <Col xs={12} md={12} lg={8}>
                                <div className={classes.image}><img src={editorPic} /></div>
                            </Col>
                        </Row>
                        <Row style={{ padding: '0 4vw' }}>
                            <Col xs={12} md={12} lg={4} className={classes.block}>
                                <div className={classes.title}>Turn Your Dapp into a Web App</div>
                                <div className={classes.description}>Greenhouse can automatically turn your deployed Dapps into a Web Apps and RESTful APIs. Also, you can publish them in the Dapp Gallery.</div>
                                <Link to='/console'><div className={[classes.btAction, classes.MainBtn].join(' ')}>Get Started {'>'}</div></Link>
                            </Col>
                            <Col xs={12} md={12} lg={8}>
                                <div className={classes.image}><img src={consolePic} /></div>
                            </Col>
                        </Row>
                        <Row style={{ padding: '0 4vw' }}>
                            <Col xs={12} md={12} lg={4} className={classes.block}>
                                <div className={classes.title}>See What Is Happening on the Blockchain</div>
                                <div className={classes.description}>Greenhouse's data engine allows you to easily navigate through the blocks, transactions, contracts, so you can know exactly what is happening on the chain.</div>
                                <Link to='/chains'><div className={[classes.btAction, classes.MainBtn].join(' ')}>Get Started {'>'}</div></Link>
                            </Col>
                            <Col xs={12} md={12} lg={8}>
                                <div className={classes.image}><img src={monitorPic} /></div>
                            </Col>
                        </Row>
                    </Carousel>
                </div>
                <div className={[classes.triangleBorder, classes.blue1].join(' ')}></div>
                <div className={[classes.triangleBorder, classes.blue2].join(' ')}></div>
                <div id="beginning-of-news"></div>
                {
                    news ? <div>
                        <div className={[classes.posts, classes.white].join(' ')}>
                            <Row>
                                <Col xs={12} md={8} lg={9}>
                                    <Carousel responsive={responsive2}
                                        additionalTransfrom={0}
                                        arrows
                                        centerMode={false}
                                        containerClass="container"
                                        focusOnSelect={false}
                                        infinite
                                        keyBoardControl
                                        minimumTouchDrag={80}
                                        renderButtonGroupOutside={false}
                                        renderDotsOutside={false}
                                        showDots
                                        slidesToSlide={1}
                                        swipeable >
                                        {
                                            news.map((article, i) => {
                                                let name = article.title.split(' ').join('-')
                                                return <Link to={`/blog/${name}-${article.id}`} key={article.id}><Card className={classes.card}>
                                                    <Card.Img variant="top" src={article.img_url} />
                                                    <Card.Body>
                                                        <div className={classes.cardTitle}>{article.title}</div>
                                                    </Card.Body>
                                                    <Card.Footer>
                                                        <small className="text-muted">{toLocalTime(article.last_update_time)}</small>
                                                    </Card.Footer>
                                                </Card></Link>
                                            })
                                        }
                                    </Carousel>
                                </Col>
                                <Col xs={12} md={4} lg={3}>
                                    <div className={classes.title}>News and Insights</div>
                                    {/* <Link to='/home'><div className={[classes.btAction, classes.MainBtn].join(' ')}>Read More {'>'}</div></Link> */}
                                </Col>
                            </Row>
                        </div>
                    </div> : null
                }
                {
                    tutorials ? <div>
                        <div className={[classes.triangleBorder, classes.green4].join(' ')}></div>
                        <div className={[classes.triangleBorder, classes.green3].join(' ')}></div>
                        <div className={[classes.posts, classes.green].join(' ')}>
                            <Row>
                                <Col xs={12} md={4} lg={3}>
                                    <div className={classes.title}>Tutorials</div>
                                    {/* <Link to='/home'><div className={[classes.btAction, classes.MainBtn].join(' ')}>Read More {'>'}</div></Link> */}
                                </Col>
                                <Col xs={12} md={8} lg={9}>
                                    <Carousel responsive={responsive2}
                                        additionalTransfrom={0}
                                        arrows
                                        centerMode={false}
                                        containerClass="container"
                                        focusOnSelect={false}
                                        infinite
                                        keyBoardControl
                                        minimumTouchDrag={80}
                                        renderButtonGroupOutside={false}
                                        showDots
                                        renderDotsOutside
                                        slidesToSlide={1}
                                        swipeable
                                    >
                                        {
                                            tutorials.map((article, i) => {
                                                let name = article.title.split(' ').join('-')
                                                return <Link to={`/blog/${name}-${article.id}`} key={article.id}><Card className={classes.card}>
                                                    <Card.Img variant="top" src={article.img_url} />
                                                    <Card.Body>
                                                        <div className={classes.cardTitle}>{article.title}</div>
                                                    </Card.Body>
                                                    <Card.Footer>
                                                        <small className="text-muted">{toLocalTime(article.last_update_time)}</small>
                                                    </Card.Footer>
                                                </Card></Link>
                                            })
                                        }
                                    </Carousel>
                                </Col>
                            </Row>
                        </div>
                        <div className={[classes.triangleBorder, classes.green1].join(' ')}></div>
                        <div className={[classes.triangleBorder, classes.green2].join(' ')}></div></div>
                        : null
                }
            </div>
        </React.Fragment>
    )
}

function toLocalTime(time) {
    let gmt_time = (new Date(time)).toString()
    let utc_time = gmt_time.substring(0, gmt_time.length - 4) + ' UTC';
    let localtime = new Date(utc_time).toLocaleString();
    return localtime;
}

export default Home;