import React, { useState, useEffect, useContext } from 'react';
import classes from './Card.module.scss';
// import imageHolder from '../../../../assets/images/photo-default.png';
import imageHolder from '../../../../assets/images/blockchain.png';
import { Link } from 'react-router-dom';
import ethereumLogo from '../../../../assets/images/logo_ethereum.png';
import quorumLogo from '../../../../assets/images/logo_quorum.png';

const Card = (props) => {

    let appInfo = props.data;
    let chainInfo = null, contractId = null;
    if (props.isCreator) {
        chainInfo = <div className={classes.chainInfo} title={`${appInfo.chain_name} (ID: ${appInfo.chain_id})`}>{appInfo.chain_name} (ID: {appInfo.chain_id})</div>
        contractId = <div className={classes.chainInfo}>Contract ID: <b>{appInfo.contract_id}</b></div>
    }
    let type = appInfo.chain_type;
    if (appInfo.chain_type === 'quorum') type = <img src={quorumLogo} height='16' alt="quorum" title="quorum chain" />
    else if (appInfo.chain_type === 'ethereum') type = <img src={ethereumLogo} height='16' alt="ethereum" title="ethereum chain" />

    let id = appInfo.id;
    if (appInfo.url_id && appInfo.url_id !== '') id = appInfo.url_id;

    return (
        <Link to={`/gallery/shop/${id}`}>
            <div className={classes.card}>
                <div style={{ display: 'flex' }}>
                    <img src={appInfo.icon_url || imageHolder} className={classes.logo}></img>
                    <div className={classes.inline} style={{ margin: 'auto 0' }}>
                        <div className={classes.name} title={appInfo.name}>{appInfo.name}</div>
                        <div>{type} {chainInfo}</div>
                        {contractId}
                        <div className={classes.creator} title={appInfo.creator_name}>{appInfo.creator_name}</div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default Card;