import React, { useState, useEffect, useContext } from 'react';
import classes from './Monitor.module.scss';
import { AuthContext } from '../../../context/auth-context';
import { MonitorContext } from '../../../context/monitor-context';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as Common from '../../../common';
import { checkAuth } from '../../../commonFunc';
import ReactPaginate from 'react-paginate';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const Nodes = () => {
    const monitorContext = useContext(MonitorContext);
    const authContext = useContext(AuthContext);

    const [currPage, setCurrPage] = useState(1);
    const [pages, setPages] = useState();
    const [perPage, setPerPage] = useState(10);
    const [nodeList, setNodeList] = useState()
    const [selectedNode, setSelectedNode] = useState();
    const [nodeInfo, setNodeInfo] = useState()
    const [refreshing, setRefreshing] = useState(false);

    useEffect(() => {
        if (monitorContext.chain) getNodeData();
    }, [monitorContext])

    useEffect(() => {
        if (selectedNode && nodeList) {
            nodeList.forEach(node => {
                if (node.node_id === selectedNode) setNodeInfo(node);
            })
        }
    }, [selectedNode])

    const getNodeData = () => {
        setRefreshing(true);

        let data = {
            credentials: authContext.credentials,
            data: {
                page: currPage,
                per_page: perPage,
                chain_id: monitorContext.chain.chain_id,
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_GET_NODES_V2, data).then(response => {
            setRefreshing(false);

            if (response.data.status === '1') {
                setNodeList(response.data.items)
                if (response.data.items.length > 0) setSelectedNode(response.data.items[0].node_id)
                setPages(Math.ceil(parseFloat(response.data.total) / perPage))
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const tablePagination = pages > 0 ? <ReactPaginate
        forcePage={currPage - 1}
        previousLabel={<i className="fas fa-caret-left"></i>}
        nextLabel={<i className="fas fa-caret-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h"></i>}
        breakClassName={'break-me'}
        pageCount={pages}
        onPageChange={(page) => {
            setCurrPage(page.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
    /> : null

    const nodes = nodeList ? nodeList.length > 0 ? nodeList.map((node, i) => {
        let _classes
        if (node.node_id === selectedNode) {
            _classes = [classes.node, classes.selected].join(' ')
        } else _classes = classes.node
        return <div key={i} className={_classes} title={node.name} onClick={() => setSelectedNode(node.node_id)}>{node.name}</div>
    }) : <div>No nodes exists</div> : <div>Fetching Data...</div>

    const nodeDetail = selectedNode && nodeInfo ? <div className={classes.container}>
        <div className={classes.thirdTitle} style={{ marginBottom: '1.5rem' }}>Node Details</div>
        <div className={classes.nodeDetails}>
            <Row>
                <Col xs={6} md={3}><div>Node Name</div></Col>
                <Col xs={6}><div><b>{nodeInfo.name}</b></div></Col>
            </Row>
            <Row>
                <Col xs={6} md={3}><div>Creation Date</div></Col>
                <Col xs={6}><div>{toLocalTime(nodeInfo.create_time)}</div></Col>
            </Row>
            <Row>
                <Col xs={6} md={3}><div>Last Update Date</div></Col>
                <Col xs={6}><div>{toLocalTime(nodeInfo.last_update_time)}</div></Col>
            </Row>
            <Row>
                <Col xs={6} md={3}><div>Node ID</div></Col>
                <Col xs={6}><div>{nodeInfo.node_id}</div></Col>
            </Row>
            <Row>
                <Col xs={6} md={3}><div>Peers</div></Col>
                <Col xs={6}><div><b>{nodeInfo.peers}</b></div></Col>
            </Row>
            <Row>
                <Col xs={6} md={3}><div>CPU</div></Col>
                <Col xs={6}><div><b>{nodeInfo.cpu || 'Not Available'}</b></div></Col>
            </Row>
            <Row>
                <Col xs={6} md={3}><div>RAM</div></Col>
                <Col xs={6}><div><b>{nodeInfo.ram || 'Not Available'}</b>{nodeInfo.ram_usage_rate ? ` (${nodeInfo.ram_usage_rate})` : null}</div></Col>
            </Row>
            <Row>
                <Col xs={6} md={3}><div>Disk</div></Col>
                <Col xs={6}><div><b>{nodeInfo.disk || 'Not Available'}</b>{nodeInfo.disk_usage_rate ? ` (${nodeInfo.disk_usage_rate})` : null}</div></Col>
            </Row>
        </div>
    </div> : null;

    return (
        <div className={classes.monitor}>
            <div className={classes.subTitle}>Nodes<OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Popover id="contracts-popover">
                    <Popover.Content>
                        <p>A node is basically a device (like a computer) that contains a full copy of the transaction history of the blockchain. </p>
                    </Popover.Content>
                </Popover>}>
                <sup><i class="fas fa-info-circle"></i></sup></OverlayTrigger></div>
            {monitorContext.chain ?
                <div>
                    {
                        nodeList ? <div style={{ textAlign: 'right' }}>
                            {
                                !refreshing ? <span className={classes.refreshBtn} title="refresh data"><i className="fas fa-sync-alt" onClick={() => getNodeData()}></i></span> :
                                    <Spinner animation="border" variant="secondary" />
                            }
                        </div> : null
                    }
                    <div style={{ marginBottom: '2rem' }}>{nodes}</div>
                    {tablePagination}
                    {nodeDetail}
                </div> : <div>Click <Link to="/chain">here</Link> to select a chain first</div>
            }

        </div>
    )
}

function toLocalTime(time) {
    return new Date((new Date(time)).toString() + ' UTC').toLocaleString();
}

export default Nodes;