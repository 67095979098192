import React, { useState } from 'react';
import * as Common from '../common';
import { useCookies } from 'react-cookie';
import Modal from 'react-bootstrap/Modal';

const COOKIE_LIFTTIME = 1; // day
export const AuthContext = React.createContext({
  isAuth: false,
  login: () => { },

});

const AuthContextProvider = props => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [credentials, setCredentials] = useState({
    email: null,
    token: null,
    service_id: Common.SERVICE_ID,
  })
  const [userid, setUserid] = useState(null);
  const [usertype, setUsertype] = useState(null);
  const [username, setUsername] = useState(null);
  const [roles, setRoles] = useState(null);
  const [icon, setIcon] = useState(null);
  const [cookie, setCookie, removeCookie] = useCookies(['email', 'token', 'username', 'userid', 'usertype', 'roles', 'icon'])
  const [unreadMsgCount, setUnreadMsgCount] = useState();

  const loginHandler = (email, token, username, userid, usertype, roles, icon) => {
    updateCookie('email', email, COOKIE_LIFTTIME, Common.DOMAIN);
    updateCookie('token', token, COOKIE_LIFTTIME, Common.DOMAIN);
    updateCookie('username', username, COOKIE_LIFTTIME, Common.DOMAIN);
    updateCookie('userid', userid, COOKIE_LIFTTIME, Common.DOMAIN);
    updateCookie('usertype', usertype, COOKIE_LIFTTIME, Common.DOMAIN);
    updateCookie('roles', JSON.stringify(roles), COOKIE_LIFTTIME, Common.DOMAIN);
    updateCookie('icon', icon, COOKIE_LIFTTIME, Common.DOMAIN)
    setIsAuthenticated(true);
    setCredentials({
      ...credentials,
      email: email,
      token: token,
    });
    setUserid(userid);
    setUsertype(usertype);
    setUsername(username);
    setRoles(roles);
    setIcon(icon);
  };

  const credentialRenew = () => {
    // updateCookie('email', cookie.email, 0.5, Common.DOMAIN);
    // updateCookie('token', cookie.token, 0.5, Common.DOMAIN);
    // updateCookie('username', cookie.username, 0.5, Common.DOMAIN);
    // updateCookie('userid', cookie.userid, 0.5, Common.DOMAIN);
    // updateCookie('usertype', cookie.usertype, 0.5, Common.DOMAIN);
  }

  const [showLogOutAlert, setShowLogOutAlert] = useState(false);
  const [logOutAlertContent, setLogOutAlertContent] = useState('');

  const removeAuth = () => {
    setIsAuthenticated(false);
    removeCookie('email', { path: '/', domain: Common.DOMAIN });
    removeCookie('token', { path: '/', domain: Common.DOMAIN });
    removeCookie('username', { path: '/', domain: Common.DOMAIN });
    removeCookie('userid', { path: '/', domain: Common.DOMAIN });
    removeCookie('usertype', { path: '/', domain: Common.DOMAIN });
    removeCookie('roles', { path: '/', domain: Common.DOMAIN });
    removeCookie('icon', { path: '/', domain: Common.DOMAIN });

    window.sessionStorage.removeItem('selected_chain');
    window.sessionStorage.removeItem('selected_node_id');
    window.sessionStorage.removeItem('_console_selected_info');
    setCredentials(null);
    setUserid(null);
    setUsertype(null);
    setRoles(null);
    setIcon(null);
    setUsername(null);
  }

  const logoutHandler = () => {
    setShowLogOutAlert(true);
    setLogOutAlertContent('Session expired, please sign in again.');
    setTimeout(() => {
      setShowLogOutAlert(false);
      window.location.href = "/login";
    }, 3000);
    removeAuth();
  };

  const maintenanceModeHandler = (message) => {
    setShowLogOutAlert(true);
    setLogOutAlertContent(message);
    setTimeout(() => {
      setShowLogOutAlert(false);
      window.location.href = "/login";
    }, 3000);
    removeAuth();
  }

  const signOutHandler = () => {
    removeAuth();
    window.location.href = "/login";
  }

  const updateCookie = (key, value, days, domain) => {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    setCookie(key, value, { path: '/', expires: date, domain: domain })
  }

  const updateIcon = (icon) => {
    setIcon(icon);
    updateCookie('icon', icon, COOKIE_LIFTTIME, Common.DOMAIN)
  }

  const updateUsername = (username) => {
    setUsername(username);
    updateCookie('username', username, COOKIE_LIFTTIME, Common.DOMAIN)
  }

  return (
    <AuthContext.Provider
      value={{
        login: loginHandler,
        logout: logoutHandler,
        signout: signOutHandler,
        maintenance: maintenanceModeHandler,
        isAuth: isAuthenticated,
        credentials: credentials,
        userid: userid,
        usertype: usertype,
        renew: credentialRenew,
        username: username,
        roles: roles,
        icon: icon,
        setIcon: updateIcon,
        setUsername: updateUsername
      }}
    >
      <Modal show={showLogOutAlert}>
        <Modal.Body>
          <h3 style={{ padding: '2rem 1rem' }}>{logOutAlertContent}</h3>
        </Modal.Body>
      </Modal>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;