import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Menu from './Menu';
import chainAdmin from './ChainAdmin';
import chainActivity from './ChainActivity';
import monitor from '../Monitor/Monitor';
const Chain = () => {
    return (
        <div>
            <Menu />
            <Switch>
                <Route path='/chain/admin' exact component={chainAdmin}></Route>
                <Route path='/chain/activity' exact component={chainActivity}></Route>
                <Route path='/chain/monitor' component={monitor}></Route>
                <Redirect from="/chain" to="/chain/admin" />
            </Switch>
        </div>
    )
}

export default Chain;