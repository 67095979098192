export const convertRole = (role) => {
  switch (role) {
    case 'admin': return 'ADMIN';
    case 'demo-user': return 'DEMO';
    case 'user': return 'USER';
    default: return null;
  }
}

const SHORT_DESC = {
  ADMIN: 'As an admin, you can manage all the chains.',
  DEMO: 'As a demo user, you can create/reboot/terminate basic chains, and can have 1 active Ethereum chain and 1 Quorum chain at any time.',
  // USER: 'As a user, you can view all the shared chains, but cannot manage chains.'
  USER: 'As a user, you can create/reboot/terminate basic chains, and can have 1 active Ethereum chain and 1 Quorum chain at any time.',
}

export const getShortDesc = (role) => {
  return SHORT_DESC[convertRole(role)]
}