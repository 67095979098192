import React, { useState, useEffect, useContext } from 'react';
import classes from './Monitor.module.scss';
import { useParams } from 'react-router-dom';
import { MonitorContext } from '../../../context/monitor-context';
import { AuthContext } from '../../../context/auth-context';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
import Timestamp from 'react-timestamp';
import axios from 'axios';
import * as Common from '../../../common';
import { checkAuth } from '../../../commonFunc';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Badge from 'react-bootstrap/Badge';
import Tooltip from 'react-bootstrap/Tooltip';

const Contract = () => {
    let { contractId } = useParams();
    const authContext = useContext(AuthContext);
    const monitorContext = useContext(MonitorContext);
    const [contractInfo, setContractInfo] = useState();
    const [currPage, setCurrPage] = useState(1);
    const [pages, setPages] = useState(12);
    const [perPage, setPerPage] = useState(10);
    const [txData, setTxData] = useState();

    const pagination_options = [10, 25, 50];

    const getContractInfo = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                id: contractId,
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_GET_CONTRACT, data).then(response => {
            if (response.data.status === '1') {
                setContractInfo(response.data);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getTxs = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                page: currPage,
                per_page: perPage,
                chain_id: monitorContext.chain.chain_id,
                contract_address: contractInfo.contract_hash
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_GET_TRANSACTIONS_V2, data).then(response => {
            if (response.data.status === '1') {
                setTxData(response.data.items);
                setPages(Math.ceil(parseFloat(response.data.total_items) / perPage))
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    useEffect(() => {
        if (monitorContext.chain) {
            getContractInfo();
        }
    }, [monitorContext])

    useEffect(() => {
        if (monitorContext.chain && contractInfo) {
            getTxs();
        }
    }, [monitorContext, contractInfo])

    const popover = contractInfo ? (
        <Popover id="popover-basic" style={{maxWidth: '600px'}}>
            <Popover.Title as="h3"><span>Source Code</span></Popover.Title>
            <Popover.Content style={{maxWidth: '600px'}}>
                <pre style={{maxHeight:'300px'}}>{contractInfo.source_code}</pre>
                <div style={{textAlignLast:'right'}}><span className={classes.actionBtn} onClick={(e) => { 
                    copyToClipboard(contractInfo.source_code) 
                    let ele = e.target
                    ele.innerText = 'Copied!'
                    setTimeout(() => ele.innerText = 'Copy to Clipboard', 2000)
                }}>Copy to Clipboard</span></div>
            </Popover.Content>
        </Popover>
    ) : null;

    const backToContracts = <Link to="/chain/monitor/contracts"><div className={classes.actionBtn} style={{ fontSize: '1.4rem' }}>&lt; Back to Contracts</div></Link>
    const contractOverview = contractInfo ? <div className={classes.container}>
        <div className={classes.contractDetails}>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Contract Name</div></Col>
                <Col xs={8} md={9}><div><b>{contractInfo.contract_name}</b>{contractInfo.is_private ? <span><i className="fas fa-lock" style={{color:'#00b6a6'}} title="private contract"></i></span> : null} <span className={classes.highlight} title="copy ABI to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(contractInfo.abi) }}>ABI</span> <span className={classes.highlight} title="copy Bytecode to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(contractInfo.byte_code) }}>BYTECODE</span>{' '}
                    {contractInfo.source_code ?
                    // <span className={classes.highlight} title="copy source code to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(contractInfo.source_code) }}>SOURCECODE</span> : 
                    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                        <span className={classes.highlight} style={{ cursor: 'pointer' }}>SOURCE CODE</span>
                    </OverlayTrigger> : null } 
                </div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>ID</div></Col>
                <Col xs={8} md={9}><div><b>#{contractId}</b></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Address</div></Col>
                <Col xs={8} md={9}><div>{contractInfo.contract_hash}<i className="far fa-copy" title="copy block hash to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(contractInfo.contract_hash) }}></i></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Block</div></Col>
                <Col xs={8} md={9}><div><Link to={`/chain/monitor/blocks/${contractInfo.block_id}`}><b>#{contractInfo.block_number}</b> {contractInfo.block_hash}</Link><i className="far fa-copy" title="copy block hash to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(contractInfo.block_hash) }}></i></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Time</div></Col>
                <Col xs={8} md={9}><div><b><Timestamp relative date={toLocalTime(contractInfo.time)} /></b> ({toLocalTime(contractInfo.time)})</div></Col>
            </Row>
            {
                contractInfo.creator ? <Row>
                    <Col xs={4} md={3} lg={2}><div>Creator</div></Col>
                    <Col xs={8} md={9}><div>{contractInfo.creator}<i className="far fa-copy" title="copy creator address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(contractInfo.creator) }}></i></div></Col>
                </Row> : null
            }
            <Row>
                <Col xs={4} md={3} lg={2}><div>Transaction Hash</div></Col>
                <Col xs={8} md={9}><div>{contractInfo.tx_hash}<i className="far fa-copy" title="copy transaction hash to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(contractInfo.tx_hash) }}></i></div></Col>
            </Row>
            {
                contractInfo.is_private && contractInfo.private_for ? <Row><Col xs={4} md={3} lg={2}><div>Private For</div></Col>
                <Col xs={8} md={9}><div>{
                        contractInfo.private_for.map(index => {
                            return <Badge variant="info" style={{color: 'white', marginRight:'3px'}}>Node {index + 1}</Badge>
                        })
                    }
                </div></Col>
                </Row> : null
            }
        </div>
    </div> : <div className={classes.container}>Fetching Data...</div>;

    let tableRows = txData ? txData.length > 0 ? txData.map((tx, i) => {
        let txType = tx.tx_type;
        if (txType === 'invoke') {
            let inputs = ''
            tx.contract.inputs.forEach(input => {
                inputs += (input + ', ')
            })
            if (inputs !== '') inputs = inputs.substring(0, inputs.length - 2);
            inputs = '(' + inputs + ')'
            txType = <OverlayTrigger
                placement={"right"}
                overlay={
                    <Tooltip style={{ fontSize: '12px' }}>
                        Invoked function - <b>{tx.contract.function}{inputs}</b> from contract - <b>{tx.contract.contract_name}</b>
                    </Tooltip>
                }
            ><Badge pill variant="info" style={{ color: 'white' }}>Invoke<i className="fas fa-info-circle" style={{ color: 'white', marginRight: '0' }}></i></Badge></OverlayTrigger>
        } else if (txType === 'deploy') {
            let isPrivate = tx.contract.is_private ? <i className="fas fa-lock" title="private contract" style={{ color: 'white' }}></i> : null;
            txType = <OverlayTrigger
                placement={"right"}
                overlay={
                    <Tooltip style={{ fontSize: '12px' }}>
                        Deplyed <b>{tx.contract.is_private ? 'Private ' : 'Public '}</b>Contract - <b>{tx.contract.contract_name}</b>
                    </Tooltip>
                }
            >
                <Badge pill variant="secondary" style={{ color: 'white' }}>Deploy{isPrivate}<i className="fas fa-info-circle" style={{ color: 'white', marginRight: '0' }}></i></Badge></OverlayTrigger>
        } else if (txType === 'transfer') {
            txType = <Badge pill variant="warning" style={{ color: 'white' }}>Transfer</Badge>

        }
        return <tr key={i}>
            <td><Link to={`/chain/monitor/transactions/${tx.id}`}><b>Tx</b> {trimHash(tx.tx_hash)}</Link><i className="far fa-copy" title="copy hash to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(tx.tx_hash) }}></i></td>
            <td><b>From</b> {trimHash(tx.from)}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(tx.from) }}></i></td>
            <td><b>To</b> {trimHash(tx.to)}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(tx.to) }}></i></td>
            <td>{txType}</td>
            <td><span title={toLocalTime(tx.tx_time)}>{toLocalTime(tx.tx_time)}</span></td>
        </tr>
    }) : <tr><td colSpan="5">No Transaction Data available</td></tr> : <tr><td colSpan="5">Fetching Data...</td></tr>;

    let txTable = <Table hover className={classes.table}>
        <tbody>
            {tableRows}
        </tbody>
    </Table>

    const paginationUpdate = (perPage) => {
        setPerPage(perPage);
        setCurrPage(1);
    }

    const paginationOptions = pagination_options.map((number, index) => {
        let classes = "option"
        if (number === perPage) classes = "option selected";
        return <span className={classes} key={index} onClick={() => { paginationUpdate(number) }}>{number}</span>
    })

    const tablePagination = pages > 0 ? <ReactPaginate
        forcePage={currPage - 1}
        previousLabel={<i className="fas fa-caret-left"></i>}
        nextLabel={<i className="fas fa-caret-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h"></i>}
        breakClassName={'break-me'}
        pageCount={pages}
        onPageChange={(page) => {
            setCurrPage(page.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
    /> : null

    let txTableSession = <div>
        <div className={classes.spaceBetween}>
            <div className={classes.thirdTitle}>Lastest Transactions</div>
            <div className="pagination_menu" style={{ fontSize: '1.2rem' }}>show {paginationOptions} items</div>
        </div>
        {txTable}
        {tablePagination}
    </div>

    return (
        <div className={classes.monitor}>
            <div className={classes.subTitle}>Contract</div>
            {backToContracts}
            {contractOverview}
            {txTableSession}
        </div>
    )
}

function trimHash(hash) {
    return hash.substring(0, 6) + '...' + hash.substring(hash.length - 6, hash.length)
}

function toLocalTime(time) {
    return new Date((new Date(time)).toString() + ' UTC').toLocaleString();
}

function copyToClipboard(secretInfo) {
    var $body = document.getElementsByTagName('body')[0];
    var $tempInput = document.createElement('INPUT');
    $body.appendChild($tempInput);
    $tempInput.setAttribute('value', secretInfo)
    $tempInput.select();
    document.execCommand('copy');
    $body.removeChild($tempInput);
}

export default Contract;