import React, { useState, useEffect, useContext } from 'react';
import classes from './Marketplace.module.scss';
import Card from './Card/Card';
import axios from 'axios';
import * as Common from '../../../common';
import ReactPaginate from 'react-paginate';
import { AuthContext } from '../../../context/auth-context';
import { checkAuth } from '../../../commonFunc';

const MyApps = () => {
    const authContext = useContext(AuthContext);
    const [showLoginReminder, setShowLoginReminder] = useState();
    const [currPagePublish, setCurrPagePublish] = useState(1);
    const [pagesPublish, setPagesPublish] = useState();
    const [dappDataPublish, setDappDataPublish] = useState();

    const perPage = 24;

    useEffect(() => {
        if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
            setShowLoginReminder(false);
            getPublishedApps();
        } else { setShowLoginReminder(true); }
    }, [authContext, currPagePublish])

    const getPublishedApps = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                current_page: currPagePublish,
                per_page: perPage,
            }
        }
        axios.post(Common.ENDPOINT + Common.PUBLISHED_DAPP_LIST, data).then(response => {
            if (response.data.status === '1') {
                setDappDataPublish(response.data.items);
                setPagesPublish(Math.ceil(parseFloat(response.data.total_items) / perPage))
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const pulishPagination = pagesPublish > 0 ? <ReactPaginate
        forcePage={currPagePublish - 1}
        previousLabel={<i className="fas fa-caret-left"></i>}
        nextLabel={<i className="fas fa-caret-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h"></i>}
        breakClassName={'break-me'}
        pageCount={pagesPublish}
        onPageChange={(page) => {
            setCurrPagePublish(page.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
    /> : null

    const publishedApps = <div>
            {
                dappDataPublish ? dappDataPublish.length > 0 ? dappDataPublish.map(app => {
                    return <Card data={app} key={app.id} isCreator={true} />
                }) : <p>You have not pulished dapp yet</p> : <p>Fetching Data...</p>
            }
        {pulishPagination}
    </div>

    const loginReminder = showLoginReminder ? <div>Please <a href="/login">login</a> to check details.</div> : null;

    return (
        <div className={classes.marketplace}>
            <div className={classes.pageTitle}>My Pubished DApps</div>
            {loginReminder}
            {
                !showLoginReminder ? <div className={classes.container}>
                    {publishedApps}
                </div> : null
            }
        </div>
    )
}

export default MyApps;