import React, { useState, useEffect, useContext } from 'react';
import classes from '../Monitor/Menu/Menu.module.scss';
import { NavLink } from 'react-router-dom';

const Menu = () => {
    return (
        <div className={classes.menu}>
            <div className={classes.title}>Manage Chains</div>
            <div className={classes.icons}>
                <NavLink to="/chain/admin" activeClassName={classes.Active}>
                    <div className={classes.icon}>All Chains</div>
                </NavLink>
            </div>
            <div className={classes.icons}>
                <NavLink to="/chain/activity" activeClassName={classes.Active}>
                    <div className={classes.icon}>Activities</div>
                </NavLink>
            </div>
        </div>
    )
}

export default Menu;