import React, { useContext, useState, useEffect } from 'react';
import classes from './Menu.module.scss';
import { NavLink } from 'react-router-dom';
import { MessageContext } from '../../../context/message-context';
import Badge from 'react-bootstrap/Badge';

const Menu = () => {
    const msgContext = useContext(MessageContext);
    let unreadMsgCount = msgContext && msgContext.unreadMsgCount !== 0 ? <sup title='You have unread messages'><Badge variant="danger">{msgContext.unreadMsgCount}</Badge></sup> : null;
    
    return (
        <div className={classes.menu}>
            <div className={classes.spaceBetween}>
                <div>
                    <NavLink to="/user/profile" activeClassName={classes.Active}>
                        <div className={classes.title}>Profile Settings</div>
                    </NavLink>
                    <NavLink to="/user/account" activeClassName={classes.Active}>
                        <div className={classes.title}>Account</div>
                    </NavLink>
                    <NavLink to="/user/messages" activeClassName={classes.Active}>
                        <div className={classes.title}>Messages{unreadMsgCount}</div>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Menu;