import React, {useState} from 'react';
import classes from './api.module.scss';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const Api = (props) => {
    let inputsMeta = props.data.inputs;
    let accessToken = props.accessToken;
    let chain = props.chain;
    let contract = props.contract;
    let account = props.account;
    let name = props.data.name;

    let dataInputs = [];
    inputsMeta.forEach((input, i) => {
        // dataInputs[input.name] = {
        //     value: 'SOME VALUE',
        //     type: input.type
        // }
        dataInputs.push(`INPUT ${i + 1}`)
    })

    let sampleInput = chain ? {
        access_token: accessToken || 'access token is not ready',
        chain_type: chain.type,
        data: {
            chain_id: chain.chain_id,
            contract_address: contract.contract_address,
            contract_name: contract.contract_name,
            function: name,
            inputs: dataInputs,
            account: account.address,
        }
    } : null

    const [showInput, setShowInput] = useState(false);

    return (
        <div>
            {sampleInput ?
                <div>
                <div className={classes.title} onClick={() => setShowInput(!showInput)}>{name} {
                        showInput ? <i className="fas fa-angle-down"></i> : <i className="fas fa-angle-right"></i>
                    }</div>
                    {
                        showInput ? <pre style={{ overflow: 'hidden' }}>
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="copy-tooltip">
                                Click to Copy!
                        </Tooltip>}
                        ><div className={classes.code} onClick={(e) => {
                            copyToClipboard(JSON.stringify(sampleInput));
                            let ele = e.target;
                            ele.className += ` ${classes.clicked}`;
                            setTimeout(() => { ele.className = classes.code }, 400)
                        }}>{JSON.stringify(sampleInput, null, 4)}</div></OverlayTrigger>
                    </pre> : null
                    }
                </div>
            : null
            }
        </div>
    )
}

function copyToClipboard(secretInfo) {
    var $body = document.getElementsByTagName('body')[0];
    var $tempInput = document.createElement('INPUT');
    $body.appendChild($tempInput);
    $tempInput.setAttribute('value', secretInfo)
    $tempInput.select();
    document.execCommand('copy');
    $body.removeChild($tempInput);
}

export default Api;

