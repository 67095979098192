import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Overview from './Overview';
import Blocks from './Blocks';
import Block from './Block';
import Transactions from './Transactions';
import Transaction from './Transaction';
import Contracts from './Contracts';
import Contract from './Contract';
import Token from './Token';
import Wallet from './Wallet';
import Nodes from './Nodes';
import Menu from './Menu/Menu';
import { MonitorContext } from '../../../context/monitor-context';
import { AuthContext } from '../../../context/auth-context';
import * as Admin from '../../../constant/userAdmin';
import classes from './Monitor.module.scss';
import ethereumLogo from '../../../assets/images/logo_ethereum.png';
import quorumLogo from '../../../assets/images/logo_quorum.png';
import Badge from 'react-bootstrap/Badge';
import * as Common from '../../../common';
import axios from 'axios';
import { checkAuth } from '../../../commonFunc';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import BlocksGif from '../../../assets/images/net.gif';
import MyModal from '../../UI/Modal/Modal';

const Monitor = () => {
    const monitorContext = useContext(MonitorContext);
    const authContext = useContext(AuthContext);
    const [showTerminateConfirm, setShowTerminateConfirm] = useState(false);
    const [showRebootConfirm, setShowRebootConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState({ action_type: '', show: false });

    useEffect(() => {
        if (monitorContext && !monitorContext.chain) {
            monitorContext.setChain(JSON.parse(window.sessionStorage.getItem('selected_chain')))
        }
    }, [monitorContext])

    let chainInfo = null;
    if (monitorContext.chain) {
        let type = monitorContext.chain.type;
        if (monitorContext.chain.type === 'quorum') type = <img src={quorumLogo} height='25' alt="quorum" title="quorum chain" />
        else if (monitorContext.chain.type === 'ethereum') type = <img src={ethereumLogo} height='25' alt="ethereum" title="ethereum chain" />

        let number_of_children = null
        if (Admin.convertRole(authContext.usertype) === "ADMIN" && monitorContext.chain.number_of_children !== null) {
            number_of_children = <span title="number of children" style={{ display: 'inlineBlock' }}><i className="fas fa-child"></i>{monitorContext.chain.number_of_children}</span>
        }

        let permission = monitorContext.chain.permission;
        if (permission === 'public') permission = <Badge pill variant="warning" >Shared {number_of_children}</Badge>
        else if (permission === 'share-private') permission = <Badge pill variant="info" >Shared-Private {number_of_children}</Badge>
        else if (permission === 'basic-private') permission = <Badge pill variant="light" >Basic</Badge>;
        else if (permission === 'private') permission = <Badge pill variant="light" >Standard</Badge>;

        chainInfo = <div className={classes.chainInfoTopBar}>
            <div className={classes.spaceBetween} style={{width: '100%'}}>
                <div>
                    <b>{monitorContext.chain.name}</b> (ID: {monitorContext.chain.chain_id}) <span style={{ margin: '0 1rem' }}>{type}</span>{permission}
                </div>
                <div>
                    <div className={classes.mainBtn} style={{ marginRight: '1rem' }} onClick={() => setShowRebootConfirm(true)}>Reboot</div>
                    <div className={classes.mainBtn} onClick={() => setShowTerminateConfirm(true)}>Terminate</div>
                </div>
            </div>
        </div>
    }

    const rebootChainHandler = () => {
        setShowRebootConfirm(false);
        setIsLoading({ action_type: 'Rebooting the chain', show: true });
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: monitorContext.chain.chain_id,
                action: 'reboot'
            }
        }
        setTimeout(() => {
            window.location.href = "/chain/admin"
        }, 3000)
        axios.post(Common.ENDPOINT + Common.CHAIN_ACTION, data).then(response => {
            // setTimeout(() => {
            //     setIsLoading({ action_type: '', show: false });
            // }, 3000)
            if (response.data.status === '1') {
                // direct to all chains                
            } else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const terminateChainHandler = () => {
        setShowTerminateConfirm(false);
        setIsLoading({ action_type: 'Terminating the chain', show: true });
        setTimeout(() => {
            window.location.href = "/chain/admin"
        }, 3000)
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: monitorContext.chain.chain_id,
                action: 'terminate'
            }
        }
        axios.post(Common.ENDPOINT + Common.CHAIN_ACTION, data).then(response => {
            if (response.data.status === '1') {
                // direct to all chains
            } else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    {/* modal for terminate the chain */ }
    const terminateModal = monitorContext.chain ? <Modal animation={true} show={showTerminateConfirm} onHide={() => { setShowTerminateConfirm(false) }}>
        <Modal.Header closeButton>
            <Modal.Title>Terminate the Chain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Are you sure to terminate <b>{monitorContext.chain.name} (ID: {monitorContext.chain.chain_id})</b> created by <b>{monitorContext.chain.user_email}</b>?</p>
            <p>All the records in the chain will be removed.</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => { setShowTerminateConfirm(false) }}>Cancel</Button>
            <Button variant="primary" onClick={terminateChainHandler}>Terminate the chain!</Button>
        </Modal.Footer>
    </Modal> : null

    {/* modal for reboot the chain */ }
    const rebootModal = monitorContext.chain ? <Modal animation={true} show={showRebootConfirm} onHide={() => { setShowRebootConfirm(false) }}>
        <Modal.Header closeButton>
            <Modal.Title>Reboot the Chain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Are you sure to reboot <b>{monitorContext.chain.name} (ID: {monitorContext.chain.chain_id})</b> created by <b>{monitorContext.chain.user_email}</b>?</p>
            <p>All the records in the chain will be removed.</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => { setShowRebootConfirm(false) }}>Cancel</Button>
            <Button variant="primary" onClick={rebootChainHandler}>Reboot the chain!</Button>
        </Modal.Footer>
    </Modal> : null

    const loadingModal = <MyModal show={isLoading.show}>
        <img src={BlocksGif} width="200" alt="img" />
        <p>{isLoading.action_type}, will direct to "Manage Chains" page...</p>
    </MyModal>

    return (
        <div>
            <Menu />
                {chainInfo}
                <Switch>
                    <Route path='/chain/monitor/overview' exact component={Overview}></Route>
                    <Route path='/chain/monitor/contracts' exact component={Contracts}></Route>
                    <Route path='/chain/monitor/contracts/:contractId' exact component={Contract}></Route>
                    <Route path='/chain/monitor/transactions' exact component={Transactions}></Route>
                    <Route path='/chain/monitor/transactions/:txId' exact component={Transaction}></Route>
                    <Route path='/chain/monitor/blocks' exact component={Blocks}></Route>
                    <Route path='/chain/monitor/blocks/:blockId' component={Block}></Route>
                    <Route path='/chain/monitor/token' exact component={Token}></Route>
                    <Route path='/chain/monitor/wallet' exact component={Wallet}></Route>
                    <Route path='/chain/monitor/nodes' exact component={Nodes}></Route>
                    <Redirect from="/chain/monitor/" to="/chain/monitor/overview" />
                </Switch>
            {terminateModal}
            {rebootModal}
            {loadingModal}
        </div>
    )
}

export default Monitor;