import React, { useState, useEffect, useContext } from 'react';
import classes from './Monitor.module.scss';
import { useParams } from 'react-router-dom';
import { MonitorContext } from '../../../context/monitor-context';
import { AuthContext } from '../../../context/auth-context';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import * as Common from '../../../common';
import { checkAuth } from '../../../commonFunc';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const Block = () => {
    let { blockId } = useParams();
    const authContext = useContext(AuthContext);
    const monitorContext = useContext(MonitorContext);
    const [blockInfo, setBlockInfo] = useState();

    const [txCount, setTxCount] = useState();
    const [contractCount, setContractCount] = useState();
    const [currPage, setCurrPage] = useState(1);
    const [pages, setPages] = useState();
    const [perPage, setPerPage] = useState(10);
    const [selectedTable, setSelectedTable] = useState('Transactions');
    const [txData, setTxData] = useState();
    const [contractData, setContractData] = useState();

    const pagination_options = [10, 25, 50];

    const getBlockInfo = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                id: Number(blockId),
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_GET_BLOCK, data).then(response => {
            if (response.data.status === '1') {
                setBlockInfo(response.data);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getTxs = () => {
        setTxData(null);
        let data = {
            credentials: authContext.credentials,
            data: {
                page: currPage,
                per_page: perPage,
                chain_id: monitorContext.chain.chain_id,
                block_id: Number(blockId)
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_GET_TRANSACTIONS_V2, data).then(response => {
            if (response.data.status === '1') {
                setTxData(response.data.items);
                setTxCount(response.data.total);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getContracts = () => {
        setContractData(null);
        let data = {
            credentials: authContext.credentials,
            data: {
                page: currPage,
                per_page: perPage,
                chain_id: monitorContext.chain.chain_id,
                block_id: Number(blockId)
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_GET_CONTRACTS_V2, data).then(response => {
            if (response.data.status === '1') {
                setContractData(response.data.items);
                setContractCount(response.data.total);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const paginationUpdate = (perPage) => {
        setPerPage(perPage);
        setCurrPage(1);
    }

    useEffect(() => {
        if (monitorContext.chain) {
            getTxs();
            getContracts();
        }
    }, [monitorContext, currPage, perPage])

    useEffect(() => {
        if (monitorContext.chain) getBlockInfo();
    }, [monitorContext])

    useEffect(() => {
        setCurrPage(1);
        if (selectedTable === 'Contracts') setPages(Math.ceil(parseFloat(contractCount) / perPage))
        else if (selectedTable === 'Transactions') setPages(Math.ceil(parseFloat(txCount) / perPage))
    }, [selectedTable])

    const backToBlocks = <Link to="/chain/monitor/blocks"><div className={classes.actionBtn} style={{ fontSize: '1.4rem' }}>&lt; Back to Blocks</div></Link>

    const blockDetails = blockInfo ? <div className={classes.container}>
        <div className={classes.blockDetails}>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Block</div></Col>
                <Col xs={8} md={9}><div><b>#{blockInfo.block_number}</b> {blockInfo.block_hash}<i className="far fa-copy" title="copy block hash to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(blockInfo.block_hash) }}></i></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Time</div></Col>
                <Col xs={8} md={9}><div>{getLocalTime(blockInfo.block_time)}</div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Mined By</div></Col>
                <Col xs={8} md={9}><div>{trimHash(blockInfo.mined_by)}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(blockInfo.mined_by) }}></i></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Reward</div></Col>
                <Col xs={8} md={9}><div><b>{blockInfo.reward}</b></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Difficulty</div></Col>
                <Col xs={8} md={9}><div><b>{blockInfo.difficulty}</b></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Size</div></Col>
                <Col xs={8} md={9}><div><b>{blockInfo.size} bytes</b></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Gas Used</div></Col>
                <Col xs={8} md={9}><div><b>{blockInfo.gas}</b></div></Col>
            </Row>
            <Row>
                <Col xs={4} md={3} lg={2}><div>Nonce</div></Col>
                <Col xs={8} md={9}><div>{blockInfo.nonce}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(blockInfo.nonce) }}></i></div></Col>
            </Row>
        </div>
    </div> : <div className={classes.container}>Fetching Data...</div>;

    const paginationOptions = pagination_options.map((number, index) => {
        let classes = "option"
        if (number === perPage) classes = "option selected";
        return <span className={classes} key={index} onClick={() => { paginationUpdate(number) }}>{number}</span>
    })

    const tablePagination = pages > 0 ? <ReactPaginate
        forcePage={currPage - 1}
        previousLabel={<i className="fas fa-caret-left"></i>}
        nextLabel={<i className="fas fa-caret-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h"></i>}
        breakClassName={'break-me'}
        pageCount={pages}
        onPageChange={(page) => {
            setCurrPage(page.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
    /> : null

    const txOrBlockBtns = ['Transactions', 'Contracts'].map(type => {
        let _classes = type === selectedTable ? [classes.mainBtn, classes.selected].join(' ') : classes.mainBtn
        return <div className={_classes} key={type} style={{ marginRight: '1rem', padding: '0.3rem 2rem' }} onClick={() => setSelectedTable(type)}><b>{type === 'Contracts' ? contractCount : txCount}</b> {type}</div>
    })

    const transactionTableRows = txData ? txData.length > 0 ? txData.map((tx, i) => {
        let txType = tx.tx_type;
        if (txType === 'invoke') {
            let inputs = ''
            tx.contract.inputs.forEach(input => {
                inputs += (input + ', ')
            })
            if (inputs !== '') inputs = inputs.substring(0, inputs.length - 2);
            inputs = '(' + inputs + ')'
            txType = <OverlayTrigger
                placement={"right"}
                overlay={
                    <Tooltip style={{ fontSize: '12px' }}>
                        Invoked function - <b>{tx.contract.function}{inputs}</b> from contract - <b>{tx.contract.contract_name}</b>
                    </Tooltip>
                }
            ><Badge pill variant="info" style={{ color: 'white' }}>Invoke<i className="fas fa-info-circle" style={{ color: 'white', marginRight: '0' }}></i></Badge></OverlayTrigger>
        } else if (txType === 'deploy') {
            let isPrivate = tx.contract.is_private ? <i className="fas fa-lock" title="private contract" style={{ color: 'white' }}></i> : null;
            txType = <OverlayTrigger
                placement={"right"}
                overlay={
                    <Tooltip style={{ fontSize: '12px' }}>
                        Deplyed <b>{tx.contract.is_private ? 'Private ' : 'Public '}</b>Contract - <b>{tx.contract.contract_name}</b>
                    </Tooltip>
                }
            >
                <Badge pill variant="secondary" style={{ color: 'white' }}>Deploy{isPrivate}<i className="fas fa-info-circle" style={{ color: 'white', marginRight: '0' }}></i></Badge></OverlayTrigger>
        } else if (txType === 'transfer') {
            txType = <Badge pill variant="warning" style={{ color: 'white' }}>Transfer</Badge>
        }
        return <tr key={i}>
            <td><Link to={`/chain/monitor/transactions/${tx.id}`}><b>Tx</b> {trimHash(tx.tx_hash)}</Link><i className="far fa-copy" title="copy hash to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(tx.tx_hash) }}></i></td>
            <td><b>From</b> {trimHash(tx.from)}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(tx.from) }}></i></td>
            <td><b>To</b> {trimHash(tx.to)}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(tx.to) }}></i></td>
            <td>{txType}</td>
            <td><span title={getLocalTime(tx.tx_time)}>{getLocalTime(tx.tx_time)}</span></td>
        </tr>
    }) : <tr><td colSpan="5">No Transaction Data available</td></tr> : <tr><td colSpan="5">Fetching Data...</td></tr>;

    const contractTableRows = contractData ? contractData.length > 0 ? contractData.map((contract, i) => {
        return <tr key={i}>
            <td><Link to={`/chain/monitor/contracts/${contract.id}`}><b>{contract.contract_name}</b></Link> ({trimHash(contract.contract_hash)}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(contract.contract_hash) }}></i>)</td>
            <td><b>Tx</b> {trimHash(contract.tx_hash)}<i className="far fa-copy" title="copy hash to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(contract.tx_hash) }}></i></td>
            <td><span title={getLocalTime(contract.time)}>{getLocalTime(contract.time)}</span></td>
        </tr>
    }) : <tr><td colSpan="3">No Contract Data available</td></tr> : <tr><td colSpan="3">Fetching Data...</td></tr>;

    const table = <Table hover className={classes.table}>
        <tbody>
            {selectedTable === 'Transactions' ? transactionTableRows : selectedTable === 'Contracts' ? contractTableRows : null}
        </tbody>
    </Table>

    const txOrContractDetails = <div>
        <div className={classes.spaceBetween}>
            <div>{txOrBlockBtns}</div>
            <div className="pagination_menu" style={{ fontSize: '1.2rem' }}>show {paginationOptions} items</div>
        </div>
        {table}
        {tablePagination}
    </div>
    return (
        <div className={classes.monitor}>
            <div className={classes.subTitle}>Block</div>
            {backToBlocks}
            {blockDetails}
            {txOrContractDetails}
        </div>
    )
}

function trimHash(hash) {
    if (hash === '') return 'Not Available'
    else return hash.substring(0, 6) + '...' + hash.substring(hash.length - 6, hash.length)
}

function copyToClipboard(secretInfo) {
    var $body = document.getElementsByTagName('body')[0];
    var $tempInput = document.createElement('INPUT');
    $body.appendChild($tempInput);
    $tempInput.setAttribute('value', secretInfo)
    $tempInput.select();
    document.execCommand('copy');
    $body.removeChild($tempInput);
}

function getLocalTime(time) {
    let utc_time = (new Date(time)).toString() + ' UTC';
    let localtime = new Date(utc_time).toLocaleString();
    return localtime;
}

export default Block;