import React, { useState, useEffect, useContext } from 'react';
import classes from './Monitor.module.scss';
import { AuthContext } from '../../../context/auth-context';
import { MonitorContext } from '../../../context/monitor-context';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as Common from '../../../common';
import { checkAuth } from '../../../commonFunc';
import Spinner from 'react-bootstrap/Spinner';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'

const Blocks = () => {
    const authContext = useContext(AuthContext);
    const monitorContext = useContext(MonitorContext);
    const [searchInput, setSearchInput] = useState();
    const [currPage, setCurrPage] = useState(1);
    const [pages, setPages] = useState();
    const [perPage, setPerPage] = useState(10);
    const [blockData, setBlockData] = useState();
    const [refreshing, setRefreshing] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const pagination_options = [10, 25, 50];

    const getBlocks = (searchInput) => {
        setBlockData(null);
        setRefreshing(true);
        let data = {
            credentials: authContext.credentials,
            data: {
                page: currPage,
                per_page: perPage,
                chain_id: monitorContext.chain.chain_id,
                search_term: searchInput
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_GET_BLOCKS_V2, data).then(response => {
            setRefreshing(false);
            if (response.data.status === '1') {
                setBlockData(response.data.items);
                setPages(Math.ceil(parseFloat(response.data.total) / perPage))
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    useEffect(() => {
        if (monitorContext.chain) {
            getBlocks();
        }
    }, [monitorContext, currPage, perPage])

    const isInputValid = (input) => {
        var format = /^[a-zA-Z0-9]+$/
        return format.test(input)
    }

    let blockSearch = <div className={classes.search}><InputGroup>
        <FormControl
            placeholder="Search block by block number or block hash"
            aria-label="Search block by block number or block hash"
            aria-describedby="search-addon"
            onChange={(e) => {
                setSearchInput(e.target.value);
                if (!isInputValid(e.target.value)) {
                    setShowFeedback(true);
                } else setShowFeedback(false);
            }}
            maxLength="66"
        />
        {
            showFeedback ? <InputGroup.Append>
                <div className={[classes.mainBtn, classes.disabled].join(' ')} ><i className="fas fa-search" style={{ marginLeft: 0 }}></i>Search</div>
            </InputGroup.Append> :
                <InputGroup.Append>
                    <div className={classes.mainBtn} onClick={() => { getBlocks(searchInput) }}><i className="fas fa-search" style={{ marginLeft: 0 }}></i>Search</div>
                </InputGroup.Append>
        }
    </InputGroup>
        {showFeedback && searchInput !== '' ? <small>Please provide a valid block number or hash</small> : null}
        <div><span className={classes.actionBtn} onClick={() => { getBlocks() }}>Show All Blocks</span></div>
    </div>

    let tableRows = blockData ? blockData.length > 0 ? blockData.map((block, i) => {
        let localTime = getLocalTime(block.block_time)
        return <tr key={i}>
            <td><Link to={`blocks/${block.id}`}><b>Block # {block.block_number}</b></Link> ({trimHash(block.block_hash)}<i className="far fa-copy" title="copy block hash to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(block.block_hash) }}></i>)</td>
            <td><Link to={`blocks/${block.id}`}><b>{block.tx_count}</b> Transactions</Link> <Link to={`blocks/${block.id}`}><b>{block.contract_count}</b> Contracts</Link></td>
            <td><span title={localTime}>{localTime}</span></td>
        </tr>
    }) : <tr><td colSpan="3">No Block Data available</td></tr> : <tr><td colSpan="3">Fetching Data...</td></tr>

    let blocksTable = <Table hover className={classes.table}>
        <tbody>
            {tableRows}
        </tbody>
    </Table>

    const paginationUpdate = (perPage) => {
        setPerPage(perPage);
        setCurrPage(1);
    }

    const paginationOptions = pagination_options.map((number, index) => {
        let classes = "option"
        if (number === perPage) classes = "option selected";
        return <span className={classes} key={index} onClick={() => { paginationUpdate(number) }}>{number}</span>
    })

    const tablePagination = pages > 0 ? <ReactPaginate
        forcePage={currPage - 1}
        previousLabel={<i className="fas fa-caret-left"></i>}
        nextLabel={<i className="fas fa-caret-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h"></i>}
        breakClassName={'break-me'}
        pageCount={pages}
        onPageChange={(page) => {
            setCurrPage(page.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
    /> : null

    let blocksTableSession = <div>
        <div className={classes.spaceBetween}>
            <div className={classes.thirdTitle}>Lastest Blocks</div>
            <div>
                <span className="pagination_menu" style={{ fontSize: '1.2rem', display: 'inline-block' }}>show {paginationOptions} items</span>{' '}
                {
                    !refreshing ? <span className={classes.refreshBtn} title="refresh data"><i className="fas fa-sync-alt" onClick={() => getBlocks()}></i></span> :
                        <Spinner animation="border" variant="secondary" />
                }
            </div>
        </div>
        {blocksTable}
        {tablePagination}
    </div>

    return (
        <div className={classes.monitor}>
            <div className={classes.subTitle}>Blocks<OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={<Popover id="blocks-popover">
              <Popover.Content>
              <p>Transaction data is permanently recorded in files called blocks. Each block contains, among other things, the current time, a record of some or all recent transactions, and a reference to the block that came immediately before it.</p>
              </Popover.Content>
          </Popover>}
          ><sup><i class="fas fa-info-circle"></i></sup></OverlayTrigger></div>
            {/* {chainDesc} */}
            {monitorContext.chain ?
                <div>
                    {blockSearch}
                    {blocksTableSession}
                </div> :
                <div>Click <Link to="/chain">here</Link> to select a chain first</div>}

        </div>
    )
}

function getLocalTime(time) {
    let utc_time = (new Date(time)).toString() + ' UTC';
    let localtime = new Date(utc_time).toLocaleString();
    return localtime;
}

function trimHash(hash) {
    return hash.substring(0, 6) + '...' + hash.substring(hash.length - 6, hash.length)
}

function copyToClipboard(secretInfo) {
    var $body = document.getElementsByTagName('body')[0];
    var $tempInput = document.createElement('INPUT');
    $body.appendChild($tempInput);
    $tempInput.setAttribute('value', secretInfo)
    $tempInput.select();
    document.execCommand('copy');
    $body.removeChild($tempInput);
}

export default Blocks;