import React, { useContext, useState, useEffect } from 'react';
import classes from './Messages.module.scss';
import { AuthContext } from '../../../../context/auth-context';
import * as Admin from '../../../../constant/userAdmin';
import Message from './Message/Message';
import axios from 'axios';
import * as Common from "../../../../common";
import { checkAuth } from "../../../../commonFunc";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import ReactPaginate from 'react-paginate';
import { MessageContext } from '../../../../context/message-context';

const Messages = () => {

	const authContext = useContext(AuthContext);
	const msgContext = useContext(MessageContext);
	const ADMIN = 'ADMIN';
	const tableTypes = ['Inbox', 'Sent'];
	const perPage = 10;
	const [inboxCurrPage, setInboxCurrPage] = useState(1);
	const [inboxPages, setInboxPages] = useState();
	const [totalMsgCount, setTotalMsgCount] = useState();
	const [tableTabs, setTableTabs] = useState();
	const [table, setTable] = useState();
	const [reloadTrigger, setReloadTrigger] = useState();
	const [tick, setTick] = useState(true);
	const [inBoxData, setInBoxData] = useState();
	const [sentBoxCurrPage, setSentBoxCurrPage] = useState(1);
	const [sentPages, setSentPages] = useState();
	const [sentBoxData, setSentBoxData] = useState();
	const [selectedTableName, setSelectedTableName] = useState('Inbox');
	const [showNewMsgForm, setShowNewMsgForm] = useState(false);
	const [preUnreadMsg, setPreUnreadMsg] = useState(msgContext.unreadMsgCount);
	const [newMessage, setNewMessage] = useState({
		message_content: '',
		message_to: '',
		message_type: 'sent',
		message_title: '',
		message_reply_status: 1
	})
	const [validated, setValidated] = useState(false);

	let reloadInterval;
	useEffect(() => {
		if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
			getInboxMessages();
		}
	}, [inboxCurrPage])

	useEffect(() => {
		if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
			getSentMessages();
		}
	}, [sentBoxCurrPage])

	useEffect(() => {
		if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
			getUnreadMsgCount(true);
			getInboxMessages();
			getSentMessages();
			setReloadTrigger(true);
		}
	}, [authContext.credentials])

	useEffect(() => {
		if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
			if (reloadTrigger) {
				reloadInterval = setInterval(getUnreadMsgCount, 15000);
				let timeout;
				document.onmousemove = () => {
					clearTimeout(timeout);
					setReloadTrigger(true);
					timeout = setTimeout(() => {
						clearInterval(reloadInterval);
						setReloadTrigger(false);
					}, 15000)
				}
			}
		} else {
			clearInterval(reloadInterval);
		}
		return (() => {
			clearInterval(reloadInterval);
		})
	}, [reloadTrigger])

	const getInboxMessages = () => {
		const data = {
			credentials: authContext.credentials,
			data: {
				per_page: perPage,
				page: inboxCurrPage
			}
		}
		axios.post(Common.ENDPOINT + Common.GET_ALL_MESSAGES, data).then(response => {
			if (response.data.status === '1') {
				setInBoxData(response.data.message_meta)
				setInboxPages(Math.ceil(parseFloat(response.data.index) / 10))
			}
			else {
				if (checkAuth(response.data, authContext.logout, authContext.maintenance)) console.log(response.data.message);
			}
		})
	}

	const getSentMessages = () => {
		const data = {
			credentials: authContext.credentials,
			data: {
				per_page: perPage,
				page: sentBoxCurrPage
			}
		}
		axios.post(Common.ENDPOINT + Common.GET_SENT_MESSAGES, data).then(response => {
			if (response.data.status === '1') {
				setSentBoxData(response.data.message_meta)
				setSentPages(Math.ceil(parseFloat(response.data.index) / 10))
			}
			else {
				if (checkAuth(response.data, authContext.logout, authContext.maintenance)) console.log(response.data.message);
			}
		})
	}

	const deleteMsgHandler = (messageId, isSentMsg) => {
		const data = {
			credentials: authContext.credentials,
			data: {
				message_id: messageId
			}
		}
		const DELETE_MESSAGE = isSentMsg ? Common.DELETE_SENT_MESSAGE : Common.DELETE_RECEIVE_MESSAGE
		axios.post(Common.ENDPOINT + DELETE_MESSAGE, data).then(response => {
			if (response.data.status === '1') {
				// if it delete successfully, reload all messages, update total count
				if (isSentMsg) {
					getSentMessages();
				} else {
					getInboxMessages();
				}
			}
			else {
				if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message);
			}
		})
	}

	const isUnread = (messageId) => {
		let isUnread = false;
		inBoxData.forEach(message => {
			if (message.message_id === messageId && message.message_read_status === 0) {
				isUnread = true;
				return;
			}
		})
		return isUnread;
	}

	const getMsgContent = (messageId) => {
		const data = {
			credentials: authContext.credentials,
			data: {
				message_id: messageId
			}
		}
		axios.post(Common.ENDPOINT + Common.GET_MESSAGE_CONTENT, data).then(response => {
			if (response.data.status === '1') {
				updateMessageContent(messageId, response.data.message_meta)
				// mark as read
				if (isUnread(messageId)) {
					markReadMsgHandler(messageId);
				}
			}
			else {
				if (checkAuth(response.data, authContext.logout, authContext.maintenance)) {
					console.log(response.data.message);
					updateMessageContent(messageId, 'Cannot get message, please try it later or contact us at contact@blocktest.net')
				}
			}
		})
	}

	const markReadMsgHandler = (messageId) => {
		const data = {
			credentials: authContext.credentials,
			data: {
				message_id: messageId
			}
		}
		axios.post(Common.ENDPOINT + Common.MARK_MESSAGE_READ, data).then(response => {
			if (response.data.status === '1') {
				msgContext.setUnreadMsgCount(msgContext.unreadMsgCount - 1);
				updateMessageReadStatus(messageId);
			}
			else {
				if (checkAuth(response.data, authContext.logout, authContext.maintenance)) console.log(response.data.message);
			}
		})
	}

	const getUnreadMsgCount = (firstTime) => {
		// call api to get the unread count
		const data = {
			credentials: authContext.credentials,
			data: {}
		}
		axios.post(Common.ENDPOINT + Common.UNREAD_MESSAGE_COUNT, data).then(response => {
			if (response.data.status === '1') {
				msgContext.setUnreadMsgCount(response.data.unread_message_count)
			}
			else {
				if (checkAuth(response.data, authContext.logout, authContext.maintenance)) console.log(response.data.message);
			}
		})
	}

	const updateMessageContent = (id, content) => {
		let updatedMessages = [...inBoxData];

		updatedMessages.forEach((message) => {
			if (message.message_id === id) {
				message.message_content = content;
			}
		})
		setInBoxData(updatedMessages);
	}

	const updateMessageReadStatus = (id) => {
		let updatedMessages = [...inBoxData];
		updatedMessages.forEach((message) => {
			if (message.message_id === id) {
				message.message_read_status = 1;
			}
		})
		setInBoxData(updatedMessages);
	}

	useEffect(() => {
		// only call get all messages when unread message count increase
		if (msgContext.unreadMsgCount) {
			if (preUnreadMsg < msgContext.unreadMsgCount) {
				getInboxMessages();
				msgContext.setShowMessageNotif(true);
			}
		}
		setPreUnreadMsg(msgContext.unreadMsgCount);

	}, [msgContext.unreadMsgCount]);

	useEffect(() => {
		// force the table to rerender
		let timeout;
		timeout = setTimeout(() => {
			setTick(!tick);
		}, 60000);
		return (() => {
			clearTimeout(timeout);
		})
	}, [tick])

	const sendNewMessageHandler = (event) => {
		const form = event.currentTarget;
		setValidated(true);
		event.preventDefault();
		if (form.checkValidity() === false) {
			event.stopPropagation();
		} else {
			const isEmail = (email) => {
				var re = /\S+@\S+\.\S+/;
				return re.test(email);
			}
			if (newMessage.message_to !== 'ALLUSERS' && !isEmail(newMessage.message_to)) alert('Message must have at least one recipient');
			let data = {
				credentials: authContext.credentials,
				data: newMessage
			}
			axios.post(Common.ENDPOINT + Common.SEND_MESSAGE, data).then(response => {
				setValidated(false);
				if (response.data.status === '1') {
					alert('Message sent!');
					setNewMessage({
						message_content: '',
						message_to: '',
						message_type: 'sent',
						message_title: '',
						message_reply_status: 1
					})
					setShowNewMsgForm(false);
				}
				else {
					if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message);
				}
			})
		}
	}

	const refreshInboxMessages = () => {
		getUnreadMsgCount();
		getInboxMessages();
	}

	const inBoxTableRows = inBoxData ?
		inBoxData.length === 0 ?
			<p>Your inbox is empty</p> :
			inBoxData.map(message => {
				return <Message data={message} key={message.message_id} markRead={markReadMsgHandler} delete={deleteMsgHandler} reloadSentData={getSentMessages} getContent={getMsgContent} />
			}) : <p>Loading Messages...</p>;

	const inboxPagination = inboxPages ? <ReactPaginate
		forcePage={inboxCurrPage - 1}
		previousLabel={<i className="fas fa-caret-left"></i>}
		nextLabel={<i className="fas fa-caret-right"></i>}
		breakLabel={<i className="fas fa-ellipsis-h"></i>}
		breakClassName={'break-me'}
		pageCount={inboxPages}
		onPageChange={(page) => {
			setInboxCurrPage(page.selected + 1);
		}}
		pageRangeDisplayed={3}
		marginPagesDisplayed={2}
		containerClassName={'pagination'}
		subContainerClassName={'pages pagination'}
		activeClassName={'active'}
	/> : null;

	const refreshBtn = <span className={classes.refresh}><i className="fas fa-sync-alt" title="refresh messages" onClick={() => {
		if (selectedTableName === 'Inbox') {
			refreshInboxMessages();
		} else {
			getSentMessages();
		}
	}
	}></i></span>
	const inBoxTable = <div>
		{inBoxTableRows}
		{inboxPagination}
	</div>

	const sentBoxTableRows = sentBoxData ?
		sentBoxData.length === 0 ?
			<p>No sent messages exists</p> :
			sentBoxData.map((message, i) => {
				return <Message data={message} key={i} sentBox={true} delete={deleteMsgHandler} reloadSentData={getSentMessages} />
			}) : <p>Loading Messages...</p>;

	const sentPagination = sentPages ? <ReactPaginate
		forcePage={sentBoxCurrPage - 1}
		previousLabel={<i className="fas fa-caret-left"></i>}
		nextLabel={<i className="fas fa-caret-right"></i>}
		breakLabel={<i className="fas fa-ellipsis-h"></i>}
		breakClassName={'break-me'}
		pageCount={sentPages}
		onPageChange={(page) => {
			setSentBoxCurrPage(page.selected + 1);
		}}
		pageRangeDisplayed={3}
		marginPagesDisplayed={2}
		containerClassName={'pagination'}
		subContainerClassName={'pages pagination'}
		activeClassName={'active'}
	/> : null;

	const sentBoxTable = <div>
		{sentBoxTableRows}
		{sentPagination}
	</div>

	// SELECT TABLE
	const switchTable = (selectedTableName) => {
		switch (selectedTableName) {
			case 'Inbox': {
				return inBoxTable;
			}
			case 'Sent': {
				return sentBoxTable;
			}
			default:
				return null;
		}
	}

	// switch table when clicking table tabs
	useEffect(() => {
		let newTableTabs = tableTypes.map((tableName) => {
			let className = [classes.btSecondary, classes.switchBtn].join(' ');
			if (selectedTableName === tableName) {
				className = [classes.btSecondary, classes.switchBtn, classes.active].join(' ');
			}
			let icon = tableName === 'Inbox' ? <i className="fas fa-inbox"></i> : <i className="fas fa-paper-plane"></i>
			return <div className={className} key={tableName} onClick={() => {
				setSelectedTableName(tableName)
			}}>{icon}{tableName}</div>
		})

		setTableTabs(newTableTabs);
		setTable(switchTable(selectedTableName));
	}, [selectedTableName, inBoxData, sentBoxData, inboxPagination, sentPagination, tick]);

	const newMsgBtn = authContext && Admin.convertRole(authContext.usertype) === ADMIN ?
		<div className={classes.btSecondary} onClick={() => setShowNewMsgForm(true)}><i className="fas fa-plus-circle"></i> New Message</div>
		: null;

	const messageToInput = authContext && Admin.convertRole(authContext.usertype) === ADMIN && authContext.roles.includes('broadcast') ?
		<Form.Group controlId="messageToEmail">
			<Form.Control type="text" placeholder="To" value={newMessage.message_to || ''} onChange={(event) => setNewMessage({ ...newMessage, message_to: event.target.value, message_type: 'sent' })} required />
			<Form.Control.Feedback type="invalid" >
				Please provide a valid email.
	</Form.Control.Feedback>
			<span className={classes.toAllBtn} title="send to all users" onClick={() => {
				setNewMessage({ ...newMessage, message_to: 'ALLUSERS', message_type: 'system' })
			}}>All USERS</span>
		</Form.Group> : <Form.Group controlId="messageToEmail">
			<Form.Control type="email" placeholder="To" value={newMessage.message_to || ''} onChange={(event) => setNewMessage({ ...newMessage, message_to: event.target.value })} required />
			<Form.Control.Feedback type="invalid" >
				Please provide a valid email.
		</Form.Control.Feedback>
		</Form.Group>

	const newMsgForm = <Form noValidate
		validated={validated}
		onSubmit={e => sendNewMessageHandler(e)}>
		{messageToInput}
		<Form.Group controlId="messageToTitle">
			<Form.Control type="text" placeholder="Title" value={newMessage.message_title || ''} onChange={(event) => setNewMessage({ ...newMessage, message_title: event.target.value })} required />
			<Form.Control.Feedback type="invalid" >
				Please provide a title.
			</Form.Control.Feedback>
		</Form.Group>
		<Form.Group controlId="messageToContent">
			<Form.Control as="textarea" rows="4" style={{ fontSize: '1.4rem' }} placeholder="Compose your message here" value={newMessage.message_content || ''} onChange={(event) => setNewMessage({ ...newMessage, message_content: event.target.value })} required />
			<Form.Control.Feedback type="invalid" >
				Please add the message content.
			</Form.Control.Feedback>
		</Form.Group>
		<Form.Group controlId="messageReplable">
			<Form.Check type="checkbox" label=" Replyable" checked={newMessage.message_reply_status === 1}
				onChange={() => {
					if (newMessage.message_reply_status === 1) {
						setNewMessage({ ...newMessage, message_reply_status: 0 })
					} else {
						setNewMessage({ ...newMessage, message_reply_status: 1 })
					}
				}} />
		</Form.Group>
		<div className={classes.buttonRow}>
			<Button className={classes.submitBtn} type="submit" >Send</Button>
			<Button className={classes.cancelBtn} onClick={() => { setShowNewMsgForm(false) }}>Cancel</Button>
		</div>
	</Form>;

	const newMsgModal = <Modal animation={true} show={showNewMsgForm} onHide={() => { setShowNewMsgForm(false) }}>
		<Modal.Header closeButton>
			<Modal.Title>New Message</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			{newMsgForm}
		</Modal.Body>
	</Modal>

	return (
		<div className={classes.Messages}>
			<div className={classes.spaceBetween} style={{margin: '2rem 0'}}>
				<div>{tableTabs}
				{/* {refreshBtn} */}
				</div>
				{newMsgBtn}
			</div>
			{table}
			{newMsgModal}
		</div>
	)
}

export default Messages;