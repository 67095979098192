import React, { useState, useEffect, useContext } from 'react';
import classes from './Console.module.scss';
import ethereumLogo from '../../../assets/images/logo_ethereum.png';
import quorumLogo from '../../../assets/images/logo_quorum.png';
import Badge from 'react-bootstrap/Badge';
import * as Admin from '../../../constant/userAdmin';
import { AuthContext } from '../../../context/auth-context';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import axios from 'axios';
import * as Common from '../../../common';
import { checkAuth } from '../../../commonFunc';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import FuncRow from './FuncRow';
import Web3 from 'web3';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Popover from 'react-bootstrap/Popover';
import Api from './api';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { List, arrayMove } from 'react-movable';
import Icon from '../../UI/Icon/icon';
import ImageUploader from "react-images-upload";
import imageHolder from '../../../assets/images/blockchain.png';
import { Link } from 'react-router-dom';

const Console = () => {
    const authContext = useContext(AuthContext);
    const [showLoginReminder, setShowLoginReminder] = useState();
    const [showSelectContract, setShowSelectContract] = useState(true);
    const [displaySession, setDisplaySession] = useState('Web App');
    const [chainList, setChainList] = useState();
    const [selectedChain, setSelectedChain] = useState()
    const [contracts, setContracts] = useState();
    const [selectedContract, setSelectedContract] = useState();
    const [wallets, setWallets] = useState();
    const [balance, setBalance] = useState();
    const [selectedWallet, setSelectedWallet] = useState()
    const [showCreateAccount, setShowCreateAccount] = useState(false);
    const [accountNickName, setAccountNickName] = useState();
    const [accessToken, setAccessToken] = useState();
    const [isFreshing, setIsFreshing] = useState(false);
    const [isLoading, setIsLaoding] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [selectedEthChain, setSelectedEthChain] = useState(); // chain id
    const ethChains = new Map([[1, 'Mainnet'], [3, 'Ropsten'], [4, 'Rinkeby'], [5, 'Goerli'], [42, 'Kovan']]);
    const [web3, setWeb3] = useState();
    const [showRefreshTokenModal, setShowRefreshTokenModal] = useState(false);

    const [preChainId, setPreChainId] = useState();
    const [preChainType, setPreChainType] = useState();
    const [preContractId, setPreContractId] = useState();

    const [dappStatus, setDappStatus] = useState('unshared');
    const [tempStatus, setTempStatus] = useState('unshared');
    const [dappName, setDappName] = useState('');
    const [tempName, setTempName] = useState('');
    const [showPublishConfirm, setShowPublishConfirm] = useState(false);
    const [showPrivateConfirm, setShowPrivateConfirm] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [urlId, setUrlId] = useState();
    const [updatingStatus, setUpdatingStatus] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [showEditContractDescModal, setShowEditContractDescModal] = useState(false);
    const [dappDesc, setDappDesc] = useState();

    const [transactSorting, setTransactSorting] = useState(false);
    const [callSorting, setCallSorting] = useState(false);
    const [selectedContractIndex, setSelectedContractIndex] = useState();

    const [showUploadIconsModal, setShowUploadIconsModal] = useState(false);
    const [showUploader, setShowUploader] = useState(true);
    const [icons, setIcons] = useState();
    const [newIcons, setNewIcons] = useState();
    const [showUpdateIcon, setShowUpdateIcon] = useState(false);
    const [dappIcon, setDappIcon] = useState();
    const [newDappName, setNewDappName] = useState();
    const [editOn, setEditOn] = useState(false);

    useEffect(() => {
        if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
            setShowLoginReminder(false);
            getPreviousSelectedContract();
            getChains();
        } else { setShowLoginReminder(true); }
    }, [authContext])

    const selectedEthChainHandler = () => {
        setSelectedChain(null);
        setSelectedContract(null);
        setBalance(null);
        setWeb3(getBrowserWeb3());
    }

    const getPreviousSelectedContract = () => {
        if (window.sessionStorage.getItem('_console_selected_info')) {
            let contract_info = JSON.parse(window.sessionStorage.getItem('_console_selected_info'))
            if (contract_info.chain_type && contract_info.contract_id && contract_info.chain_id) {
                // chain_type: 'eth-official' or 'BT'
                // select chain - use chain id to select the listed chain
                // select contract - when contract loaded, use contract_id to select the listed contract
                setPreChainId(contract_info.chain_id);
                setPreChainType(contract_info.chain_type);
                setPreContractId(contract_info.contract_id);
                if (contract_info.chain_type === 'eth-official') {
                    selectedEthChainHandler();
                    setSelectedEthChain(contract_info.chain_id);
                }
            }
        }
    }

    const selectChainById = (id) => {
        if (!id) id = preChainId;
        if (id) {
            chainList.forEach(chain => {
                if (chain.chain_id === id) setSelectedChain(chain)
            })
        }
    }

    const selectContractById = (id) => {
        if (!id) id = preContractId;
        if (id && ((preChainType === 'BT' && selectedChain && preChainId === selectedChain.chain_id) || (preChainType === 'eth-official' && preChainId === selectedEthChain))) {
            contracts.forEach((contract, i) => {
                if (contract.contract_id === id) {
                    setSelectedContract(contract);
                    setSelectedContractIndex(i);
                }
            })
        }
    }

    const isInputValid = (input) => {
        var format = /^[a-zA-Z0-9-._]+$/
        return format.test(input)
    }

    useEffect(() => {
        if (web3) {
            let account = web3.eth.defaultAccount;
            web3.eth.handleRevert = true;
            if (!account) {
                web3.eth.getAccounts().then(accounts => {
                    if (accounts && accounts.length > 0) {
                        setSelectedWallet(accounts[0])
                    }
                })
            } else setSelectedWallet(account);
        } else setSelectedWallet(null);
    }, [web3])

    useEffect(() => {
        // setSelectedChain(null);
        // setSelectedContract(null);
        // setBalance(null);
        if (selectedEthChain) {
            getContractsEthOfficial();
        } else {
            // setWeb3(null);
        }
    }, [selectedEthChain])

    const getBrowserWeb3 = () => {
        if (window.web3) {
            window.web3 = new Web3(Web3.givenProvider);
            window.web3.eth.net.getId().then(id => {
                setSelectedEthChain(id);
            });
            window.ethereum.enable()
            return window.web3
        } else {
            alert('Please install metamask fisrt')
            return null
        }
    };

    const getChains = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                op: 'active'
            }
        }
        axios.post(Common.ENDPOINT + Common.CHAIN_CHAIN_LIST, data).then(response => {
            if (response.data.status === '1') {
                setChainList(response.data.chains);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    useEffect(() => {
        if (chainList && (preChainType === 'BT')) {
            selectChainById();
        }
    }, [chainList])

    useEffect(() => {
        if (contracts && preContractId) {
            selectContractById();
        }
    }, [contracts])

    const getContracts = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: selectedChain.chain_id,
                per_page: 200,
                current_page: 1
            }
        }
        axios.post(Common.ENDPOINT + 'bdaas/' + selectedChain.type + '/' + Common.GET_CONSOLE_CONTRACTS, data).then(response => {
            if (response.data.status === '1') {
                setContracts(response.data.contracts)
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getContractsEthOfficial = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: selectedEthChain,
                per_page: 200,
                current_page: 1
            }
        }
        axios.post(Common.ENDPOINT + 'bdaas/ethereum/' + Common.GET_CONSOLE_CONTRACTS, data).then(response => {
            if (response.data.status === '1') {
                setContracts(response.data.contracts)
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getAccounts = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: selectedChain.chain_id,
            }
        }
        axios.post(Common.ENDPOINT + 'bdaas/' + selectedChain.type + '/get_accounts', data).then(response => {
            if (response.data.status === '1') {
                setWallets(response.data.accounts)
                if (response.data.accounts.length > 0) {
                    setSelectedWallet(response.data.accounts[0]);
                }
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getBalance = () => {
        if (!selectedWallet.address) return;
        let data = {
            credentials: authContext.credentials,
            data: {
                account_address: selectedWallet.address,
            }
        }
        axios.post(Common.ENDPOINT + 'bdaas/ethereum/get_balance', data).then(response => {
            if (response.data.status === '1') {
                setBalance(response.data.account_balance)
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const loadBalanceHandler = () => {
        setIsLaoding(true);
        let data = {
            credentials: authContext.credentials,
            data: {
                account_address: selectedWallet.address,
            }
        }
        axios.post(Common.ENDPOINT + 'bdaas/ethereum/transfer_ether', data).then(response => {
            setIsLaoding(false);
            if (response.data.status === '1') {
                getBalance();
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const createAccountHandler = () => {
        if (!accountNickName || accountNickName === '') {
            alert('Please name your account!');
            return;
        }
        setShowCreateAccount(false);
        setIsCreating(true);
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: selectedChain.chain_id,
                nick_name: accountNickName
            }
        }
        axios.post(Common.ENDPOINT + 'bdaas/' + selectedChain.type + '/create_account', data).then(response => {
            setIsCreating(false);
            if (response.data.status === '1') {
                getAccounts();
                alert('Account created successfully!');
                setShowCreateAccount(false);
                setAccountNickName(null);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getAccessToken = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: selectedChain.chain_id,
                contract_name: selectedContract.contract_name,
                contract_address: selectedContract.contract_address
            }
        }
        axios.post(Common.ENDPOINT + Common.ACCESS_TOKEN_GET, data).then(response => {
            if (response.data.status === '1') {
                setAccessToken(response.data.access_token);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const refreshTokenHandler = () => {
        setIsFreshing(true);
        setShowRefreshTokenModal(false);
        let data = {
            credentials: authContext.credentials,
            data: {
                access_token: accessToken
            }
        }
        axios.post(Common.ENDPOINT + Common.ACCESS_TOKEN_REFRESH, data).then(response => {
            setIsFreshing(false);
            if (response.data.status === '1') {
                setAccessToken(response.data.access_token);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const publicDappHanlder = (op) => {
        setUpdatingStatus(true);
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: selectedEthChain || selectedChain.chain_id,
                contract_address: selectedContract.contract_address,
                name: dappName || selectedContract.contract_name,
                op: op,
                icon_url: dappIcon
            }
        }
        axios.post(Common.ENDPOINT + Common.DAPP_SHARE, data).then(response => {
            if (response.data.status === '1') {
                getDappStatus();
                setUrlId(response.data.url_id);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getDappStatus = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: selectedEthChain || selectedChain.chain_id,
                contract_address: selectedContract.contract_address,
            }
        }
        axios.post(Common.ENDPOINT + Common.DAPP_STATUS, data).then(response => {
            if (response.data.status === '1') {
                setDappStatus(response.data.dapp_status);
                setTempStatus(response.data.dapp_status);
                setUpdatingStatus(false);
                if (response.data.url_id && response.data.url_id !== '') setUrlId(response.data.url_id);
                setDappName(response.data.dapp_name);
                setDappIcon(response.data.icon_url);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const privateDappHandler = () => {
        setUpdatingStatus(true);
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: selectedEthChain || selectedChain.chain_id,
                contract_address: selectedContract.contract_address,
            }
        }
        axios.post(Common.ENDPOINT + Common.DAPP_UNSHARE, data).then(response => {
            if (response.data.status === '1') {
                setUrlId(null);
                getDappStatus();
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const editContractDescriptionHandler = () => {
        setShowEditContractDescModal(false);
        let data = {
            credentials: authContext.credentials,
            data: {
                contract_id: selectedContract.contract_id,
                description: dappDesc
            }
        }
        axios.post(Common.ENDPOINT + Common.CONTRACT_DESCRIPTION, data).then(response => {
            if (response.data.status === '1') {
                let newContract = { ...selectedContract, contract_description: dappDesc }
                let newContracts = [...contracts]
                newContracts[selectedContractIndex] = newContract;
                setSelectedContract(newContract);
                setContracts(newContracts);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const sortTransactHandler = () => {
        setTransactSorting(false);
        let data = {
            credentials: authContext.credentials,
            data: {
                contract_id: selectedContract.contract_id,
                operation: "transact",
                functions: selectedContract.application_console_contract_abi.transact
            }
        }
        axios.post(Common.ENDPOINT + Common.FUNCTION_SORT, data).then(response => {
            if (response.data.status === '1') {
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const sortCallHandler = () => {
        setCallSorting(false);
        let data = {
            credentials: authContext.credentials,
            data: {
                contract_id: selectedContract.contract_id,
                operation: "call",
                functions: selectedContract.application_console_contract_abi.call
            }
        }
        axios.post(Common.ENDPOINT + Common.FUNCTION_SORT, data).then(response => {
            if (response.data.status === '1') {
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }


    useEffect(() => {
        setContracts(null);
        setWallets(null);
        setBalance(null);
        setDappStatus(null);
        if (selectedChain) {
            getContracts();
            getAccounts();
            setSelectedContract(null);
            setSelectedWallet(null);
        }
    }, [selectedChain])

    useEffect(() => {
        if (selectedChain && selectedWallet && selectedChain.type === 'ethereum') getBalance();
    }, [selectedWallet])

    useEffect(() => {
        if (selectedChain && selectedContract && selectedWallet || selectedEthChain && selectedContract && selectedWallet) {
            setShowSelectContract(false)
        } else {
            setShowSelectContract(true)
        }
    }, [selectedWallet, selectedContract])

    useEffect(() => {
        if (selectedChain && selectedContract) {
            getAccessToken();
            let preSelectInfo = {
                chain_type: 'BT',
                chain_id: selectedChain.chain_id,
                contract_id: selectedContract.contract_id
            }
            setPreChainType('BT');
            setPreChainId(selectedChain.chain_id);
            setPreContractId(selectedContract.contract_id);
            window.sessionStorage.setItem('_console_selected_info', JSON.stringify(preSelectInfo));
            getDappStatus();
        } else if (selectedEthChain && selectedContract) {
            let preSelectInfo = {
                chain_type: 'eth-official',
                chain_id: selectedEthChain,
                contract_id: selectedContract.contract_id
            }
            setPreChainType('eth-official');
            setPreChainId(selectedEthChain);
            setPreContractId(selectedContract.contract_id);
            window.sessionStorage.setItem('_console_selected_info', JSON.stringify(preSelectInfo));
            getDappStatus();
        }
    }, [selectedContract && selectedContract.contract_id])

    const updateFunctionHandler = (key, value, funcType, i) => {
        let newContract = { ...selectedContract };
        newContract.application_console_contract_abi[funcType][i][key] = value;
        setSelectedContract(newContract);
        let newContracts = [...contracts];
        newContracts[selectedContractIndex] = newContract;
        setContracts(newContracts);
    }

    const getIcons = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                current_page: 1,
                per_page: 100,
                source: 'dapp'
            }
        }

        axios.post(Common.ENDPOINT + Common.PROFILE_ICON_LIST, data).then(response => {
            if (response.data.status === '1') {
                setIcons(response.data.items);
            } else {
                alert(response.data.message);
            }
        })
    }

    const uploadIconHandler = () => {
        if (newIcons && newIcons.length > 0) {
            let formData = new FormData();
            formData.append('email', authContext.credentials.email);
            formData.append('token', authContext.credentials.token);
            formData.append('service_id', authContext.credentials.service_id);
            formData.append('source', 'dapp');

            newIcons.forEach(icon => {
                formData.append('file', icon);
            })

            axios({
                method: 'post',
                url: Common.ENDPOINT + Common.ADMIN_UPLOAD_ICONS,
                data: formData,
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }).then(response => {
                setNewIcons();
                getIcons();
                // force uploader to refresh
                setShowUploader(false);
                setTimeout(() => setShowUploader(true), 10);
            })
        } else {
            alert('Please choose images')
        }
    }

    const deleteIconHandler = (id) => {
        let _icons = icons.filter(icon => icon.id !== id)
        setIcons(_icons)
    }

    const editIconHandler = (id, name) => {
        let _icons = [...icons]
        for (let icon of _icons) {
            if (icon.id === id) {
                icon.filename = name;
                break;
            }
        }
        setIcons(_icons);
    }

    const selectedIconHandler = (url) => {
        setShowUpdateIcon(false);
        let data = {
            credentials: authContext.credentials,
            data: {
                contract_id: selectedContract.contract_id,
                icon_url: url
            }
        }

        axios.post(Common.ENDPOINT + Common.UPDATE_ICON, data).then(response => {
            if (response.data.status === '1') {
                setDappIcon(url);
            } else {
                alert(response.data.message);
            }
        })
    }

    const editDappNameHandler = () => {
        setEditOn(false);
        if (!newDappName || newDappName === dappName) return;
        let data = {
            credentials: authContext.credentials,
            data: {
                contract_id: selectedContract.contract_id,
                app_name: newDappName
            }
        }

        axios.post(Common.ENDPOINT + Common.UPDATE_DAPP_NAME, data).then(response => {
            if (response.data.status === '1') {
                setDappName(newDappName);
            } else {
                alert(response.data.message);
            }
        })
    }

    useEffect(() => {
        if ((showUploadIconsModal || showUpdateIcon) && !icons) {
            getIcons();
        }
    }, [showUploadIconsModal, showUpdateIcon])

    const createChainReminder = chainList && chainList.length === 0 ? <div className={classes.reminder}><span>Seems like you do not have a chain yet. Please create a new chain through the <a href='/chain'><i className="fas fa-users-cog"></i>Chain Management.</a></span></div> : null

    const chainItems = chainList ? chainList.map((chain) => {
        let permission = chain.permission === 'public' ? <i className="fas fa-globe-americas" title="public chain"></i> : <i className="fas fa-user" title="private chain"></i>;
        let email = Admin.convertRole(authContext.usertype) === 'ADMIN' ? chain.user_email : null
        return <Dropdown.Item key={chain.chain_id} eventKey={chain.chain_id} onSelect={() => setSelectedChain(chain)}>ID:{chain.chain_id} ({chain.name}{permission} {email})</Dropdown.Item>
    }) : null

    const refreshChainBtn = <span className={classes.refreshBtn} title="refresh chain list" onClick={getChains}><i className="fas fa-sync-alt"></i></span>

    const deployContractReminder = contracts && contracts.length === 0 ? <div className={classes.reminder}><span>Seems like you do not have a deployed contract yet. Please deploy a contract through the <a href='/editor'><i className="far fa-edit"></i>Editor.</a></span></div> : null
    const contractItems = contracts ? contracts.map((contract, i) => {
        let fullHash = contract.contract_address
        let hash = fullHash.substring(0, 8) + '...' + fullHash.substring(fullHash.length - 8, fullHash.length)
        let contractIndex = contract.contract_id ? '#' + contract.contract_id + ': ' : ''
        return <Dropdown.Item key={contract.contract_address} onSelect={() => {
            setSelectedContract(null);
            setTimeout(() => {
                setSelectedContract(contract);
                setSelectedContractIndex(i);
            }, 50)

        }}>{contractIndex} {contract.contract_name} ({hash})</Dropdown.Item>
    }) : null

    const refreshContractBtn = <span className={classes.refreshBtn} title="refresh contract list" onClick={() => {
        if (!selectedEthChain) getContracts();
        else getContractsEthOfficial();
    }}><i className="fas fa-sync-alt"></i></span>
    let selectedContractTitle = 'Please select a contract';
    if (selectedContract) {
        let fullHash = selectedContract.contract_address
        let hash = fullHash.substring(0, 8) + '...' + fullHash.substring(fullHash.length - 8, fullHash.length)
        let contractIndex = selectedContract.contract_id ? '#' + selectedContract.contract_id + ': ' : ''
        selectedContractTitle = `${contractIndex} ${selectedContract.contract_name} (${hash})`
    }
    const refreshWalletBtn = <span className={classes.refreshBtn} title="refresh wallet list" onClick={getAccounts}><i className="fas fa-sync-alt"></i></span>

    const copyWalletBtn = selectedWallet ?
        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={(props) => {
            return (
                <Tooltip id="copy-tooltip" {...props}>
                    Click to Copy Wallet address!
                </Tooltip>
            )
        }}>
            <span className={classes.copyBtn} onClick={(e) => {
                copyToClipboard(selectedWallet.address);
                let ele = e.target;
                ele.innerText = ' Copied!'
                setTimeout(() => { ele.innerText = '' }, 1500)
            }}><i className="fas fa-copy"></i></span>
        </OverlayTrigger> : null

    const createWalletReminder = wallets && wallets.length === 0 ? <div className={classes.reminder}><span>Seems like you do not have a wallet yet. Please create one first.</span></div> : null

    const walletItems = wallets ? wallets.map((wallet, i) => {
        return <Dropdown.Item key={i} eventKey={wallet} onSelect={() => setSelectedWallet(wallet)}>{wallet.nick_name} ({wallet.address})</Dropdown.Item>
    }) : null

    const selectContract =
        <div className={classes.container}>
            {
                showSelectContract ?
                    <div>
                        <div className={classes.row}>
                            <h5 className={[classes.forceCenter, classes.inline].join(' ')} style={{ marginRight: '5px', width: '120px' }}>Select Chain</h5>
                            {
                                selectedEthChain ? <span><b>{ethChains.get(selectedEthChain)}</b></span> :
                                    <span>
                                        <DropdownButton className={classes.inline} title={selectedChain ? `ID: ${selectedChain.chain_id} (${selectedChain.name})` : 'Please select a chain'} variant="custom-blue">
                                            {chainItems}
                                        </DropdownButton>
                                        {refreshChainBtn}
                                        {createChainReminder}
                                    </span>
                            }
                            <span style={{ marginLeft: '1rem' }}>Or Select {selectedEthChain ? <span className={classes.actionBtn} onClick={() => setSelectedEthChain(null)}>BlockTEST chains</span> : <span className={classes.actionBtn} onClick={selectedEthChainHandler}>Ethereum Testnet/Mainnet</span>}</span>
                        </div>
                        {selectedChain || selectedEthChain ?
                            <div>
                                <div className={classes.row}>
                                    <h5 className={[classes.forceCenter, classes.inline].join(' ')} style={{ marginRight: '5px', width: '120px' }}>Select Contract</h5>
                                    <DropdownButton className={classes.inline} title={selectedContractTitle} variant="custom-blue">
                                        {contractItems}
                                    </DropdownButton>
                                    {refreshContractBtn}
                                    {deployContractReminder}
                                </div>
                                {
                                    selectedChain ? <div className={classes.row}>
                                        <h5 className={[classes.forceCenter, classes.inline].join(' ')} style={{ marginRight: '5px', width: '120px' }}>Select Wallet</h5>
                                        <DropdownButton className={classes.inline} title={selectedWallet ? `${selectedWallet.nick_name} (${selectedWallet.address})` : 'Please select a wallet'} variant="custom-blue">
                                            {walletItems}
                                        </DropdownButton>
                                        {refreshWalletBtn}
                                        {copyWalletBtn}
                                        {createWalletReminder}
                                        {balance ? !isLoading ? <span style={{ marginRight: '1rem' }}><b>{balance} ETH</b><span style={{ marginLeft: '1rem', display: 'inline-block' }} className={classes.actionBtn} onClick={loadBalanceHandler}>+ Balance </span></span> : <span style={{ marginRight: '1rem' }}>Loading balance...</span> : null}
                                        {!isCreating ? <span className={classes.actionBtn} onClick={() => setShowCreateAccount(true)}> Create Wallet</span> : <span> Creating...</span>}
                                        {showCreateAccount ? <div className={classes.search} style={{ marginTop: '1rem' }}><InputGroup>
                                            <FormControl
                                                placeholder="Please enter the name of the new wallet"
                                                aria-label="Please enter the name of the new wallet"
                                                aria-describedby="newaccount-addon"
                                                value={accountNickName}
                                                onChange={(e) => {
                                                    setAccountNickName(e.target.value);
                                                    if (!isInputValid(e.target.value)) {
                                                        setShowFeedback(true);
                                                    } else setShowFeedback(false);
                                                }}
                                                maxLength="30"
                                            />
                                            {
                                                showFeedback || !accountNickName || accountNickName === '' ? <InputGroup.Append>
                                                    <div><span className={classes.actionBtn} style={{ marginLeft: '1rem' }} onClick={() => setShowCreateAccount(false)}>Cancel</span></div>
                                                </InputGroup.Append> : <InputGroup.Append>
                                                        <div><span className={classes.actionBtnDark} onClick={createAccountHandler}>Create</span><span className={classes.actionBtn} style={{ marginLeft: '1rem' }} onClick={() => setShowCreateAccount(false)}>Cancel</span></div>
                                                    </InputGroup.Append>
                                            }
                                        </InputGroup>
                                            {showFeedback && accountNickName !== '' ? <small>Wallet name can only contain alphanumeric characters, "-", "_", and "."</small> : null}
                                        </div> : null}
                                    </div> : null
                                }
                            </div>
                            : null}
                    </div> : null
            }
            <div style={{ textAlign: 'center' }}>
                {
                    selectedContract && selectedWallet ? showSelectContract ?
                        <span className={classes.actionBtn2} onClick={() => setShowSelectContract(false)}><i className="fas fa-angle-up"></i>Hide</span>
                        : <span className={classes.actionBtn2} onClick={() => {
                            setShowSelectContract(true);
                        }}><i className="fas fa-angle-down"></i>Show Contract Selection</span> : null
                }
            </div>
        </div>

    const publishDappModal = selectedContract ? <Modal animation={true} show={showPublishConfirm} onHide={() => { setShowPublishConfirm(false) }}>
        <Modal.Header closeButton>
            <Modal.Title>Publish Your Dapp</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Are you sure to publish the dapp <b>{dappName || selectedContract.contract_name}</b><br></br><span style={{ fontSize: '1.2rem' }}>(Address: {selectedContract.contract_address})</span>?</p>
            <p>All the users can access this contract in the DApp Gallery then.</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => { setShowPublishConfirm(false); }}>Cancel</Button>
            <Button variant="primary" onClick={() => { publicDappHanlder('shared'); setShowPublishConfirm(false); }}>Yes, I'd like to publish!</Button>
        </Modal.Footer>
    </Modal> : null;

    const privateDappModal = selectedContract ? <Modal animation={true} show={showPrivateConfirm} onHide={() => { showPrivateConfirm(false) }}>
        <Modal.Header closeButton>
            <Modal.Title>Remove Your Dapp From DApp Gallery</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Are you sure to remove the dapp <b>{tempName}</b><br></br><span style={{ fontSize: '1.2rem' }}>(Address: {selectedContract.contract_address})</span> from the DApp Gallery?</p>
            <p>Users will not be able to access this contract in the DApp Gallery then.</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => { setShowPrivateConfirm(false); }}>Cancel</Button>
            <Button variant="primary" onClick={() => { privateDappHandler(); setShowPrivateConfirm(false); }}>Yes, I'd like to remove!</Button>
        </Modal.Footer>
    </Modal> : null;

    const shareModal = selectedContract ? <Modal animation={true} show={showShareModal} onHide={() => { setShowShareModal(false) }}>
        <Modal.Header closeButton>
            <Modal.Title>Share Your Dapp</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className={classes.spaceBetween} style={{ margin: '1rem 0' }}>
                <span style={{ fontWeight: '600' }}>Share this Dapp in the DApp Gallery (also with link)<br></br>
                    <span style={{ fontSize: '1.2rem', fontWeight: '400' }}>all the greenhouse user can find this Dapp in the Dapp Gallery</span>
                </span>
                <BootstrapSwitchButton width={60} height={30}
                    onstyle="success"
                    offstyle="secondary"
                    checked={tempStatus === 'shared'}
                    onlabel='Yes'
                    offlabel='No'
                    onChange={() => {
                        if (tempStatus === 'shared') {
                            setTempStatus('unshared')
                        } else {
                            setTempStatus('shared')
                        }
                    }}
                />
            </div>
            {
                tempStatus !== 'shared' ? <div className={classes.spaceBetween}>
                    <span style={{ fontWeight: '600' }}>Share this Dapp with link<br></br>
                        <span style={{ fontSize: '1.2rem', fontWeight: '400' }}>
                            only the user has the link below can see this Dapp, user cannot find this Dapp by browsing Dapp Gallery                         </span>
                    </span>
                    <span style={{ display: 'inline-table' }}>
                        <BootstrapSwitchButton width={60} height={30}
                            onstyle="success"
                            offstyle="secondary"
                            checked={tempStatus !== 'unshared'}
                            onlabel='Yes'
                            offlabel='No'
                            onChange={() => {
                                if (tempStatus === 'urlshared') {
                                    setTempStatus('unshared')
                                } else if (tempStatus === 'unshared') {
                                    setTempStatus('urlshared')
                                } else if (tempStatus === 'shared') {

                                }
                            }}
                        /></span>
                </div> : null
            }
            {urlId ? <span className={classes.link}>{Common.DAPP_SHARE_PREFIX}{urlId}<OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={(props) => {
                return (
                    <Tooltip id="copy-tooltip" {...props}>
                        Click to Copy the URL!
                    </Tooltip>
                )
            }}>
                <span className={classes.copyBtn} onClick={(e) => {
                    copyToClipboard(Common.DAPP_SHARE_PREFIX + urlId);
                    let ele = e.target;
                    ele.innerText = ' Copied!'
                    setTimeout(() => { ele.innerText = '' }, 1500)
                }}><i className="fas fa-copy"></i></span>
            </OverlayTrigger></span> : null}
        </Modal.Body>
        {!updatingStatus ? (tempStatus !== dappStatus) ? <Modal.Footer><Button variant="primary" onClick={() => {
            if (tempStatus === 'unshared') {
                setShowPrivateConfirm(true);
            } else if (tempStatus === 'urlshared') {
                publicDappHanlder('urlshared')
            } else if (tempStatus === 'shared') {
                setShowPublishConfirm(true);
            }
        }}>Save</Button><Button variant="secondary" onClick={() => { setShowShareModal(false); setTempStatus(dappStatus); setTempName(dappName) }}>Cancel</Button>
        </Modal.Footer> : <Modal.Footer><Button variant="secondary" onClick={() => { setShowShareModal(false); setTempStatus(dappStatus); setTempName(dappName) }}>Close</Button>
            </Modal.Footer> : <Modal.Footer><b>Updating...</b></Modal.Footer>}
    </Modal> : null;

    let contractInfo = null;
    let chainInfo = null;

    if (selectedContract && selectedWallet) {
        let shareButton = <div className={classes.actionBtnDark} onClick={() => { setShowShareModal(true) }}><b>Share</b><i className="fas fa-share-alt"></i></div>
        if (dappStatus === 'shared') {
            shareButton = <div className={[classes.actionBtnDark, classes.green].join(' ')} onClick={() => { setShowShareModal(true) }}>Shared in DApp Gallery<i className="fas fa-share-alt"></i></div>
        } else if (dappStatus === 'urlshared') shareButton = <div className={[classes.actionBtnDark, classes.orange].join(' ')} onClick={() => { setShowShareModal(true) }}>Shared by link<i className="fas fa-share-alt"></i></div>

        let privateFor = selectedContract.private_for && selectedContract.private_for.length > 0 ? <span style={{ color: '#555' }}><i className="fas fa-lock"></i>Private contract, private for {
            selectedContract.private_for.map(index => {
                return <b>Node{index + 1} </b>
            })
        }</span> : null;

        contractInfo = <div>
            <div className={classes.spaceBetween} style={{ lineHeight: '3rem', marginBottom: '1rem' }}>
                <div>
                    {
                        dappIcon ? <div onClick={() => {
                            setShowUpdateIcon(true);
                        }} className={classes.dappIcon}><img src={dappIcon} /><i className="fas fa-sync-alt"></i></div>
                            : <div onClick={() => {
                                setShowUpdateIcon(true);
                            }} className={classes.dappIcon}><img src={imageHolder} /><i className="fas fa-sync-alt"></i></div>
                    }
                    <div style={{ display: 'inline-block' }}>
                        <div style={{ marginRight: '1rem', fontSize: '2.4rem' }}><b>{selectedContract.contract_name}</b>
                            <span style={{ marginLeft: '5px' }}>( <span title="Dapp Name in Gallery" className={editOn ? classes.nameEdit : null} contentEditable={editOn} onBlur={editDappNameHandler} onInput={(e) => {
                                setNewDappName(e.target.innerText)
                            }} onKeyPress={(e) => {
                                if (e.which == 13 || e.keyCode == 13) { editDappNameHandler(); }
                            }}>{dappName || selectedContract.contract_name}</span><span className={classes.actionBtn}><i className="fas fa-pencil-alt" onClick={() => setEditOn(true)}></i></span>)</span>
                        </div>
                        <div>{
                            selectedContract.contract_description ?
                                <span style={{ color: '#666' }}>{selectedContract.contract_description}<span className={classes.actionBtn} onClick={() => setShowEditContractDescModal(true)} title="edit dapp description"><i className="fas fa-pencil-alt"></i></span></span>
                                : <span style={{ color: '#666' }}>
                                    You havn't set the dapp description, click<span className={classes.actionBtn} onClick={() => setShowEditContractDescModal(true)}><i className="fas fa-pencil-alt"></i></span>to add.
                            </span>
                        }</div>
                        {
                            urlId ? <Link to={`/gallery/shop/${urlId}`}><div className={classes.actionBtnLight}>Go to Gallery</div></Link> : null
                        }
                    </div>
                </div>
                {shareButton}
            </div>
            <div>
                <span title="contract address">{selectedContract.contract_address}</span>
                {privateFor}
            </div>
        </div >
    }

    if (selectedChain && selectedContract && selectedWallet) {
        let type = selectedChain.type;
        if (selectedChain.type === 'quorum') type = <img src={quorumLogo} height='20' alt="quorum" title="quorum chain" />
        else if (selectedChain.type === 'ethereum') type = <img src={ethereumLogo} height='20' alt="ethereum" title="ethereum chain" />

        let number_of_children = null
        if (Admin.convertRole(authContext.usertype) === "ADMIN" && selectedChain.number_of_children !== null) {
            number_of_children = <span title="number of children" style={{ display: 'inlineBlock' }}><i className="fas fa-child"></i>{selectedChain.number_of_children}</span>
        }

        let permission = selectedChain.permission;
        if (permission === 'public') permission = <Badge pill variant="warning" >Shared {number_of_children}</Badge>
        else if (permission === 'share-private') permission = <Badge pill variant="info" >Shared-Private {number_of_children}</Badge>
        else if (permission === 'basic-private') permission = <Badge pill variant="light" >Basic</Badge>;
        else if (permission === 'private') permission = <Badge pill variant="light" >Standard</Badge>;

        chainInfo = <div>
            <div style={{ fontWeight: 600, fontSize: '1.4rem', color: '#555' }}>
                {selectedChain.name} (ID: {selectedChain.chain_id}) <span style={{ margin: '0 1rem' }}>{type}</span>{permission}
            </div>
        </div>
    }

    let web3Contract = selectedEthChain && selectedContract && web3 ? new web3.eth.Contract(selectedContract.contract_abi, selectedContract.contract_address) : null;

    const apiSession = <div>
        <div className={classes.title}>Access Token<OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={<Popover id="contracts-popover">
                <Popover.Content>
                    <p>Access token is the authentication token needed to invoke the RESTful APIs for this application. You can find out how it is used in the JSON examples. </p>                         </Popover.Content>
            </Popover>}
        ><sup><i className="fas fa-info-circle"></i></sup></OverlayTrigger></div>
        {
            accessToken ? <span>
                <span style={{ marginRight: '1rem' }} ><b>{accessToken}</b></span>
                {!isFreshing ? <span className={classes.actionBtn} onClick={() => { setShowRefreshTokenModal(true) }}>Generate New Token</span> : <span>Generating token...</span>}
            </span> :
                <span style={{ color: 'grey' }}>Generating access token...</span>
        }
        <div className={classes.title}>Request URL</div>
        <pre style={{ overflow: 'hidden' }}>
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="copy-tooltip">
                    Click to Copy!
            </Tooltip>}
            ><div className={classes.code} onClick={(e) => {
                copyToClipboard(Common.RESTFUL_ENDPOINT);
                let ele = e.target;
                ele.className += ` ${classes.clicked}`;
                setTimeout(() => { ele.className = classes.code }, 400)
            }}>{Common.RESTFUL_ENDPOINT}</div></OverlayTrigger>
        </pre>
        <div className={classes.title}>Sample Input</div>
        {
            selectedContract && selectedContract.application_console_contract_abi.transact ? selectedContract.application_console_contract_abi.transact.map((abi, i) => {
                if (abi.type === 'function') return <Api data={abi} accessToken={accessToken} chain={selectedChain} contract={selectedContract} account={selectedWallet} key={i} />
            }) : null
        }
        {
            selectedContract && selectedContract.application_console_contract_abi.call ? selectedContract.application_console_contract_abi.call.map((abi, i) => {
                if (abi.type === 'function') return <Api data={abi} accessToken={accessToken} chain={selectedChain} contract={selectedContract} account={selectedWallet} key={i} />
            }) : null
        }
    </div >

    const transactFuncRow = selectedContract && selectedContract.application_console_contract_abi.transact ? !transactSorting ? selectedContract.application_console_contract_abi.transact.map((abi, i) => {
        if (abi.type === 'function') return <FuncRow data={abi} accessToken={accessToken} chain={selectedChain} contract={selectedContract} account={selectedWallet} 
        web3Contract={web3Contract} key={i} 
        sortable={transactSorting} update={(key, value) => updateFunctionHandler(key, value, 'transact', i)} 
        calls={selectedContract.application_console_contract_abi.call} />
    }) : <List
            values={selectedContract.application_console_contract_abi.transact}
            onChange={({ oldIndex, newIndex }) => {
                let newContract = { ...selectedContract };
                newContract.application_console_contract_abi.transact = arrayMove(selectedContract.application_console_contract_abi.transact, oldIndex, newIndex)
                setSelectedContract(newContract);
                let newContracts = [...contracts];
                newContracts[selectedContractIndex] = newContract;
                setContracts(newContracts);
            }}
            renderList={({ children, props }) => <div {...props}>{children}</div>}
            renderItem={({ value, props }) => <div {...props}><FuncRow key={value.name} data={value} accessToken={accessToken} chain={selectedChain} contract={selectedContract} account={selectedWallet} web3Contract={web3Contract} sortable={transactSorting} /></div>}
        /> : null;

    const callFuncRow = selectedContract && selectedContract.application_console_contract_abi.call ? !callSorting ? selectedContract.application_console_contract_abi.call.map((abi, i) => {
        if (abi.type === 'function') return <FuncRow data={abi} accessToken={accessToken} chain={selectedChain} contract={selectedContract} account={selectedWallet} web3Contract={web3Contract} key={i} sortable={callSorting} update={(key, value) => updateFunctionHandler(key, value, 'call', i)} />
    }) : <List
            values={selectedContract.application_console_contract_abi.call}
            onChange={({ oldIndex, newIndex }) => {
                let newContract = { ...selectedContract };
                newContract.application_console_contract_abi.call = arrayMove(selectedContract.application_console_contract_abi.call, oldIndex, newIndex)
                setSelectedContract(newContract);
                let newContracts = [...contracts];
                newContracts[selectedContractIndex] = newContract;
                setContracts(newContracts);
            }}
            renderList={({ children, props }) => <div {...props}>{children}</div>}
            renderItem={({ value, props }) => <div {...props}><FuncRow key={value.name} data={value} accessToken={accessToken} chain={selectedChain} contract={selectedContract} account={selectedWallet} web3Contract={web3Contract} sortable={callSorting} /></div>}
        /> : null;

    const functionSession = selectedContract ? <div style={{ maxWidth: '1200px' }}>
        <Row>
            <Col xs={12} md={6}>
                <div className={[classes.funcType, classes.transact].join(' ')}>
                    <div className={classes.transact}>TRANSACT</div>
                    <div className={classes.sort}>
                        {
                            !transactSorting ?
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 0, hide: 200 }}
                                    overlay={<Tooltip id="sort-transact-tooltip">
                                        <span>Click to sort the functions by dragging and dropping.</span>
                                    </Tooltip>}
                                ><i className="fas fa-sort" onClick={() => setTransactSorting(true)}></i></OverlayTrigger>
                                : <i className="fas fa-check" onClick={sortTransactHandler}></i>
                        }
                    </div>
                </div>
                {transactFuncRow}
            </Col>
            <Col xs={12} md={6}>
                <div className={[classes.funcType, classes.call].join(' ')}>
                    <div className={classes.call}>CALL</div>
                    <div className={classes.sort}>
                        {
                            !callSorting ? <OverlayTrigger
                                placement="top"
                                delay={{ show: 0, hide: 200 }}
                                overlay={<Tooltip id="sort-call-tooltip">
                                    <span>Click to sort the functions by dragging and dropping.</span>
                                </Tooltip>}
                            ><i className="fas fa-sort" onClick={() => setCallSorting(true)}></i></OverlayTrigger>
                                : <i className="fas fa-check" onClick={sortCallHandler}></i>
                        }
                    </div></div>
                {callFuncRow}
            </Col>
        </Row>
    </div> : null;

    const switchButtons = ['Web App', 'RESTful API'].map((tableName, i) => {
        let className = [classes.btSecondary, classes.switchBtn].join(' ');
        if (displaySession === tableName) {
            className = [classes.btSecondary, classes.switchBtn, classes.active].join(' ');
        }
        return <div className={className} key={i} onClick={() => {
            setDisplaySession(tableName)
        }}>{tableName}</div>
    })

    const functionContainer = selectedContract && selectedWallet ? <div style={{ marginBottom: '3rem' }}>
        {contractInfo}
        {chainInfo}
        <div style={{ margin: '2rem 0' }}>{switchButtons}</div>
        {displaySession === 'Web App' ? functionSession : apiSession}
    </div> : null

    const loginReminder = showLoginReminder ? <div>Please <a href="/login">login</a> to check details.</div> : null;

    const refreshTokenModal = <Modal animation={true} show={showRefreshTokenModal} onHide={() => { setShowRefreshTokenModal(false) }}>
        <Modal.Header closeButton>
            <Modal.Title>Generate New Token</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Are you sure to generate a new token for this contract?</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => { setShowRefreshTokenModal(false) }}>Cancel</Button>
            <Button variant="primary" onClick={refreshTokenHandler}>Yes, please!</Button>
        </Modal.Footer>
    </Modal>

    const editContractDescriptionModel = <Modal animation={true} show={showEditContractDescModal} onHide={() => { setShowEditContractDescModal(false) }}>
        <Modal.Header closeButton><Modal.Title>Dapp Description</Modal.Title></Modal.Header>
        <Modal.Body>
            <div>
                <p>Please describe your dapp</p>
                <div><textarea style={{ width: '100%' }} value={selectedContract ? dappDesc ? dappDesc : selectedContract.contract_description : ''} onChange={(e) => setDappDesc(e.target.value)} /></div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => { setShowEditContractDescModal(false) }}>Cancel</Button>
            <Button variant="primary" onClick={editContractDescriptionHandler}>Save</Button>
        </Modal.Footer>
    </Modal>

    const uploadDappIconModal = <Modal show={showUploadIconsModal} animation={true} onHide={() => { setShowUploadIconsModal(false) }} dialogClassName={classes.modal}>
        <Modal.Header closeButton>
            <Modal.Title><b>Manage Dapp Icons</b></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '3rem' }}>
            <h5>Current Icons</h5>
            <div className={classes.icons}>
                {
                    icons ? icons.length > 0 ? icons.map((image, i) => {
                        return <Icon src={image.url} key={i} name={image.filename} id={image.id} editable={true} delete={deleteIconHandler} edit={editIconHandler} source="dapp" />
                    }) : <p>No existing icons.</p> : <p>Fetching Icons...</p>
                }
            </div>
            <h5>Upload New Icons</h5>
            {
                showUploader ? <ImageUploader
                    className={classes.icons}
                    fileContainerStyle={{ width: '100%' }}
                    withIcon={true}
                    onChange={(pictures) => setNewIcons(pictures)}
                    imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                    maxFileSize={5242880}
                    withPreview={true}
                /> : null}
            <div className={classes.btPrimary} onClick={uploadIconHandler}><i className="fas fa-cloud-upload-alt"></i>Upload</div>
        </Modal.Body>
    </Modal>

    const updateDappIconModal = <Modal show={showUpdateIcon} animation={true} onHide={() => { setShowUpdateIcon(false) }} dialogClassName={classes.modal}>
        <Modal.Header closeButton>
            <Modal.Title><b>Manage Dapp Icon</b></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '3rem' }}>
            <h5>Please select your Dapp icon</h5>
            <div className={classes.icons}>
                {
                    icons ? icons.length > 0 ? icons.map((image, i) => {
                        return <Icon src={image.url} key={i} name={image.filename} id={image.id} editable={false} select={selectedIconHandler} />
                    }) : <p>No existing icons.</p> : <p>Fetching Icons...</p>
                }
            </div>
        </Modal.Body>
    </Modal>

    return (
        <div className={classes.console}>
            {refreshTokenModal}
            {publishDappModal}
            {privateDappModal}
            {shareModal}
            {editContractDescriptionModel}
            {uploadDappIconModal}
            {updateDappIconModal}
            <div className={classes.pageTitle}>Application Console</div>
            <p className={classes.pageDesc}>
                Greenhouse automatically creates a Web App and RESTful API for each of your deployed Smart Contract, you can locate your Smart Contract by choosing the chain and contract below. If a Smart Contract has been deployed multiple times, you can use the contract ID to locate a particular version.
            </p>
            {
                authContext && authContext.usertype === 'admin' ?
                    <div className={classes.btPrimary} onClick={() => setShowUploadIconsModal(true)}><i className="fas fa-user-cog"></i>Manage Dapp Icons</div>
                    : null
            }
            {loginReminder}
            {
                !showLoginReminder ? <div>
                    {selectContract}
                    {functionContainer}
                </div> : null
            }
        </div>
    )
}

const copyToClipboard = (secretInfo) => {
    var $body = document.getElementsByTagName('body')[0];
    var $tempInput = document.createElement('INPUT');
    $body.appendChild($tempInput);
    $tempInput.setAttribute('value', secretInfo)
    $tempInput.select();
    document.execCommand('copy');
    $body.removeChild($tempInput);
}

export default Console;