import React, { useContext, useState, useEffect } from 'react';
import classes from './Setting.module.scss';
import { AuthContext } from '../../../context/auth-context';
import { MessageContext } from '../../../context/message-context';

import SubMenu from '../../UI/SubMenu/SubMenu';
import { ClickOutsideToClose } from '../../ClickOutsideToClose/ClickOutsideToClose';
import { useCookies } from 'react-cookie';
import * as ADMIN from '../../../constant/userAdmin';
import Badge from 'react-bootstrap/Badge';
import axios from "axios";
import * as Common from "../../../common";
import { checkAuth } from "../../../commonFunc";

const Setting = () => {
	const authContext = useContext(AuthContext);
	const msgContext = useContext(MessageContext);

	const [showSetting, setShowSetting] = useState(authContext.isAuth);
	const [cookie, setCookie] = useCookies(['email', 'token', 'username']);
	const [reloadUnreadMsgTrigger, setReloadUnreadMsgTrigger] = useState(true);
	const [preUnreadMsg, setPreUnreadMsg] = useState(msgContext.unreadMsgCount);

	let firstTime = true;
	let reloadUnreadMsgInterval;
	const [userIcon, setUserIcon] = useState();

	useEffect(() => {
		// check if cookie has the auth info
		if (cookie.email && cookie.token && cookie.username) {
			let email = cookie.email;
			let token = cookie.token;
			let username = cookie.username;
			let userid = cookie.userid;
			let usertype = cookie.usertype;
			let roles = cookie.roles;
			let icon = cookie.icon;
			authContext.login(email, token, username, userid, usertype, roles, icon);
		}
	}, [])

	useEffect(() => {
		if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
			getUnreadMsgCount();
		}
	}, [authContext.credentials])

	useEffect(() => {
		if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
			setUserIcon(authContext.icon);
		}
	}, [authContext.icon])

	useEffect(() => {
		// only show new message notification when unread message count increase
		if (msgContext.unreadMsgCount) {
			if (preUnreadMsg < msgContext.unreadMsgCount) {
				msgContext.setShowMessageNotif(true);
			}
		}
		setPreUnreadMsg(msgContext.unreadMsgCount);

	}, [msgContext.unreadMsgCount]);

	useEffect(() => {
		if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
			if (reloadUnreadMsgTrigger) {
				reloadUnreadMsgInterval = setInterval(getUnreadMsgCount, 60000);
				let timeout;
				document.onmousemove = () => {
					clearTimeout(timeout);
					setReloadUnreadMsgTrigger(true);
					timeout = setTimeout(() => {
						clearInterval(reloadUnreadMsgInterval);
						setReloadUnreadMsgTrigger(false);
					}, 60001)
				}
			}
		} else {
			clearInterval(reloadUnreadMsgInterval);
		}
		return (() => {
			clearInterval(reloadUnreadMsgInterval);
		})
	}, [authContext.credentials, reloadUnreadMsgTrigger])

	const getUnreadMsgCount = () => {
		// call api to get the unread count
		const data = {
			credentials: authContext.credentials,
			data: {}
		}
		axios.post(Common.ENDPOINT + Common.UNREAD_MESSAGE_COUNT, data).then(response => {
			if (response.data.status === '1') {
				msgContext.setUnreadMsgCount(response.data.unread_message_count)
			}
			else {
				if (checkAuth(response.data, authContext.logout, authContext.maintenance)) console.log(response.data.message);
			}
		})
	}

	// const loginHandler = () => {
	// 	window.location = '/login';
	// }

	// const toggleSettingHandler = () => {
	// 	setShowSetting(!showSetting);
	// }

	// const closeSettingHandler = () => {
	// 	if (showSetting) {
	// 		setShowSetting(false);
	// 	}
	// }

	// const logoutHandler = () => {
	// 	authContext.signout();
	// 	setShowSetting(false);
	// 	// window.location.href='/login';
	// }

	// let greeting = <p><span role="img" aria-label="hello">👋</span>Welcome, <b>{cookie.email}</b></p>;
	// let username = <p>Username: {cookie.username}</p>
	// let roleDescription = ADMIN.getShortDesc(cookie.usertype)
	// let otherRoles = cookie.roles && cookie.roles !== 'undefined' ? cookie.roles.map(role => {
	// 	return <Badge variant="warning" style={{ fontSize: 'inherit', marginLeft:'3px'}} key={role}>{role}</Badge>
	// }) : null;
	// let role = <p><Badge variant="warning" style={{ fontSize: 'inherit' }}>{cookie.usertype}<i className="fas fa-info-circle" title={roleDescription}></i></Badge>
	// 	{otherRoles}</p>
	// let unreadMsgCount = msgContext && msgContext.unreadMsgCount !== 0 ? <sup title='You have unread messages'><Badge variant="danger">{msgContext.unreadMsgCount}</Badge></sup> : null;
	// let setting = showSetting ?
	// 	<SubMenu>
	// 		{greeting}
	// 		{username}
	// 		{role}
	// 		<div className={classes.buttons}>
	// 			<NavLink title="Go to setting page" to='/user/profile' onClick={() => setShowSetting(false)}>Settings</NavLink>
	// 			<NavLink title="Go to account page" to='/user/account' onClick={() => setShowSetting(false)}>Account</NavLink>
	// 			<NavLink title="Go to message center" to='/user/messages' onClick={() => setShowSetting(false)}>Messages{unreadMsgCount}</NavLink>
	// 			<span onClick={logoutHandler} title="log out"><i className="fas fa-sign-out-alt"></i></span>
	// 		</div>
	// 	</SubMenu> : null;

	let unreadMsgCount2 = msgContext && msgContext.unreadMsgCount !== 0 ? <sup title='You have unread messages' className={classes.userIconSup}><Badge variant="danger">{msgContext.unreadMsgCount}</Badge></sup> : null;
	let icon = authContext.isAuth ? userIcon ? <span><img src={userIcon} className={classes.icon}/>{unreadMsgCount2}</span> : <i className="fas fa-user-cog" title="go to settings" style={{color: 'white', fontSize:'20px'}}>{unreadMsgCount2}</i>
	: null

	return (
		// <ClickOutsideToClose handler={closeSettingHandler}>
		<div className={classes.setting}>
			{icon}
			{/* <div className={classes.menu}>{setting}</div> */}
		</div>
		// </ClickOutsideToClose>
	)
}

export default Setting;
