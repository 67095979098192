import React, { useState, useEffect, useContext } from 'react';
import classes from './Chain.module.scss';
import Table from 'react-bootstrap/Table';
import { AuthContext } from '../../../context/auth-context';
import axios from 'axios';
import * as Common from '../../../common';
import ethereumLogo from '../../../assets/images/logo_ethereum.png';
import quorumLogo from '../../../assets/images/logo_quorum.png';
import ReactPaginate from 'react-paginate';
import { checkAuth } from '../../../commonFunc';

const ChainActivity = () => {
    const authContext = useContext(AuthContext);
    const [showLoginReminder, setShowLoginReminder] = useState();
    const [actionData, setActionData] = useState();
    const [actionPages, setActionPages] = useState(0);
    const [currActionPage, setCurrActionPage] = useState(1);
    const [actionPerPage, setActionPerPage] = useState(10);
    const [reloadTrigger, setReloadTrigger] = useState(true);

    const ACTION_CREATE = 'initialize';
    const ACTION_REBOOT = 'reboot';
    const ACTION_REBOOTED = 'rebooted';
    const ACTION_TERMINATE = 'terminate'

    const pagination_options = [10, 25, 50];
    let actionTableRows;
    let reloadChainInterval;

    // LOAD CHAIN_LIST and ACTION_LIST when load the page
    useEffect(() => {
        if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
            setShowLoginReminder(false);
            // only reload the page when the page is active
            if (reloadTrigger && !showLoginReminder) {
                getActionList(authContext.credentials, currActionPage, actionPerPage);
                reloadChainInterval = setInterval(() => {
                    getActionList(authContext.credentials, currActionPage, actionPerPage);
                }, 15000)
                // detect if the page is active, if not stop reloading
                let timeout;
                document.onmousemove = () => {
                    clearTimeout(timeout);
                    setReloadTrigger(true);
                    timeout = setTimeout(() => {
                        clearInterval(reloadChainInterval);
                        setReloadTrigger(false);
                    }, 300000)
                }
            }
        } else {
            setShowLoginReminder(true);
            setActionData(null);
            clearInterval(reloadChainInterval);
        }
        return (() => {
            clearInterval(reloadChainInterval);
        })
    }, [authContext, reloadTrigger, currActionPage, actionPerPage, showLoginReminder])

    useEffect(() => {
        return () => {
            clearInterval(reloadChainInterval);
        }
    }, [])

    const getActionList = (credentials, page, perPage) => {
        let data = {
            credentials,
            data: {
                per_page: perPage,
                page: page
            }
        }
        axios.post(Common.ENDPOINT + Common.CHAIN_ACTION_LIST, data).then(response => {
            if (response.data.status === '1') {
                setActionPages(Math.ceil(parseFloat(response.data.total_items) / actionPerPage))
                setActionData(response.data.actions);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) console.log(response.data.message);
            }
        })
    }

    // ACTION TABLE

    actionTableRows = <tr style={{ textAlign: 'center' }}><td colSpan="6">fetching chain data...</td></tr>
    if (actionData && actionData.length === 0) {
        actionTableRows = <tr style={{ textAlign: 'center' }}><td colSpan="6">No action has been recorded.</td></tr>
    }
    if (showLoginReminder) actionTableRows = <tr style={{ textAlign: 'center' }}><td colSpan="6">Please <a href="/login">login</a> to check details.</td></tr>
    if (actionData && actionData.length > 0) {
        actionTableRows = actionData.map((i, index) => {
            let action = 'unknown';
            if (i.action.toLowerCase() === ACTION_CREATE) {
                action = <span className={[classes.actionType, classes.create].join(' ')}><i className="fas fa-plus-circle"></i>Initialize</span>
            } else if (i.action.toLowerCase() === ACTION_REBOOT) {
                action = <span className={[classes.actionType, classes.reboot].join(' ')}><i className="far fa-play-circle"></i>Reboot</span>
            } else if (i.action.toLowerCase() === ACTION_REBOOTED) {
                action = <span className={[classes.actionType, classes.rebooted].join(' ')}><i className="far fa-play-circle"></i>Rebooted</span>
            } else if (i.action.toLowerCase() === ACTION_TERMINATE) {
                action = <span className={[classes.actionType, classes.terminate].join(' ')}><i className="fas fa-ban"></i>Terminate</span>
            }
            //convert UTC time to localtime
            let utc_time = (new Date(i.action_time)).toString() + ' UTC';
            let localtime = new Date(utc_time).toLocaleString();
            let type = i.type;
            if (type === 'quorum') type = <img src={quorumLogo} height='20' alt="quorum" title="quorum chain" />
            else if (type === 'ethereum') type = <img src={ethereumLogo} height='20' alt="ethereum" title="ethereum chain" />
            return <tr key={index}>
                <td>{i.chain_id}</td>
                <td>{i.name}</td>
                <td style={{ textAlign: 'center' }}>{type}</td>
                <td><b>{i.number_of_nodes}</b></td>
                <td>{localtime}</td>
                <td>{action}</td>
            </tr>
        })
    }

    const actionPaginationUpdate = (perPage) => {
        setCurrActionPage(1);
        setActionPerPage(perPage);
    }

    const actionPaginationOptions = pagination_options.map((number, index) => {
        let classes = "option"
        if (number === actionPerPage) classes = "option selected";
        return <span className={classes} key={index} onClick={() => { actionPaginationUpdate(number) }}>{number}</span>
    })

    const actionTable = <div>
        <div className="pagination_menu">show {actionPaginationOptions} items</div>
        <Table hover style={{ fontSize: '1.4rem' }}>
            <thead>
                <tr>
                    <td>Chain ID</td>
                    <td>Name</td>
                    <td style={{ textAlign: 'center' }}>Protocol</td>
                    <td>Nodes</td>
                    <td>Timestamp</td>
                    <td>Action</td>
                </tr>
            </thead>
            <tbody>
                {actionTableRows}
            </tbody>
        </Table>
        <ReactPaginate
            forcePage={currActionPage - 1}
            previousLabel={<i className="fas fa-caret-left"></i>}
            nextLabel={<i className="fas fa-caret-right"></i>}
            breakLabel={<i className="fas fa-ellipsis-h"></i>}
            breakClassName={'break-me'}
            pageCount={actionPages}
            onPageChange={(page) => {
                setCurrActionPage(page.selected + 1);
            }}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
        />
    </div>

    return (
        <div>
            <div className={classes.chainAdmin}>
                <div className={classes.pageTitle}>Activities</div>
                <p className={classes.pageDesc}>Here are the records of all your chain activity history.</p>
                {actionTable}
            </div>
        </div>
    )
}

export default ChainActivity;