import React from 'react';
import classes from './Menu.module.scss';
import Setting from './Setting/Setting';
import { NavLink } from 'react-router-dom';
import Notification from './Notification/Notification';

const Menu = () => {
  return (
    <div className={classes.menu}>
      <div className={classes.icons}>
        <NavLink to="/home" activeClassName={classes.Active}><div className={classes.icon} title="home"><i className="fas fa-home"></i></div></NavLink>
        <NavLink to="/chain" activeClassName={classes.Active}><div className={classes.icon} title="chain admin"><i className="fas fa-cube"></i></div></NavLink>
        {/* <NavLink to="/consortiums" activeClassName={classes.Active}><div className={classes.icon} title="consortiums"><i className="fas fa-users"></i></div></NavLink> */}
        <NavLink to="/editor" activeClassName={classes.Active}><div className={classes.icon} title="editor"><i className="fas fa-edit"></i></div></NavLink>
        <NavLink to="/console" activeClassName={classes.Active}><div className={classes.icon} title="application console"><i className="fas fa-code"></i></div></NavLink>
        <NavLink to="/gallery" activeClassName={classes.Active}><div className={classes.icon} title="gallery"><i className="fas fa-star"></i></div></NavLink>
      </div>
      {/* <div className={classes.icon}><Setting /></div> */}
      <Notification />
    </div>
  )
}

export default Menu;