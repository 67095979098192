import React, { useState, useEffect, useContext } from 'react';
import classes from './Marketplace.module.scss';
import Card from './Card/Card';
import axios from 'axios';
import * as Common from '../../../common';
import ReactPaginate from 'react-paginate';

const Shop = () => {
    const [currPageRcm, setCurrPageRcm] = useState(1);
    const [currPageRt, setCurrPageRt] = useState(1);
    const [pagesRcm, setPagesRcm] = useState(0);
    const [pagesRt, setPagesRt] = useState(0);
    const [rcmData, setRcmData] = useState();
    const [rtData, setRtData] = useState();

    let perPage = 12;

    const getDappList = (op) => {
        let currPage = op === 'recommended' ? currPageRcm : currPageRt;
        let data = {
            data: {
                op: op,
                current_page: currPage,
                per_page: perPage
            }
        }
        axios.post(Common.ENDPOINT + Common.DAPP_LIST, data).then(response => {
            if (response.data.status === '1') {
                if (op === 'recommended') {
                    setRcmData(response.data.items);
                    setPagesRcm(Math.ceil(parseFloat(response.data.total_items) / perPage))
                } else if (op === 'recent') {
                    setRtData(response.data.items);
                    setPagesRt(Math.ceil(parseFloat(response.data.total_items) / perPage))
                }
            }
            else {
                alert(response.data.message)
            }
        })
    }

    useEffect(()  => {
        getDappList('recommended');
    }, [currPageRcm])

    useEffect(()  => {
        getDappList('recent');
    }, [currPageRt])

    const rcmPagination = pagesRcm > 0 ? <ReactPaginate
        forcePage={currPageRcm - 1}
        previousLabel={<i className="fas fa-caret-left"></i>}
        nextLabel={<i className="fas fa-caret-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h"></i>}
        breakClassName={'break-me'}
        pageCount={pagesRcm}
        onPageChange={(page) => {
            setCurrPageRcm(page.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
    /> : null

    const rtPagination = pagesRt > 0 ? <ReactPaginate
        forcePage={currPageRt - 1}
        previousLabel={<i className="fas fa-caret-left"></i>}
        nextLabel={<i className="fas fa-caret-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h"></i>}
        breakClassName={'break-me'}
        pageCount={pagesRt}
        onPageChange={(page) => {
            setCurrPageRt(page.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
    /> : null

    const RecommendedApps = <div>
        <div className={classes.subTitle}>Recommended for you</div>
            {
                rcmData ? rcmData.length > 0 ? rcmData.map(app => {
                    return <Card data={app} key={app.id}/>
                }) : <p>No recommended dapp available yet</p> : <p>Fetching Data...</p>
            }
        {rcmPagination}
    </div>

    const RecentApps = <div>
        <div className={classes.subTitle}>Recent Updated</div>
            {
                rtData ? rtData.length > 0 ? rtData.map(app => {
                    return <Card data={app} key={app.id}/>
                }) : <p>No recent updated dapp available yet</p> : <p>Fetching Data...</p>
            }
        {rtPagination}
    </div>

    return (
        <div className={classes.marketplace}>
            <div className={classes.pageTitle}>DApp Gallery</div>
            <p className={classes.pageDesc}>Creating, sharing, learning. We transform the way you show what you’re passionate about. Here you can publish your Dapps and share it with the rest of the world. </p>
            <div className={classes.container}>
                {RecommendedApps}
                {RecentApps}
            </div>
        </div>
    )
}

export default Shop;