import React, { useState, useEffect, useContext } from 'react';
import classes from './Menu.module.scss';
import { Link, NavLink } from 'react-router-dom';
import { MonitorContext } from '../../../../context/monitor-context';

const Menu = () => {
    const monitorContext = useContext(MonitorContext);
    const [showMonitorMenu, setShowMonitorMenu] = useState(false);

    useEffect(() => {
        if (monitorContext.chain) setShowMonitorMenu(true);
        else setShowMonitorMenu(false);
    }, [monitorContext])

    if (monitorContext.chain) {
        return (
            <div className={classes.menu}>
                <div className={classes.title}>Chain Monitor</div>
                <div className={classes.icons}>
                    <Link to="/chain/admin"><div className={[classes.icon, classes.redirect].join(' ')}>&lt; Back to All Chains</div></Link>
                    {/* <div className={classes.chainName}>{monitorContext.chain.name}</div> */}
                    <NavLink to="/chain/monitor/overview" activeClassName={classes.Active}>
                        <div className={classes.icon}>Overview</div>
                    </NavLink>
                    <NavLink to="/chain/monitor/blocks" activeClassName={classes.Active}>
                        <div className={classes.icon}>Blocks</div>
                    </NavLink>
                    <NavLink to="/chain/monitor/transactions" activeClassName={classes.Active}>
                        <div className={classes.icon}>Transactions</div>
                    </NavLink>
                    <NavLink to="/chain/monitor/contracts" activeClassName={classes.Active}>
                        <div className={classes.icon}>Contracts</div>
                    </NavLink>
                    <NavLink to="/chain/monitor/token" activeClassName={classes.Active}>
                        <div className={classes.icon}>Token</div>
                    </NavLink>
                    <NavLink to="/chain/monitor/wallet" activeClassName={classes.Active}>
                        <div className={classes.icon}>Wallet</div>
                    </NavLink>
                    <NavLink to="/chain/monitor/nodes" activeClassName={classes.Active}>
                        <div className={classes.icon}>Nodes</div>
                    </NavLink>
                </div>
            </div>
        )
    } else return null;
}

export default Menu;