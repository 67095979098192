import React, { useState } from 'react';

export const MonitorContext = React.createContext({});

const MonitorContextProvider = props => {
    const [selectedChain, setSelectedChain] = useState();

    const getChainDesc = () => {
        let chainDesc = selectedChain ? 
         (selectedChain.type)[0].toUpperCase() + (selectedChain.type).slice(1) + ' / ' +  selectedChain.name : null
        return chainDesc
    }

    return (
        <MonitorContext.Provider
            value={{
                chain: selectedChain,
                setChain: setSelectedChain,
                getChainDesc: getChainDesc,
            }}>
            {props.children}
        </MonitorContext.Provider>
    )
}

export default MonitorContextProvider;

