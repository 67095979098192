import React, { useState, useEffect, useContext } from 'react';
import classes from './Marketplace.module.scss';
import Card from './Card/Card';
import axios from 'axios';
import * as Common from '../../../common';
import ReactPaginate from 'react-paginate';
import { AuthContext } from '../../../context/auth-context';
import { checkAuth } from '../../../commonFunc';

const SavedApps = () => {
    const authContext = useContext(AuthContext);
    const [showLoginReminder, setShowLoginReminder] = useState();
    const [currPageSave, setCurrPageSave] = useState(1);
    const [pagesSave, setPagesSave] = useState();
    const [dappDataSave, setDappDataSave] = useState();

    const perPage = 24;

    useEffect(() => {
        if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
            setShowLoginReminder(false);
            getSavedApps();
        } else { setShowLoginReminder(true); }
    }, [authContext, currPageSave])

    const getSavedApps = () => {
        let data = {
            credentials: authContext.credentials,
            data: {
                current_page: currPageSave,
                per_page: perPage,
            }
        }
        axios.post(Common.ENDPOINT + Common.SAVED_DAPP_LIST, data).then(response => {
            if (response.data.status === '1') {
                setDappDataSave(response.data.items);
                setPagesSave(Math.ceil(parseFloat(response.data.total_items) / perPage))
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const savePagination = pagesSave > 0 ? <ReactPaginate
        forcePage={currPageSave - 1}
        previousLabel={<i className="fas fa-caret-left"></i>}
        nextLabel={<i className="fas fa-caret-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h"></i>}
        breakClassName={'break-me'}
        pageCount={pagesSave}
        onPageChange={(page) => {
            setCurrPageSave(page.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
    /> : null

    const SavedApps = <div>
        {
            dappDataSave ? dappDataSave.length > 0 ? dappDataSave.map(app => {
                return <Card data={app} key={app.id} />
            }) : <p>You have not saved dapp yet</p> : <p>Fetching Data...</p>
        }
        {savePagination}
    </div>

    const loginReminder = showLoginReminder ? <div>Please <a href="/login">login</a> to check details.</div> : null;

    return (
        <div className={classes.marketplace}>
            <div className={classes.pageTitle}>My Saved DApps</div>
            {loginReminder}
            {
                !showLoginReminder ? <div className={classes.container}>
                    {SavedApps}
                </div> : null
            }
        </div>
    )
}

export default SavedApps;