import React, { useState, useEffect, useContext } from 'react';
import classes from './Chain.module.scss';
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import { AuthContext } from '../../../context/auth-context';
import axios from 'axios';
import * as Common from '../../../common';
import MyModal from '../../UI/Modal/Modal';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Blocks from '../../../assets/images/net.gif';
import ethereumLogo from '../../../assets/images/logo_ethereum.png';
import quorumLogo from '../../../assets/images/logo_quorum.png';
import neoLogo from '../../../assets/images/logo_neo.png';
import hyperledgerLogo from '../../../assets/images/logo_Hyperledger_Fabric.png';
import Spinner from 'react-bootstrap/Spinner';
import ReactPaginate from 'react-paginate';
import * as Admin from '../../../constant/userAdmin';
import { checkAuth } from '../../../commonFunc';
import { Link } from 'react-router-dom';
import { MonitorContext } from '../../../context/monitor-context';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'

const ChainAdmin = () => {
  const authContext = useContext(AuthContext);
  const monitorContext = useContext(MonitorContext);

  const [showLoginReminder, setShowLoginReminder] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showTerminateConfirm, setShowTerminateConfirm] = useState(false);
  const [showRebootConfirm, setShowRebootConfirm] = useState(false);
  const [newChain, setNewChain] = useState({ chain_type: 'quorum', name: '', number_of_nodes: 3, permission: 'basic-private' });
  const [chainToTerminate, setChainToTerminate] = useState({ name: '', chain_id: null, user_email: '' });
  const [chainToReboot, setChainToReboot] = useState({ name: '', chain_id: null, user_email: '' });
  const [showCreateChain, setShowCreateChain] = useState(false);
  const [isLoading, setIsLoading] = useState({ action_type: '', show: false });
  const [chainData, setChainData] = useState();
  const [currChainPage, setCurrChainPage] = useState(1);
  const [chainPages, setChainPages] = useState(0);
  const [chainPerPage, setChainPerPage] = useState(10);
  const [actionAlert, setActionAlert] = useState({ action_type: '', show: false });
  const [reloadTrigger, setReloadTrigger] = useState(true);

  const [selectedSharedChain, setSelectedSharedChain] = useState(null);
  const [currSharedChainPage, setCurrSharedChainPage] = useState(1);
  const [sharedChainPages, setSharedChainPages] = useState(0);
  const [selectedSharedChainData, setSelectedSharedChainData] = useState();
  const [showCreateButton, setShowCreateButton] = useState(false);
  const chainTypes = ['quorum', 'ethereum', 'hyperledger', 'neo'];
  const STATUS_ACTIVE = 'active';
  const STATUS_TERMINATED = 'terminated';
  const STATUS_FAILED = 'failed';
  const STATUS_PENDING = 'pending';
  const CHAIN_REBOOT = 'reboot';
  const CHAIN_TERMINATED = 'terminate';
  const ADMIN = 'ADMIN';
  const DEMO = 'DEMO';
  const USER = 'USER';
  const STATUS_INITIALIZE = {
    pending: 'the server instance is pending (or creating)',
    queue: 'the request is in queue',
    initializing: 'the server instance is initializing.',
    initialized: 'the server instance is initialized.',
    server_ready: 'the server is ready to install chain',
    chain_ready: 'the chain is installed',
    monitor_ready: 'the monitor is installed',
  }
  const pagination_options = [10, 25, 50];
  const permissionTypesAdmin = [{ type: 'basic-private', name: 'Basic' }, { type: 'public', name: 'Shared' }, { type: 'share-private', name: 'Shared-Private' }, { type: 'private', name: 'Standard' }];
  const permissionTypesUser = [{ type: 'basic-private', name: 'Basic' }, { type: 'private', name: 'Standard' }];
  const TYPE_DESC = {
    quorum_basic_private: <span>Basic 3-node Quorum chain with Raft concensus, it has limited computing power, suitable for development and testing​</span>,
    quorum_basic_private_limit: <span>Basic 3-node Quorum chain with Raft concensus, it has limited computing power, suitable for development and testing​. <br></br><b>You have an active Quorum basic chain, cannot create more.</b></span>,
    quorum_private: <span>Standard Quorum chain with Raft concensus. <br></br><b>This function is still under development, please use Basic type for now.</b></span>,
    eth_basic_private: <span>Basic Ethereum chain, it has limited computing power, suitable for development and testing​</span>,
    eth_basic_private_limit: <span>Basic Ethereum chain, it has limited computing power, suitable for development and testing​. <br></br><b>You have an active Ethemreum basic chain, cannot create more.</b></span>,
    eth_private: <span>Standard Ethereum chain. <br></br><b>This function is still under development, please use Basic type for now.</b>​</span>
  }

  let chainTableRows;
  let reloadChainInterval;
  let num_activeChain_quorum = 0;
  let num_activeChain_eth = 0;
  // LOAD CHAIN_LIST and ACTION_LIST when load the page
  useEffect(() => {
    if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
      setShowLoginReminder(false);
      // only reload the page when the page is active
      if (reloadTrigger && !showLoginReminder) {
        getChainList(authContext.credentials, currChainPage, chainPerPage);
        reloadChainInterval = setInterval(() => {
          getChainList(authContext.credentials, currChainPage, chainPerPage);
        }, 15000)
        // detect if the page is active, if not stop reloading
        let timeout;
        document.onmousemove = () => {
          clearTimeout(timeout);
          setReloadTrigger(true);
          timeout = setTimeout(() => {
            clearInterval(reloadChainInterval);
            setReloadTrigger(false);
          }, 300000)
        }
      }
    } else {
      setShowLoginReminder(true);
      setChainData(null);
      clearInterval(reloadChainInterval);
    }
    return (() => {
      clearInterval(reloadChainInterval);
    })
  }, [authContext, reloadTrigger, currChainPage, chainPerPage, showLoginReminder])

  useEffect(() => {
    return () => {
      clearInterval(reloadChainInterval);
    }
  }, [])

  useEffect(() => {
    if (selectedSharedChain) {
      setSelectedSharedChainData();
      getSharedChainList();
    }
  }, [selectedSharedChain, currSharedChainPage])

  const getChainList = (credentials, page, perPage) => {
    let data = {
      credentials,
      data: {
        op: 'list',
        per_page: perPage,
        page: page
      }
    }
    axios.post(Common.ENDPOINT + Common.CHAIN_CHAIN_LIST, data).then(response => {
      if (response.data.status === '1') {
        setChainPages(Math.ceil(parseFloat(response.data.total_items) / chainPerPage))
        setChainData(response.data.chains);
      }
      else {
        if (checkAuth(response.data, authContext.logout, authContext.maintenance)) console.log(response.data.message);
      }
    })
  }

  const getSharedChainList = () => {
    let data = {
      credentials: authContext.credentials,
      data: {
        per_page: 10,
        page: currSharedChainPage,
        parent_chain_id: selectedSharedChain.chain_id
      }
    }
    axios.post(Common.ENDPOINT + Common.BASIC_CHAIN_LIST, data).then(response => {
      if (response.data.status === '1') {
        setSharedChainPages(Math.ceil(parseFloat(response.data.total_items) / 10))
        setSelectedSharedChainData(response.data.chains);
      }
      else {
        if (checkAuth(response.data, authContext.logout, authContext.maintenance)) console.log(response.data.message);
      }
    })
  }

  // CREATE CHAIN SESSION 
  const nodesNumbers = [2, 3, 4, 5, 6, 7, 8, 9];
  const nodesItems = nodesNumbers.map((i) => {
    return <Dropdown.Item key={i} eventKey={i} onSelect={() => setNewChain({
      ...newChain,
      number_of_nodes: i
    })}>{i}</Dropdown.Item>
  })

  const getChainInfo = () => {
    let info = {};
    switch (newChain.chain_type) {
      case 'quorum': {
        info = {
          ...info,
          permission: newChain.permission,
          number_of_nodes: newChain.number_of_nodes
        }
        if (newChain.permission === 'basic-private') info.number_of_nodes = 1;
        return info;
      }
      case 'ethereum': {
        info = {
          ...info,
          permission: newChain.permission,
          number_of_nodes: 1
        }
        return info
      }
      default: return info;
    }
  }

  const createChainHandler = () => {
    setShowConfirm(false)
    if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
      let chainInfo = getChainInfo();
      let data = {
        credentials: authContext.credentials,
        data: {
          ...chainInfo,
          name: newChain.name,
          chain_type: newChain.chain_type,
          user_email: authContext.credentials.email
        }
      }
      setIsLoading({ action_type: 'Creating the new chain', show: true });
      setTimeout(() => {
        setIsLoading({ action_type: '', show: false });
      }, 3000)
      setShowCreateChain(false);
      axios.post(Common.ENDPOINT + Common.CHAIN_CREATE, data).then(response => {
        if (response.data.status === '1') {
          // reload chain table immediately
          setCurrChainPage(1);
          getChainList(authContext.credentials, 1, chainPerPage)
        } else {
          if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message);
        }
      })
    } else {
      alert('Please login first！');
    }
  }

  const chainTypeItems = chainTypes.map((i) => {
    let _class = classes.picDisabled;
    if (i === 'quorum' || i === 'ethereum') _class = classes.picEnabled
    if (newChain.chain_type === i) {
      _class = [_class, classes.picSelected].join(' ')
    }
    let src = null
    if (i === 'ethereum') src = ethereumLogo
    else if (i === 'quorum') src = quorumLogo
    else if (i === 'neo') src = neoLogo
    else if (i === 'hyperledger') src = hyperledgerLogo

    if (i === 'quorum' || i === 'ethereum') {
      return <div className={_class} title={i} key={i} onClick={() => { setNewChain({ ...newChain, chain_type: i }) }}>
        <img alt={i + 'logo'} src={src} height="30"></img>
        <div>{i[0].toUpperCase() + i.slice(1)}</div>
      </div>
    } else {
      return <div className={_class} key={i} title="Coming soon...">
        <img alt={i + 'logo'} src={src} height="30"></img>
        <div>{i[0].toUpperCase() + i.slice(1)}</div>
      </div>
    }
  })

  // CHAIN TABLE

  const rebootChainHandler = () => {
    setShowRebootConfirm(false);
    setIsLoading({ action_type: 'Rebooting the chain', show: true });
    let data = {
      credentials: authContext.credentials,
      data: {
        chain_id: chainToReboot.chain_id,
        action: CHAIN_REBOOT
      }
    }
    setChainToReboot({ name: '', chain_id: null, user_email: '' });
    axios.post(Common.ENDPOINT + Common.CHAIN_ACTION, data).then(response => {
      setTimeout(() => {
        setIsLoading({ action_type: '', show: false });
      }, 3000)
      if (response.data.status === '1') {
        // reload chain table
        setCurrChainPage(1);
        getChainList(authContext.credentials, 1, chainPerPage)
      } else {
        if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
      }
    })
  }

  const terminateChainHandler = () => {
    setShowTerminateConfirm(false);
    let data = {
      credentials: authContext.credentials,
      data: {
        chain_id: chainToTerminate.chain_id,
        action: CHAIN_TERMINATED
      }
    }
    setChainToTerminate({ name: '', chain_id: null, user_email: '' });
    axios.post(Common.ENDPOINT + Common.CHAIN_ACTION, data).then(response => {
      if (response.data.status === '1') {
        // reload chain table
        setCurrChainPage(1);
        getChainList(authContext.credentials, 1, chainPerPage)
      } else {
        if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
      }
    })
  }

  const chainPaginationUpdate = (perPage) => {
    setChainPerPage(perPage);
    setCurrChainPage(1);
  }

  const isValidName = (input) => {
    var format = /^[a-zA-Z0-9-._\s]+$/
    return format.test(input)
  }

  const getChainTableRows = (chainData) => {
    let rows = <tr style={{ textAlign: 'center' }}><td colSpan="8">fetching chain data...</td></tr>
    if (chainData && chainData.length === 0) rows = <tr style={{ textAlign: 'center' }}><td colSpan="8">No chain is active now. Terminated/Failed chains only displayed for 24 hrs.</td></tr>
    if (showLoginReminder) rows = <tr style={{ textAlign: 'center' }}><td colSpan="8">Please <a href="/login">login</a> to check details.</td></tr>

    if (chainData && chainData.length > 0) {
      num_activeChain_quorum = 0;
      num_activeChain_eth = 0;

      rows = chainData.map((i) => {
        //convert UTC time to localtime
        let utc_time = (new Date(i.create_time)).toString() + ' UTC';
        let localtime = new Date(utc_time).toLocaleString();
        let update_utc_time = (new Date(i.last_update_time)).toString() + ' UTC';
        let update_localtime = new Date(update_utc_time).toLocaleString();
        let updateTimeTitle = 'create time: ' + localtime;
        let updateTime = <span title={updateTimeTitle} style={{ cursor: 'default' }}>{update_localtime}</span>
        let status = i.status;
        let status_title = "Last update: " + update_localtime;
        if (i.status === STATUS_ACTIVE) {
          status = <span className={[classes.chainStatus, classes.active].join(' ')} title={status_title}><i className="fas fa-dot-circle"></i>Active</span>
        } else if (i.status === STATUS_TERMINATED) {
          status = <span className={[classes.chainStatus, classes.terminated].join(' ')} title={status_title}><i className="fas fa-ban"></i>Terminated</span>
        } else if (i.status === STATUS_FAILED) {
          status = <span className={[classes.chainStatus, classes.failed].join(' ')} title={status_title}><i className="fas fa-exclamation-triangle"></i>Failed</span>
        } else if (i.status === STATUS_PENDING) {
          status = <span className={[classes.chainStatus, classes.pending].join(' ')} title={status_title}><i className="fas fa-dot-circle"></i>Pending</span>
        } else {
          let info = i.status + ': ' + STATUS_INITIALIZE[i.status] + '. ' + status_title;
          status = <span className={[classes.chainStatus, classes.pending].join(' ')} title={info} ><Spinner as='span' animation='border' role='status' aria-hidden='true' /> Processing<sup><i className="fas fa-info-circle"></i></sup></span>
        }

        let number_of_children = null
        if (Admin.convertRole(authContext.usertype) === ADMIN && i.number_of_children !== null) {
          number_of_children = <span title="number of children" style={{ display: 'inlineBlock' }}><i className="fas fa-child"></i>{i.number_of_children}</span>
        }

        let permission;
        if (i.permission === 'public') permission = <Badge pill variant="warning" style={{ fontSize: '12px' }}>Shared {number_of_children}</Badge>
        else if (i.permission === 'share-private') permission = <Badge pill variant="info" style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => { setSelectedSharedChain(i) }} title="Click to see the children chains">Shared-Private {number_of_children}</Badge>
        else if (i.permission === 'basic-private') permission = <Badge pill variant="light" style={{ fontSize: '12px' }}>Basic</Badge>;
        else if (i.permission === 'private') permission = <Badge pill variant="light" style={{ fontSize: '12px' }}>Standard</Badge>;

        // to limit demo-user/user can only have 1 active basic quourm/eth chains
        if (i.type === 'quorum' && i.permission === 'basic-private' && i.status !== STATUS_FAILED && i.status !== STATUS_TERMINATED) {
          num_activeChain_quorum++;
        }
        if (i.type === 'ethereum' && i.permission === 'basic-private' && i.status !== STATUS_FAILED && i.status !== STATUS_TERMINATED) {
          num_activeChain_eth++;
        }
        let type = i.type;
        if (type === 'quorum') type = <img src={quorumLogo} height='20' alt="quorum" title="quorum chain" />
        else if (type === 'ethereum') type = <img src={ethereumLogo} height='20' alt="ethereum" title="ethereum chain" />
        let chainName = i.name;
        if (Admin.convertRole(authContext.usertype) === ADMIN) {
          chainName = <span><b>{i.name}</b> ({i.user_email})</span>
        }
        let monitorIcon = i.status === STATUS_ACTIVE ? <i className="fas fa-tachometer-alt"></i> : null
        return <tr key={i.chain_id}>
          <td>{i.chain_id}</td>
          <td>{chainName}<Link to="/chain/monitor" title="go to monitor page" onClick={() => {
            if (i.status === STATUS_ACTIVE) {
              window.sessionStorage.setItem('selected_chain', JSON.stringify(i));
              monitorContext.setChain(i);
            }
          }}>{monitorIcon}</Link></td>
          <td style={{ textAlign: 'center' }}>{type}</td>
          <td>{permission}</td>
          <td>{updateTime}</td>
          <td>{status}</td>
          <td><b>{i.number_of_nodes}</b></td>
          {/* <td>{actionBtn}</td> */}
        </tr>
      })
    }
    return rows;
  }

  chainTableRows = getChainTableRows(chainData);

  const chainPaginationOptions = pagination_options.map((number, index) => {
    let classes = "option"
    if (number === chainPerPage) classes = "option selected";
    return <span className={classes} key={index} onClick={() => { chainPaginationUpdate(number) }}>{number}</span>
  })

  const chainTable = <div>
    <div className="pagination_menu">show {chainPaginationOptions} items</div>
    <Table hover style={{ fontSize: '1.4rem' }}>
      <thead>
        <tr>
          <td>Chain ID</td>
          <td>Name</td>
          <td style={{ textAlign: 'center' }}>Protocol</td>
          <td>Type<OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={<Popover id="type-popover">
              <Popover.Title><h3>Chain Type</h3></Popover.Title>
              <Popover.Content>
              <p>Basic:  private chain with limited computing power, suitable for development and testing. </p>
              <p>Shared: public chain provisioned by BlockTEST, all users can access. </p>
              </Popover.Content>
          </Popover>}
          ><sup><i className="fas fa-info-circle"></i></sup></OverlayTrigger></td>
          <td>Last Updated Time</td>
          <td>Status<OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={<Popover id="status-popover">
              <Popover.Title><h3>Chain Status</h3></Popover.Title>
              <Popover.Content>
              <p>Status can be Active, Terminated, Failed, Pending, and Processing.</p>
              </Popover.Content>
          </Popover>}
          ><sup><i class="fas fa-info-circle"></i></sup></OverlayTrigger></td>
          <td>Nodes<OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={<Popover id="nodes-popover">
              <Popover.Title><h3>Nodes Number</h3></Popover.Title>
              <Popover.Content>
              <p>number of participating nodes on the chain.</p>
              </Popover.Content>
          </Popover>}
          ><sup><i class="fas fa-info-circle"></i></sup></OverlayTrigger></td>
        </tr>
      </thead>
      <tbody>
        {chainTableRows}
      </tbody>
    </Table>
    <ReactPaginate
      forcePage={currChainPage - 1}
      previousLabel={<i className="fas fa-caret-left"></i>}
      nextLabel={<i className="fas fa-caret-right"></i>}
      breakLabel={<i className="fas fa-ellipsis-h"></i>}
      breakClassName={'break-me'}
      pageCount={chainPages}
      onPageChange={(page) => {
        setCurrChainPage(page.selected + 1);
      }}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      containerClassName={'pagination'}
      subContainerClassName={'pages pagination'}
      activeClassName={'active'}
    />
  </div>

  const shortDesct = Admin.getShortDesc(authContext.usertype);
  const role = <h4>You are <Badge variant="warning" style={{ fontSize: 'inherit' }}>{authContext.usertype}</Badge>: {shortDesct}</h4>

  // Only admin user can choose the chain type from 'public' or 'private'
  // demo user can choose the chain type only from 'private'
  const permission = Admin.convertRole(authContext.usertype) === ADMIN ? permissionTypesAdmin.map(permission => {
    if (permission.type === newChain.permission) return <Button variant="light" key={permission.type} className={[classes.toggleBtn, classes.selected].join(' ')}>{permission.name}</Button>
    else return <Button variant="light" className={classes.Btn} key={permission.type} onClick={() => { setNewChain({ ...newChain, permission: permission.type }) }}>{permission.name}</Button>
  }) : permissionTypesUser.map(permission => {
    if (permission.type === newChain.permission) return <Button variant="light" key={permission.type} className={[classes.toggleBtn, classes.selected].join(' ')}>{permission.name}</Button>
    else return <Button variant="light" className={classes.Btn} key={permission.type} onClick={() => { setNewChain({ ...newChain, permission: permission.type }) }}>{permission.name}</Button>
  })

  let typeDesc = null;
  if (newChain.chain_type === 'quorum' && newChain.permission === 'basic-private') {
    if (Admin.convertRole(authContext.usertype) === ADMIN || num_activeChain_quorum < 1) {
      typeDesc = <span className={classes.typeDesc}>{TYPE_DESC.quorum_basic_private}</span>
    } else typeDesc = <span className={classes.typeDesc}>{TYPE_DESC.quorum_basic_private_limit}</span>
  } else if (newChain.chain_type === 'quorum' && newChain.permission === 'private') {
    typeDesc = <span className={classes.typeDesc}>{TYPE_DESC.quorum_private}</span>
  } else if (newChain.chain_type === 'ethereum' && newChain.permission === 'basic-private') {
    if (Admin.convertRole(authContext.usertype) === ADMIN || num_activeChain_eth < 1) {
      typeDesc = <span className={classes.typeDesc}>{TYPE_DESC.eth_basic_private}</span>
    } else typeDesc = <span className={classes.typeDesc}>{TYPE_DESC.eth_basic_private_limit}</span>
  } else if (newChain.chain_type === 'ethereum' && newChain.permission === 'private') {
    typeDesc = <span className={classes.typeDesc}>{TYPE_DESC.eth_private}</span>
  }
  const permissionRow = <Row>
    <Col xs={12}><h5>Chain Type</h5></Col>
    <Col xs={12}>
      {permission}
      {typeDesc}
    </Col>
  </Row>

  const numberOfNodesRow = newChain.chain_type === 'quorum' && newChain.permission !== 'basic-private' ? <Row>
    <Col xs={12}><h5>Number of Nodes</h5></Col>
    <Col xs={12}>
      {
        Admin.convertRole(authContext.usertype) !== ADMIN ? <DropdownButton title={newChain.number_of_nodes} variant="custom-blue" disabled>
          {nodesItems}
        </DropdownButton> : <DropdownButton title={newChain.number_of_nodes} variant="custom-blue">
            {nodesItems}
          </DropdownButton>
      }

    </Col>
  </Row> : null

  let createChainButton = null;

  if (Admin.convertRole(authContext.usertype) === DEMO || Admin.convertRole(authContext.usertype) === USER) {
    if (newChain.permission !== 'private') { // demo/user can only see basic-private or private type, and can only create 1 basic private eth/quorum chain
      if ((newChain.chain_type === 'quorum' && num_activeChain_quorum < 1) || (newChain.chain_type === 'ethereum' && num_activeChain_eth < 1)) {
        createChainButton = <div style={{ textAlign: 'center', padding: '20px 0 10px 0' }}><div className={[classes.btSecondary, classes.btnCreate].join(' ')} onClick={() => {
          if (authContext.credentials) {
            if (newChain.name === '') setNewChain({ ...newChain, name: 'Default-Chain' })
            setShowConfirm(true)
          }
          else {
            alert('please login first!')
          }
        }
        }>Create Chain</div></div>
      }
    }
  } else { // for admin
    createChainButton = <div style={{ textAlign: 'center', padding: '20px 0 10px 0' }}><div className={[classes.btSecondary, classes.btnCreate].join(' ')} onClick={() => {
      if (authContext.credentials) {
        if (newChain.name === '') setNewChain({ ...newChain, name: 'Default-Chain' })
        setShowConfirm(true)
      }
      else {
        alert('please login first!')
      }
    }
    }>Create Chain</div></div>
  }

  if (!showCreateButton) {
    createChainButton = null;
  }

  let createChain = null;
  if (showCreateChain) {
    createChain = <Modal animation={true} show={true} onHide={() => { setShowCreateChain(false) }} >
      <Modal.Header closeButton>
        <Modal.Title><span style={{ fontSize: '2rem' }}><b>Launch Chain</b></span></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(Admin.convertRole(authContext.usertype) === ADMIN || (num_activeChain_quorum !== 1 || num_activeChain_eth !== 1)) ?
          <div style={{ padding: '2rem' }}>
            <p>Here you can create your own private chain environments, the blocks, transactions in those environments are only visible to you.​</p>
            <Row>
              <Col xs={12}><h5>Protocol</h5></Col>
              <Col xs={12}>{chainTypeItems}</Col>
            </Row>
            {permissionRow}
            {numberOfNodesRow}
            <Row>
              <Col xs={12}><h5>Chain Name</h5></Col>
              <Col xs={12}>
                {
                  Admin.convertRole(authContext.usertype) !== ADMIN && newChain.permission !== 'basic-private' ?
                    <Form.Control type="text" maxLength="50" placeholder="e.g. Default Chain" value={newChain.name} disabled />
                    : <Form.Control type="text" maxLength="50" placeholder="e.g. Default Chain" value={newChain.name}
                      onChange={(e) => {
                        setNewChain({
                          ...newChain,
                          name: e.target.value
                        });
                        if (!isValidName(e.target.value)) {
                          setShowCreateButton(false)
                        } else {
                          setShowCreateButton(true)
                        }
                      }}
                    />
                }
                <div className={classes.typeDesc}>Name can only contain alphanumeric characters, "-", "_", and "."</div>
              </Col>
            </Row>
            {createChainButton}
          </div> :
          <div style={{ marginTop: '2rem' }}>
            <p><b>You Need Permissions</b><br></br>
              You don't have the permission to create more chains on this account. Contact us at <mark>contact@blocktest.net</mark> or leave us a <b>feedback</b> from the bottom left of the page if you think this is a mistake.</p>
          </div>
        }
      </Modal.Body>
    </Modal>
  }

  // BASIC CHAIN TABLE FOR ADMIN USE ONLY
  const basicChainTableRows = getChainTableRows(selectedSharedChainData)
  const basicChainTable = <div><Table striped hover style={{ fontSize: '1.4rem' }}>
    <thead>
      <tr>
        <td>Chain ID</td>
        <td>Name</td>
        <td style={{ textAlign: 'center' }}>Protocol</td>
        <td>Type</td>
        <td>Last Updated Time</td>
        <td>Status</td>
        <td>Nodes #</td>
        {/* <td>Action</td> */}
      </tr>
    </thead>
    <tbody>
      {basicChainTableRows}
    </tbody>
  </Table>
    <ReactPaginate
      forcePage={currSharedChainPage - 1}
      previousLabel={<i className="fas fa-caret-left"></i>}
      nextLabel={<i className="fas fa-caret-right"></i>}
      breakLabel={<i className="fas fa-ellipsis-h"></i>}
      breakClassName={'break-me'}
      pageCount={sharedChainPages}
      onPageChange={(page) => {
        setCurrSharedChainPage(page.selected + 1);
      }}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      containerClassName={'pagination'}
      subContainerClassName={'pages pagination'}
      activeClassName={'active'}
    />
  </div>

  const basicChainList = selectedSharedChain ?
    <MyModal show={true} width="90%" left="5%">
      <div className={classes.spaceBetween}>
        <div><b>[ID: {selectedSharedChain.chain_id}] {selectedSharedChain.name}</b></div>
        <span style={{ cursor: 'pointer' }} onClick={() => setSelectedSharedChain(null)}><i className="fas fa-times"></i></span>
      </div>
      <br></br>
      {basicChainTable}
    </MyModal> : null

  return (
    <div>
      {/* modal for creating a new chain */}
      <Modal animation={true} show={showConfirm} onHide={() => { setShowConfirm(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Chain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure to create this new <b> {newChain.chain_type}</b> chain and named <b>"{newChain.name}"</b>?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowConfirm(false) }}>Cancel</Button>
          <Button variant="primary" onClick={createChainHandler}>Create the chain!</Button>
        </Modal.Footer>
      </Modal>

      {/* modal for terminate the chain */}
      <Modal animation={true} show={showTerminateConfirm} onHide={() => { setShowTerminateConfirm(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Terminate the Chain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure to terminate <b>{chainToTerminate.name} (ID: {chainToTerminate.chain_id})</b> created by <b>{chainToTerminate.user_email}</b>?</p>
          <p>All the records in the chain will be removed.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowTerminateConfirm(false) }}>Cancel</Button>
          <Button variant="primary" onClick={terminateChainHandler}>Terminate the chain!</Button>
        </Modal.Footer>
      </Modal>

      {/* modal for reboot the chain */}
      <Modal animation={true} show={showRebootConfirm} onHide={() => { setShowRebootConfirm(false) }}>
        <Modal.Header closeButton>
          <Modal.Title>Reboot the Chain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure to reboot <b>{chainToReboot.name} (ID: {chainToReboot.chain_id})</b> created by <b>{chainToReboot.user_email}</b>?</p>
          <p>All the records in the chain will be removed.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowRebootConfirm(false) }}>Cancel</Button>
          <Button variant="primary" onClick={rebootChainHandler}>Reboot the chain!</Button>
        </Modal.Footer>
      </Modal>
      <div className={classes.chainAdmin}>
        <MyModal show={isLoading.show}>
          <img src={Blocks} width="200" alt="img" />
          <p>{isLoading.action_type}, please wait...</p>
        </MyModal>
        <MyModal show={actionAlert.show} onClick={() => { setActionAlert({ action_type: '', show: false }) }}>
          <p>Congratulations! You successfully {actionAlert.action_type}!</p>
        </MyModal>
        <div className={classes.pageTitle}>All Chains</div>
        <p className={classes.pageDesc}>BlockTEST's chain management module takes care of complicated configuration details, allowing you to easily create private chains with just a few simple clicks. Choose a blockchain and set your network size below to get started.</p>
        <div>{role}</div>
        <div className={[classes.btPrimary, classes.btnCreate].join(' ')} onClick={() => { setShowCreateChain(!showCreateChain) }}>+ Launch Chain</div>
        {createChain}
        <div className={classes.container} style={{ borderTop: 'none' }}>
          {chainTable}
        </div>
      </div>
      {basicChainList}
    </div>
  )
}

export default ChainAdmin;