import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import AuthContextProvider from './context/auth-context';
import MessageContextProvider from './context/message-context';
import MonitorContextProvider from './context/monitor-context';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

ReactDOM.render(
  <AuthContextProvider>
    <MessageContextProvider>
    <MonitorContextProvider>
    <GoogleReCaptchaProvider reCaptchaKey="6Ldw39UUAAAAAFx_WIBIYio9HdGdAUVGcwRbWNsU">
    <App />
    </GoogleReCaptchaProvider>
    </MonitorContextProvider>
    </MessageContextProvider>
  </AuthContextProvider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

