import React, { useContext, useState, useEffect } from 'react';
import classes from './Profile.module.scss';
import * as ADMIN from '../../../../constant/userAdmin';
import { AuthContext } from '../../../../context/auth-context';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import * as Common from '../../../../common';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ImageUploader from "react-images-upload";
import Modal from 'react-bootstrap/Modal';
import Icon from '../../../UI/Icon/icon';
import Photo from '../../../../assets/images/photo.png';

const Profile = () => {
  const authContext = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState();
  const [showLoginReminder, setShowLoginReminder] = useState();
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [showUpdateSecurity, setShowUpdateSecurity] = useState(false);
  const [resetSecurityQuestion, setResetSecurityQuestion] = useState(false);
  const [validated, setValidated] = useState({
    updatePassword: false,
    updateSecurity: false
  });
  const [notif, setNotif] = useState({
    show: false,
    content: ''
  })
  const [isLoad, setIsLoading] = useState({
    updatePassword: false,
    updateSecurity: false
  });
  const [passwordChange, setPasswordChange] = useState({ currentPassword: '', newPassword: '', newPassword2: '' });
  const [securityPassword, setSecurityPassword] = useState('');
  const [question1, setQuestion1] = useState({ id: null, answer: '' });
  const [question2, setQuestion2] = useState({ id: null, answer: '' });
  const [question3, setQuestion3] = useState({ id: null, answer: '' });
  const [securityQuestions, setSecurityQuestions] = useState();
  const [securityQuestions1, setSecurityQuestions1] = useState();
  const [securityQuestions2, setSecurityQuestions2] = useState();
  const [securityQuestions3, setSecurityQuestions3] = useState();
  const [myQuestions, setMyQuestions] = useState([]);

  const [icons, setIcons] = useState();
  const [newIcons, setNewIcons] = useState();
  const [showUploadIconsModal, setShowUploadIconsModal] = useState(false);
  const [showUploader, setShowUploaer] = useState(true);
  const [userIcon, setUserIcon] = useState();
  const [showUpdateIcon, setShowUpdateIcon] = useState(false);
  const [newUsername, setNewUsername] = useState();
  const [editOn, setEditOn] = useState(false);

  useEffect(() => {
    if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
      setShowLoginReminder(false);
      setUserInfo({ email: authContext.credentials.email, username: authContext.username, role: authContext.usertype, otherRoles: authContext.roles })
      getQuestionList();
      setNewUsername(authContext.username);
    } else {
      setShowLoginReminder(true);
    }
  }, [authContext.credentials])

  useEffect(() => {
    setUserIcon(authContext.icon);
  },[authContext.icon])

  const getQuestionList = () => {
    axios.get(Common.USERPATH + Common.QUESTION_LIST + '?email=' + authContext.credentials.email).then(response => {
      if (response.data.status === '1') {
        setSecurityQuestions(response.data.questions)
        setSecurityQuestions1(response.data.questions)
        setSecurityQuestions2(response.data.questions)
        setSecurityQuestions3(response.data.questions)
        getUserQuestions();
      } else {
        alert(response.data.message)
      }
    })
  }

  const getUserQuestions = () => {
    axios.get(Common.USERPATH + Common.USER_QUESTION_LIST + '?email=' + authContext.credentials.email).then(response => {
      if (response.data.status === '1') {
        if (response.data.questions === null || response.data.questions.length !== 3) {
          setResetSecurityQuestion(true);
        } else {
          setQuestion1({ id: response.data.questions[0].id, answer: 'xxxxxx' })
          setQuestion2({ id: response.data.questions[1].id, answer: 'xxxxxx' })
          setQuestion3({ id: response.data.questions[2].id, answer: 'xxxxxx' })
          setMyQuestions(response.data.questions);
        }
      } else {
        console.log(response.data.message);
      }
    })
  }

  const updatePwdDataHandler = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated({ ...validated, updatePassword: true });
    } else {
      if (passwordChange.currentPassword === passwordChange.newPassword) {
        event.preventDefault();
        event.stopPropagation();
        setNotif({ show: true, content: "Please use a new password if you'd like to update it!" })
        setPasswordChange({ ...passwordChange, newPassword: '', newPassword2: '' });
      } else if (passwordChange.newPassword !== passwordChange.newPassword2) {
        event.preventDefault();
        event.stopPropagation();
        setNotif({ show: true, content: "Two new passwords don't match, please re-enter them." })
        setPasswordChange({ ...passwordChange, newPassword: '', newPassword2: '' });
      } else {
        setValidated({ ...validated, updatePassword: true });
        setIsLoading({ ...isLoad, updatePassword: true });
        event.preventDefault();
        let data = {
          email: authContext.credentials.email,
          password: passwordChange.currentPassword,
          service_id: Common.SERVICE_ID
        }
        axios.post(Common.USERPATH + '/login', data)
          .then(response => {
            setValidated({ ...validated, updatePassword: false });
            if (response.data.status === '1') {
              authContext.login(response.data.email, response.data.token, response.data.user_name, response.data.user_id, response.data.user_type);
              data = { ...data, new_password: passwordChange.newPassword };
              axios.post(Common.USERPATH + '/password', data)
                .then(response => {
                  setIsLoading({ ...isLoad, updatePassword: false });
                  if (response.data.status === '1') {
                    setNotif({ show: true, content: 'Password updated successfully!' })
                    setPasswordChange({ currentPassword: '', newPassword: '', newPassword2: '' });
                    setShowUpdatePassword(false);
                  } else {
                    alert(response.data.message);
                  }
                })
            } else {
              alert(response.data.message);
              setIsLoading({ ...isLoad, updatePassword: false });
            }
          })
          .catch(response => {
            setValidated({ ...validated, updatePassword: false });
            console.log(response);
            alert(response.data.message)
            setIsLoading({ ...isLoad, updatePassword: false });
          });
      }
    }
  }

  const updateSecurityHandler = (event) => {
    if (!resetSecurityQuestion) {
      event.preventDefault();
      event.stopPropagation();
      setResetSecurityQuestion(true);
      setQuestion1({ id: null, answer: '' });
      setQuestion2({ id: null, answer: '' });
      setQuestion3({ id: null, answer: '' });
    } else {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated({ ...validated, updateSecurity: true });
      } else {
        setIsLoading({ ...isLoad, updateSecurity: true });
        event.preventDefault();
        setValidated({ ...validated, updateSecurity: true });
        const data = {
          credentials: authContext.credentials,
          data: {
            password: securityPassword,
            answers: [
              question1,
              question2,
              question3
            ]
          }
        }
        axios.post(Common.USERPATH + Common.UPDATE_QUESTION, data)
          .then(response => {
            setIsLoading({ ...isLoad, updateSecurity: false });
            setValidated({ ...validated, updateSecurity: false });
            if (response.data.status === '1') {
              setNotif({ show: true, content: 'Security Question updated successfully!' })
              setShowUpdateSecurity(false);
              setResetSecurityQuestion(false);
              setQuestion1({ ...question1, answer: 'xxxxxx' });
              setQuestion2({ ...question2, answer: 'xxxxxx' });
              setQuestion3({ ...question3, answer: 'xxxxxx' });
              setMyQuestions([question1, question2, question3]);
            } else {
              alert(response.data.message);
            }
          })
          .catch(response => {
            setIsLoading({ ...isLoad, updateSecurity: false });
            setValidated({ ...validated, updateSecurity: false });
            console.log(response);
          });
      }
    }
  }

  const filterSelectedQuestion = () => {
    if (securityQuestions) {
      let newList = []
      securityQuestions.map(question => {
        if (question.id !== question2.id && question.id != question3.id) newList.push(question);
      })
      setSecurityQuestions1(newList);
      newList = []
      securityQuestions.map(question => {
        if (question.id !== question1.id && question.id != question3.id) newList.push(question);
      })
      setSecurityQuestions2(newList);
      newList = []
      securityQuestions.map(question => {
        if (question.id !== question1.id && question.id != question2.id) newList.push(question);
      })
      setSecurityQuestions3(newList);
    }
  }

  useEffect(() => {
    filterSelectedQuestion();
  }, [question1, question2, question3])

  const resetMySetQuestions = () => {
    setQuestion1({ id: myQuestions[0].id, answer: 'xxxxxx' });
    setQuestion2({ id: myQuestions[1].id, answer: 'xxxxxx' });
    setQuestion3({ id: myQuestions[2].id, answer: 'xxxxxx' });
  }

  const resetUpdateSecuritySession = () => {
    setShowUpdateSecurity(false);
    if (myQuestions.length === 3) {
      setResetSecurityQuestion(false);
      resetMySetQuestions();
    }
  }

  const getIcons = () => {
    let data = {
      credentials: authContext.credentials,
      data: {
        current_page: 1,
        per_page: 100,
        source: 'user'
      }
    }

    axios.post(Common.ENDPOINT + Common.PROFILE_ICON_LIST, data).then(response => {
      if (response.data.status === '1') {
        setIcons(response.data.items);
      } else {
        alert(response.data.message);
      }
    })
  }

  const uploadIconHandler = () => {
    if (newIcons && newIcons.length > 0) {
      let formData = new FormData();
      formData.append('email', authContext.credentials.email);
      formData.append('token', authContext.credentials.token);
      formData.append('service_id', authContext.credentials.service_id);
      formData.append('source', 'user');

      newIcons.forEach(icon => {
        formData.append('file', icon);
      })

      axios({
        method: 'post',
        url: Common.ENDPOINT + Common.ADMIN_UPLOAD_ICONS,
        data: formData,
        headers: {
          'content-type': 'multipart/form-data'
        }
      }).then(response => {
        setNewIcons();
        getIcons();
        // force uploader to refresh
        setShowUploaer(false);
        setTimeout(() => setShowUploaer(true), 10);
      })
    } else {
      alert('Please choose images')
    }
  }

  const deleteIconHandler = (id) => {
    let _icons = icons.filter(icon => icon.id !== id)
    setIcons(_icons)
  }

  const editIconHandler = (id, name) => {
    let _icons = [...icons]
    for (let icon of _icons) {
      if (icon.id === id) {
        icon.filename = name;
        break;
      }
    }
    setIcons(_icons);
  }

  const selectedIconHandler = (url) => {
    let data = {
      credentials: authContext.credentials,
      data: {
        icon_url: url
      }
    }

    axios.post(Common.ENDPOINT + Common.SELECT_ICON, data).then(response => {
      if (response.data.status === '1') {
        authContext.setIcon(url);
        setShowUpdateIcon(false);
      } else {
        alert(response.data.message);
      }
    })
  }

  const editUsernameHandler = () => {
    setEditOn(false);
    if (authContext.username === newUsername) return;
    let data = {
      credentials: authContext.credentials,
      data: {
        username: newUsername
      }
    }
    axios.post(Common.ENDPOINT + Common.USER_UPDATE_USERNAME, data).then(response => {
      if (response.data.status === '1') {
        authContext.setUsername(newUsername);
      } else {
        alert(response.data.message);
      }
    })
  }

  useEffect(() => {
    if ((showUploadIconsModal || showUpdateIcon) && !icons) {
      getIcons();
    }
  }, [showUploadIconsModal, showUpdateIcon])

  let basicInfo = <p>Loading...</p>;
  let accountSetting = null;
  if (showLoginReminder) basicInfo = <p>Please <a href="/login">login</a> to check details.</p>;

  let updatePassword = <div className={classes.spaceBetween}>
    <span>Update the password for your account</span>
    <span className={classes.action} onClick={() => { setShowUpdatePassword(true) }}>Update password</span>
  </div>

  if (showUpdatePassword) {
    let button = isLoad.updatePassword ? <span>Loading...
        <Spinner as='span' animation='border' role='status' aria-hidden='true' /></span> : 'Submit';

    updatePassword = <Form noValidate
      validated={validated.updatePassword}
      onSubmit={e => updatePwdDataHandler(e)}>
      <Form.Group controlId="updateCurrPassword">
        <Form.Control type="password" placeholder="Current Password" maxLength="20" value={passwordChange.currentPassword || ''} onChange={(event) => setPasswordChange({ ...passwordChange, currentPassword: event.target.value })} required />
        <Form.Control.Feedback type="invalid">
          Password cannot be empty.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="updateNewPassword">
        <Form.Control type="password" placeholder="New Password" maxLength="20" value={passwordChange.newPassword || ''} onChange={(event) => setPasswordChange({ ...passwordChange, newPassword: event.target.value })} required />
        <Form.Control.Feedback type="invalid">
          Password cannot be empty.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="updateNewPassword2">
        <Form.Control type="password" placeholder="Confirm the New Password" maxLength="20" value={passwordChange.newPassword2 || ''} onChange={(event) => setPasswordChange({ ...passwordChange, newPassword2: event.target.value })} required />
        <Form.Control.Feedback type="invalid">
          Please re-enter your password.
        </Form.Control.Feedback>
      </Form.Group>
      <div className={classes.buttonRow}>
        <Button className={classes.submitBtn} type="submit" >{button}</Button>
        <Button className={classes.cancelBtn} onClick={() => { setShowUpdatePassword(false) }}>Cancel</Button>
      </div>
    </Form>
  }

  let updateSecurityQuestions = <div className={classes.spaceBetween}>
    <span>Update your security questions to help you reset your password</span>
    <span className={classes.action} onClick={() => {
      setShowUpdateSecurity(true);
    }}>Update security questions</span>
  </div>

  if (showUpdateSecurity) {
    let securityQuestionOptions1 = securityQuestions1 ? securityQuestions1.map(question => {
      let selected = (question.id === question1.id)
      return <option key={question.id} value={question.id} selected={selected}>{question.question}</option>
    }) : null;
    let securityQuestionOptions2 = securityQuestions2 ? securityQuestions2.map(question => {
      let selected = (question.id === question2.id)
      return <option key={question.id} value={question.id} selected={selected}>{question.question}</option>
    }) : null;
    let securityQuestionOptions3 = securityQuestions3 ? securityQuestions3.map(question => {
      let selected = (question.id === question3.id)
      return <option key={question.id} value={question.id} selected={selected}>{question.question}</option>
    }) : null;

    let button = !resetSecurityQuestion ? 'Click to Reset' : isLoad.updateSecurity ? <span>Loading...
        <Spinner as='span' animation='border' role='status' aria-hidden='true' /></span> : 'Submit';

    let defaultOption1 = question1.id ? null : <option disabled selected value="">Please select 1st security question</option>
    let defaultOption2 = question2.id ? null : <option disabled selected value="">Please select 2st security question</option>
    let defaultOption3 = question3.id ? null : <option disabled selected value="">Please select 3st security question</option>

    let pwForResetSecurityQuestion = resetSecurityQuestion ? <Form.Group controlId="securityPassword">
      <Form.Control type="password" placeholder="Enter Password" maxLength="20" value={securityPassword || ''} onChange={(event) => setSecurityPassword(event.target.value)} required />
      <Form.Control.Feedback type="invalid">
        Password cannot be empty.
    </Form.Control.Feedback>
    </Form.Group> : null

    updateSecurityQuestions = <Form noValidate
      validated={validated.updateSecurity}
      onSubmit={e => updateSecurityHandler(e)}>
      {pwForResetSecurityQuestion}
      <Form.Group>
        <Form.Control as="select" disabled={!resetSecurityQuestion} onChange={(event) => {
          setQuestion1({ id: Number(event.target.value), answer: '' });
          setValidated({ ...validated, updateSecurity: false });
        }} required>
          {defaultOption1}
          {securityQuestionOptions1}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="formControlSelect1-answer">
        <Form.Control
          required
          type="password"
          maxLength="50"
          placeholder="Answer"
          disabled={!resetSecurityQuestion}
          onChange={(event) => {
            setQuestion1({ ...question1, answer: event.target.value })
          }}
          value={question1.answer}
        />
        <Form.Control.Feedback type="invalid">Answer of 1st security question cannot be empty.</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formControlSelect2">
        <Form.Control as="select" disabled={!resetSecurityQuestion} onChange={(event) => {
          setQuestion2({ id: Number(event.target.value), answer: '' });
          setValidated({ ...validated, updateSecurity: false });
        }} required>
          {defaultOption2}
          {securityQuestionOptions2}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="formControlSelect2-answer">
        <Form.Control
          required
          type="password"
          maxLength="50"
          placeholder="Answer"
          disabled={!resetSecurityQuestion}
          onChange={(event) => {
            setQuestion2({ ...question2, answer: event.target.value })
          }}
          value={question2.answer}
        />
        <Form.Control.Feedback type="invalid">Answer of 2nd security question cannot be empty.</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="formControlSelect3">
        <Form.Control as="select" disabled={!resetSecurityQuestion} onChange={(event) => {
          setQuestion3({ id: Number(event.target.value), answer: '' });
          setValidated({ ...validated, updateSecurity: false });
        }} required>
          {defaultOption3}
          {securityQuestionOptions3}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="formControlSelect3-answer">
        <Form.Control
          required
          type="password"
          maxLength="50"
          placeholder="Answer"
          disabled={!resetSecurityQuestion}
          onChange={(event) => {
            setQuestion3({ ...question3, answer: event.target.value })
          }}
          value={question3.answer}
        />
        <Form.Control.Feedback type="invalid">Answer of 3rd security question cannot be empty.</Form.Control.Feedback>
      </Form.Group>
      <div className={classes.buttonRow}>
        <Button className={classes.submitBtn} type="submit" >{button}</Button>
        <Button className={classes.cancelBtn} onClick={resetUpdateSecuritySession}>Cancel</Button>
      </div>
    </Form>
  }

  if (!showLoginReminder && userInfo) {
    let useremail = <div>
      <div className={classes.block}>
        <h4>Email</h4><h5>{userInfo.email}</h5></div>
      <div className={classes.block}>
        <h4>Username</h4>
        <h5><span className={editOn ? classes.nameEdit : null} contentEditable={editOn} onBlur={editUsernameHandler} onInput={(e) => {
          setNewUsername(e.target.innerText)
        }} onKeyPress={(e) => {
          if (e.which == 13 || e.keyCode == 13) { editUsernameHandler(); }
        }}>
          {userInfo.username}</span><i className="fas fa-pencil-alt" onClick={() => setEditOn(true)}></i></h5>
      </div>
    </div>
    let roleDescription = ADMIN.getShortDesc(userInfo.role)
    let _otherRoles = userInfo.otherRoles ? userInfo.otherRoles.length > 0 ? userInfo.otherRoles.map(role => {
      return <Badge variant="warning" key={role} style={{ marginLeft: '3px' }}>{role}</Badge>
    }) : null : null;
    let role = <div>
      <h4>Status</h4>
      <OverlayTrigger
        placement={"right"}
        overlay={
          <Tooltip style={{ fontSize: '1.4rem' }}>
            {roleDescription}
          </Tooltip>
        }
      ><Badge variant="warning">{userInfo.role} <i className="fas fa-info-circle" style={{ margin: 0 }}></i></Badge></OverlayTrigger>{_otherRoles}</div>

    const profileImage = userIcon ? <div onClick={() => {
      setShowUpdateIcon(true);
    }}><img src={userIcon} /><i className="fas fa-sync-alt"></i></div> : <img src={Photo} onClick={() => {
      setShowUpdateIcon(true);
    }} />

    basicInfo = <div className={classes.basicInfo}>
      <h3>Basic Information</h3>
      {
        authContext && authContext.usertype === 'admin' ? <div className={classes.btPrimary} onClick={() => setShowUploadIconsModal(true)}><i className="fas fa-user-cog"></i>Manage Profile Icons</div> : null
      }
      <div className={classes.userIcon}>{profileImage}</div>
      {useremail}
      {role}
    </div>

    accountSetting = <div className={classes.setting}>
      <h3>Manage Password</h3>
      <h4>Password</h4>
      {updatePassword}
      <hr></hr>
      <h4>Security Question</h4>
      {updateSecurityQuestions}
    </div>
  }

  let notification = <Alert style={{ textAlign: 'center' }} show={notif.show} variant="info" onClose={() => {
    setNotif({ show: false, content: '' });
  }} dismissible>{notif.content}</Alert>;

  const uploadProfilePicModal = <Modal show={showUploadIconsModal} animation={true} onHide={() => { setShowUploadIconsModal(false) }} dialogClassName={classes.modal}>
    <Modal.Header closeButton>
      <Modal.Title><b>Manage Profile Icons</b></Modal.Title>
    </Modal.Header>
    <Modal.Body style={{ padding: '3rem' }}>
      <h5>Current Icons</h5>
      <div className={classes.icons}>
        {
          icons ? icons.length > 0 ? icons.map((image, i) => {
            return <Icon src={image.url} key={i} name={image.filename} id={image.id} editable={true} delete={deleteIconHandler} edit={editIconHandler} source="user" />
          }) : <p>No existing icons.</p> : <p>Fetching Icons...</p>
        }
      </div>
      <h5>Upload New Icons</h5>
      {
        showUploader ? <ImageUploader
          className={classes.icons}
          fileContainerStyle={{ width: '100%' }}
          withIcon={true}
          onChange={(pictures) => setNewIcons(pictures)}
          imgExtension={[".jpg", ".gif", ".png", ".gif"]}
          maxFileSize={5242880}
          withPreview={true}
        /> : null}
      <div className={classes.btPrimary} onClick={uploadIconHandler}><i className="fas fa-cloud-upload-alt"></i>Upload</div>
    </Modal.Body>
  </Modal>

  const updateProfilePicModal = <Modal show={showUpdateIcon} animation={true} onHide={() => { setShowUpdateIcon(false) }} dialogClassName={classes.modal}>
    <Modal.Header closeButton>
      <Modal.Title><b>Manage Profile Icon</b></Modal.Title>
    </Modal.Header>
    <Modal.Body style={{ padding: '3rem' }}>
      <h5>Please select your profile icon</h5>
      <div className={classes.icons}>
        {
          icons ? icons.length > 0 ? icons.map((image, i) => {
            return <Icon src={image.url} key={i} name={image.filename} id={image.id} editable={false} select={selectedIconHandler} />
          }) : <p>No existing icons.</p> : <p>Fetching Icons...</p>
        }
      </div>
    </Modal.Body>
  </Modal>

  return (
    <div className={classes.profile}>
      {notification}
      {uploadProfilePicModal}
      {updateProfilePicModal}
      <Row>
        <Col xs={12} md={12} lg={4}>
          <div className={classes.card}>{basicInfo}</div>
        </Col>
        <Col xs={12} md={12} lg={8}>
          <div className={classes.card}>{accountSetting}</div>
        </Col>
      </Row>
    </div>
  )
}

export default Profile;