import React, { useState, useEffect, useContext } from 'react';
import classes from './Feedback.module.scss';
import Modal from '../../UI/Modal/Modal';
import { AuthContext } from '../../../context/auth-context';
import axios from 'axios';
import * as Common from '../../../common';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { checkAuth } from '../../../commonFunc';

const Feedback = () => {
  const authContext = useContext(AuthContext);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [showForm, setShowForm] = useState(false);
  const [showLoginReminder, setShowLoginReminder] = useState();
  const [validateCheck, setValidateCheck] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [feedback, setFeedback] = useState({
    email: '',
    type: 'Question',
    level: 'Medium',
    message: '',
    validated: false,
  })

  const [submittedfeedback, setSubmittedFeedback] = useState();

  const TYPES = ['Question', 'Feedback', 'Bug', 'Criticism'];
  useEffect(() => {
    if (authContext.credentials && authContext.credentials.email && authContext.credentials.token) {
      setShowLoginReminder(false);
    } else {
      setShowLoginReminder(true);
    }
  }, [authContext])

  const sendFeedback = (credentials) => {
    let data = {
      credentials: credentials,
      data: {
        _type: feedback.type.toLowerCase(),
        level: feedback.level.toLowerCase(),
        message: feedback.message
      }
    }
    axios.post(Common.ENDPOINT + Common.FEEDBACK_SUBMIT, data).then(response => {
      setFeedback({
        type: 'Question',
        level: 'Medium',
        message: '',
        validated: false,
        email: '',
      });
      if (response.data.error_message === '') {
        setShowAlert(true);
      } else if (response.data.error_message === 'Unable to authorize this user') {
        authContext.logout();
      } else alert(response.data.error_message);
    })
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setFeedback({ ...feedback, validated: true });
    } else {
      e.preventDefault();
      setShowForm(false);
      setSubmittedFeedback({ ...feedback });
      if (showLoginReminder) {
        executeRecaptcha("feedback").then(token => {
          let credentials = {
            email: feedback.email,
            captcha_token: token
          }
          sendFeedback(credentials);
        });
      } else {
        sendFeedback(authContext.credentials);
      }
    }
  }

  const handleCancel = () => {
    setFeedback({
      ...feedback,
      validated: false
    })
    setShowForm(false);
  }

  const handleChange = (e) => {
    setFeedback({
      ...feedback,
      [e.target.id]: e.target.value
    })
  }

  const toggleBtnType = TYPES.map(type => {
    if (type === feedback.type) return <Button variant="light" key={type} className={[classes.Btn, classes.selected].join(' ')}>{type}</Button>
    else return <Button variant="light" className={classes.Btn} key={type} onClick={() => { setFeedback({ ...feedback, type: type }) }}>{type}</Button>
  })

  const emailFormGroup = showLoginReminder ? <Form.Group controlId="email">
    {/* <Form.Label className={classes.customLabel}>Email:</Form.Label> */}
    <Form.Control required type="email" placeholder="Please provide your email address" onChange={e => handleChange(e)} value={feedback.email} style={{fontSize:'1.4rem'}}>
    </Form.Control>
    <Form.Control.Feedback type="invalid">Please provide a valid email address.</Form.Control.Feedback>
  </Form.Group> : null;

  const feedbackFormContent = <div className={classes.feedbackForm}>
    <div style={{ textAlign: 'right', cursor: 'pointer' }} onClick={handleCancel}><i className="fas fa-times" style={{marginRight: '-10px'}}></i></div>
    <h2>Share your feedback</h2>
    <p>Your feedback will help us to improve your experience with Greenhouse. Thanks!​</p>
    <Form
      noValidate
      validated={feedback.validated}
      onSubmit={e => handleSubmit(e)}>
      <Form.Group>
        {/* <Form.Label className={classes.customLabel}>Type:</Form.Label> */}
        {/* {radioBtnsType} */}
        <ButtonGroup aria-label="feedback-type" style={{ width: '100%' }}>
          {toggleBtnType}
        </ButtonGroup>
      </Form.Group>
      <Form.Group controlId="message">
        {/* <Form.Label className={classes.customLabel}>Description:</Form.Label> */}
        <Form.Control required as="textarea" rows="5" placeholder="Please describe your feelings as detail as possible" onChange={e => handleChange(e)} value={feedback.message}>
        </Form.Control>
        <Form.Control.Feedback type="invalid">Please describe your feedback.</Form.Control.Feedback>
      </Form.Group>
      {emailFormGroup}
      <div style={{ textAlign: 'center', marginTop:'2rem' }}>
        <Button type="submit" className={classes.btSecondary}>Submit</Button>
      </div>
      <div style={{textAlign: 'center', margin: '2rem 0 -2rem 0',lineHeight: '1.5rem'}}>
        <small>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and<a href="https://policies.google.com/terms"> Terms of Service</a> apply.</small>
      </div>
    </Form>
  </div>

  const feedbackForm = <Modal show={showForm} top='8%'>{feedbackFormContent}</Modal>

  const confirmationMsg = submittedfeedback && submittedfeedback.type === 'Bug' ? 'one of our staff will look into this issue' : submittedfeedback && submittedfeedback.type === 'Question' ? 'one of our staff will contact you shortly' : submittedfeedback ? 'we will send you a confirmation email shortly' : null;

  const type = submittedfeedback && submittedfeedback.type === 'Bug' ? 'bug report' : submittedfeedback ? submittedfeedback.type.toLowerCase() : null;

  const _alert = <Modal show={showAlert}>
    <div className={classes.feedbackForm}>
      <div style={{ textAlign: 'right', cursor: 'pointer'}} onClick={() => setShowAlert(false)}><i className="fas fa-times" style={{marginRight: 0}}></i></div>
      <h2>We got your feedback!</h2>
      <p>
        Thank you for your {type}, {confirmationMsg}.
      </p>
    </div>
  </Modal>

  return (
    <React.Fragment>
      <div className={classes.feedback} onClick={() => { setShowForm(true) }}>
        <i className="fas fa-comment-dots"></i>
        <span>Feedback</span>
      </div>
      {feedbackForm}
      {_alert}
    </React.Fragment>
  )
}

export default Feedback;