import React, { useState, useEffect, useContext } from 'react';
import classes from './Monitor.module.scss';
import { AuthContext } from '../../../context/auth-context';
import { MonitorContext } from '../../../context/monitor-context';
import ReactPaginate from 'react-paginate';
import Table from 'react-bootstrap/Table';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as Common from '../../../common';
import { checkAuth } from '../../../commonFunc';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';

const Wallet = () => {
    const monitorContext = useContext(MonitorContext);
    const authContext = useContext(AuthContext);
    const [showCreateAccount, setShowCreateAccount] = useState(false);
    const [accountNickName, setAccountNickName] = useState();
    const [isCreating, setIsCreating] = useState(false);
    const [accountCount, setAccountCount] = useState();
    const [accountData, setAccountData] = useState();
    const [currPage, setCurrPage] = useState(1);
    const [currTxPage, setCurrTxPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [txPages, setTxPages] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [refreshing, setRefreshing] = useState(false);
    const [txRefreshing, setTxRefreshing] = useState(false);
    const [selectedWallet, setSelectedWallet] = useState();
    const [txData, setTxData] = useState();
    const pagination_options = [10, 25, 50];
    const [showFeedback, setShowFeedback] = useState(false);

    const paginationUpdate = (perPage) => {
        setPerPage(perPage);
        setCurrPage(1);
    }

    const paginationOptions = pagination_options.map((number, index) => {
        let classes = "option"
        if (number === perPage) classes = "option selected";
        return <span className={classes} key={index} onClick={() => { paginationUpdate(number) }}>{number}</span>
    })

    const isInputValid = (input) => {
        var format = /^[a-zA-Z0-9-._]+$/
        return format.test(input)
    }

    useEffect(() => {
        if (monitorContext.chain) getAccountData();
    }, [monitorContext])

    const getAccountData = () => {
        setRefreshing(true);

        let data = {
            credentials: authContext.credentials,
            data: {
                page: currPage,
                per_page: perPage,
                chain_id: monitorContext.chain.chain_id,
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_GET_WALLETS, data).then(response => {
            setRefreshing(false);

            if (response.data.status === '1') {
                setAccountData(response.data.items);
                setAccountCount(response.data.total)
                setPages(Math.ceil(parseFloat(response.data.total) / perPage))
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const createAccountHandler = () => {
        if (!accountNickName || accountNickName === '') {
            alert('Please name your account!');
            return;
        }
        setShowCreateAccount(false);
        setIsCreating(true);
        let data = {
            credentials: authContext.credentials,
            data: {
                chain_id: monitorContext.chain.chain_id,
                nick_name: accountNickName
            }
        }
        axios.post(Common.ENDPOINT + 'bdaas/' + monitorContext.chain.type + '/create_account', data).then(response => {
            setIsCreating(false);
            if (response.data.status === '1') {
                getAccountData();
                alert('Account created successfully!');
                setShowCreateAccount(false);
                setAccountNickName(null);
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    const getTxs = () => {
        setTxRefreshing(true);
        let data = {
            credentials: authContext.credentials,
            data: {
                page: currTxPage,
                per_page: 10,
                chain_id: monitorContext.chain.chain_id,
                account: selectedWallet
            }
        }
        axios.post(Common.ENDPOINT + Common.MONITOR_GET_TRANSACTIONS_V2, data).then(response => {
            setTxRefreshing(false);
            if (response.data.status === '1') {
                setTxData(response.data.items);
                setTxPages(Math.ceil(parseFloat(response.data.total) / 10))
            }
            else {
                if (checkAuth(response.data, authContext.logout, authContext.maintenance)) alert(response.data.message)
            }
        })
    }

    useEffect(() => {
        if (selectedWallet) getTxs();
    }, [selectedWallet])

    const tablePagination = pages > 0 ? <ReactPaginate
        forcePage={currPage - 1}
        previousLabel={<i className="fas fa-caret-left"></i>}
        nextLabel={<i className="fas fa-caret-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h"></i>}
        breakClassName={'break-me'}
        pageCount={pages}
        onPageChange={(page) => {
            setCurrPage(page.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
    /> : null

    const tableRows = accountData ? accountData.length > 0 ? accountData.map((account, i) => {
        let createTime = new Date((new Date(account.timestamp)).toString() + ' UTC').toLocaleString();
        return <tr key={i} onClick={() => { setSelectedWallet(account.address) }} style={{ cursor: 'pointer' }}>
            <td><b>{account.name}</b></td>
            <td>{trimHash(account.address)}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(account.address) }}></i></td>
            <td><b>{account.balance}</b></td>
            <td>{createTime}</td>
        </tr>
    }) : <tr><td colSpan="4">No Wallet Data available</td></tr> : <tr><td colSpan="4">Fetching Data...</td></tr>;

    const table = <Table hover className={classes.table}>
        <thead>
            <tr style={{ color: 'grey' }}>
                <th>Name</th>
                <th>Address</th>
                <th>Balance</th>
                <th>Creation Time</th>
            </tr>
        </thead>
        <tbody>
            {tableRows}
        </tbody>
    </Table>;;

    const tableSession = <div className={classes.container}>
        <div className={classes.spaceBetween}>
            <div className={classes.thirdTitle}>{accountCount} {(accountCount > 1 ? 'Wallets' : 'Wallet')}</div>
            <div>
                <span className="pagination_menu" style={{ fontSize: '1.2rem', display: 'inline-block' }}>show {paginationOptions} items</span>{' '}
                {
                    !refreshing ? <span className={classes.refreshBtn} title="refresh data"><i className="fas fa-sync-alt" onClick={() => getAccountData()}></i></span> :
                        <Spinner animation="border" variant="secondary" />
                }
            </div>
        </div>
        {table}
        {tablePagination}
    </div>

    let newAccountBtn = <div>
        {!isCreating ? <div className={classes.mainBtn} style={{ marginBottom: '1rem' }} onClick={() => setShowCreateAccount(!showCreateAccount)}>+ New Wallet</div> : <div className={classes.mainBtn} style={{ marginBottom: '1rem' }}>Creating Wallet...</div>}
        {(showCreateAccount ? <div>
            <div className={classes.search}><InputGroup>
                <FormControl
                    placeholder="Please enter the name of the new wallet"
                    aria-label="Please enter the name of the new wallet"
                    aria-describedby="newaccount-addon"
                    value={accountNickName}
                    onChange={(e) => {
                        setAccountNickName(e.target.value);
                        if (!isInputValid(e.target.value)) {
                            setShowFeedback(true);
                        } else setShowFeedback(false);
                    }}
                    maxLength="30"
                />
                {
                    showFeedback || !accountNickName || accountNickName === '' ? <InputGroup.Append>
                        <div><span className={classes.actionBtn} style={{ marginLeft: '1rem' }} onClick={() => setShowCreateAccount(false)}>Cancel</span></div>
                    </InputGroup.Append> : <InputGroup.Append>
                            <div><span className={classes.mainBtn} onClick={createAccountHandler}>Create</span><span className={classes.actionBtn} style={{ marginLeft: '1rem' }} onClick={() => setShowCreateAccount(false)}>Cancel</span></div>
                        </InputGroup.Append>
                }
            </InputGroup>
                {showFeedback && accountNickName !== '' ? <small>Wallet name can only contain alphanumeric characters, "-", "_", and "."</small> : null}
            </div></div> : null)}
    </div>

    const txTablePagination = txPages > 0 ? <ReactPaginate
        forcePage={currTxPage - 1}
        previousLabel={<i className="fas fa-caret-left"></i>}
        nextLabel={<i className="fas fa-caret-right"></i>}
        breakLabel={<i className="fas fa-ellipsis-h"></i>}
        breakClassName={'break-me'}
        pageCount={txPages}
        onPageChange={(page) => {
            setCurrTxPage(page.selected + 1);
        }}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
    /> : null

    let txTableRows = txData ? txData.length > 0 ? txData.map((tx, i) => {
        let txType = tx.tx_type;
        if (txType === 'invoke') {
            let inputs = ''
            tx.contract.inputs.forEach(input => {
                inputs += (input + ', ')
            })
            if (inputs !== '') inputs = inputs.substring(0, inputs.length - 2);
            inputs = '(' + inputs + ')'
            txType = <OverlayTrigger
                placement={"right"}
                overlay={
                    <Tooltip style={{ fontSize: '12px' }}>
                        Invoked function - <b>{tx.contract.function}{inputs}</b> from contract - <b>{tx.contract.contract_name}</b>
                    </Tooltip>
                }
            ><Badge pill variant="info" style={{ color: 'white' }}>Invoke<i className="fas fa-info-circle" style={{ color: 'white', marginRight: '0' }}></i></Badge></OverlayTrigger>
        } else if (txType === 'deploy') {
            let isPrivate = tx.contract.is_private ? <i className="fas fa-lock" title="private contract" style={{ color: 'white' }}></i> : null;
            txType = <OverlayTrigger
                placement={"right"}
                overlay={
                    <Tooltip style={{ fontSize: '12px' }}>
                        Deplyed <b>{tx.contract.is_private ? 'Private ' : 'Public '}</b>Contract - <b>{tx.contract.contract_name}</b>
                    </Tooltip>
                }
            >
                <Badge pill variant="secondary" style={{ color: 'white' }}>Deploy{isPrivate}<i className="fas fa-info-circle" style={{ color: 'white', marginRight: '0' }}></i></Badge></OverlayTrigger>
        } else if (txType === 'transfer') {
            txType = <Badge pill variant="warning" style={{ color: 'white' }}>Transfer</Badge>
        }
        return <tr key={i}>
            <td><Link to={`/chain/monitor/transactions/${tx.id}`}><b>Tx</b> {trimHash(tx.tx_hash)}</Link><i className="far fa-copy" title="copy hash to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(tx.tx_hash) }}></i></td>
            <td><b>From</b> {trimHash(tx.from)}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(tx.from) }}></i></td>
            <td><b>To</b> {trimHash(tx.to)}<i className="far fa-copy" title="copy address to clipboard" style={{ cursor: 'copy' }} onClick={() => { copyToClipboard(tx.to) }}></i></td>
            <td>{txType}</td>
            <td><span title={getLocalTime(tx.tx_time)}>{getLocalTime(tx.tx_time)}</span></td>
        </tr>
    }) : <tr><td colSpan="5">No Transaction Data available</td></tr> : <tr><td colSpan="5">Fetching Data...</td></tr>;

    let txTable = <Table hover className={classes.table}>
        <tbody>
            {txTableRows}
        </tbody>
    </Table>

    let txSession = selectedWallet ? <div className={classes.container}>
        <div className={classes.spaceBetween}>
            <div>
                <div className={classes.thirdTitle}>Wallet Transactions <span style={{ fontSize: '1.4rem', color: 'grey', fontWeight: '400' }}>({selectedWallet})</span></div>
            </div>
            <div>
                {
                    !txRefreshing ? <span className={classes.refreshBtn} title="refresh data"><i className="fas fa-sync-alt" onClick={() => getTxs()}></i></span> :
                        <Spinner animation="border" variant="secondary" />
                }
            </div>
        </div>
        {txTable}
        {txTablePagination}
    </div> : null

    return (
        <div className={classes.monitor}>
            <div className={classes.subTitle}>Wallet<OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Popover id="contracts-popover">
                    <Popover.Content>
                        <p>Wallet is a digital wallet that allows users to store and manage their cryptocurrency. </p>                                      </Popover.Content>
                </Popover>}
            ><sup><i class="fas fa-info-circle"></i></sup></OverlayTrigger></div>
            {/* {chainDesc} */}
            {
                monitorContext.chain ? <div>
                    {newAccountBtn}
                    {tableSession}
                    {txSession}
                </div> : <div>Click <Link to="/chain">here</Link> to select a chain first</div>
            }

        </div>
    )
}

function trimHash(hash) {
    if (hash === '') return 'Not Available'
    return hash.substring(0, 6) + '...' + hash.substring(hash.length - 6, hash.length)
}

function copyToClipboard(secretInfo) {
    var $body = document.getElementsByTagName('body')[0];
    var $tempInput = document.createElement('INPUT');
    $body.appendChild($tempInput);
    $tempInput.setAttribute('value', secretInfo)
    $tempInput.select();
    document.execCommand('copy');
    $body.removeChild($tempInput);
}

function getLocalTime(time) {
    let utc_time = (new Date(time)).toString() + ' UTC';
    let localtime = new Date(utc_time).toLocaleString();
    return localtime;
}
export default Wallet;